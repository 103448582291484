import React from 'react';
import classNames from 'classnames/bind';
import Locale, { Language } from 'locale';
import { useLanguageContext } from 'contexts/LanguageContext';
import { IRenderer } from 'look-back/interfaces';
import { getDescriptionStory } from 'look-back/util/content';
import styles from './Story3.module.scss';
import { ITitle, titlesEn, titlesVi } from './data';
import { images, backgrounds } from './images';
import { formatNumber2Decimal } from 'modules/look-back/util/common';

const cx = classNames.bind(styles);

const Story3: IRenderer = (props) => {
  const { data } = props;
  const { language } = useLanguageContext();
  const title: ITitle = language === Language.vi ? titlesVi[data.index] : titlesEn[data.index];

  return (
    <div
      id="story"
      className={cx('wrap')}
      style={{
        // @ts-ignore
        backgroundImage: `url(${backgrounds[title.background]})`,
        maxHeight: window ? window.innerHeight : '100vh',
      }}
    >
      <div className={cx('story')}>
        <p className={cx('story__label')}>{Locale.translate('call_yout')}</p>
        <p className={cx('story__title')}>{title.title}</p>
        <div
          className={cx('story__wrap__image')}
          style={{ backgroundImage: `url(${images[data.index]})` }}
        ></div>
        <div className={cx('story__bottom')}>
          <p className={cx('story__description-title')}>{title.label}</p>
          <p className={cx('story__coin')}>
            {formatNumber2Decimal(data?.value, language)} {title.type}
          </p>
          <p
            className={cx('story__description')}
            dangerouslySetInnerHTML={{
              __html: getDescriptionStory(
                title.description,
                `$${formatNumber2Decimal(data?.value, language)}`
              ),
            }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default Story3;
