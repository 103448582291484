import ExploreRemitanoNiceTruths from 'birthday/components/ExploreRemitanoNiceTruths';
import Footer from 'birthday/components/Footer';
import AnniversaryContent from 'birthday/components/AnniversaryContent';

const Anniversary = () => {
  return (
    <>
      <AnniversaryContent />
      <ExploreRemitanoNiceTruths />
      <Footer />
    </>
  );
};

export default Anniversary;
