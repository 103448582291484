export const NextIcon = ({ size, width = '24', height = '24', className, fill, onClick }: any) => (
  <svg
    onClick={onClick}
    className={className}
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    fill={fill ? fill : 'currentColor'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3_201)">
      <path
        d="M13.5 4.5L12.4275 5.54475L18.1125 11.25H3V12.75H18.1125L12.4275 18.4297L13.5 19.5L21 12L13.5 4.5Z"
        fill={fill ? fill : 'white'}
      />
    </g>
    <defs>
      <clipPath id="clip0_3_201">
        <rect width="24" height="24" fill={fill ? fill : 'white'} />
      </clipPath>
    </defs>
  </svg>
);
