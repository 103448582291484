import classNames from 'classnames/bind';
import { useMutation } from '@tanstack/react-query';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import Locale from 'locale';
import {
  EmailShareButton,
  TelegramShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
  TelegramIcon,
  FacebookShareButton,
} from 'react-share';
import LookbackService from 'look-back/services/LookbackService';
import { SHARE_URL } from 'look-back/constants';
import remiLogo from 'assets/look-back/images/logo.png';
import { CloseIcon, CopyIcon, DownloadIcon } from 'look-back/components/Icons';
import useCopyToClipboard from 'look-back/hooks/useCopyToClipboard';
import { useState } from 'react';
import styles from './ModalShare.module.scss';

const cx = classNames.bind(styles);

interface Props {
  show: boolean;
  handleHiddenShare: () => void;
}

const ModalShare: React.FC<Props> = (props) => {
  const { show, handleHiddenShare } = props;
  const [copied, setCopyStatus] = useState(false);
  const [value, copy] = useCopyToClipboard();

  const { mutate: submitReward } = useMutation({
    mutationFn: async () => await LookbackService.submitReward(),
  });

  const onCopy = () => {
    try {
      copy(SHARE_URL);
      setCopyStatus(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadImage = async () => {
    const canvas = await html2canvas(document.getElementById('story') as HTMLElement);
    const dataURL = canvas.toDataURL('image/png');
    downloadjs(dataURL, 'download.png', 'image/png');
  };

  const onClickShare = () => {
    submitReward();
  };

  return (
    <>
      {show ? (
        <div className={cx('modal')}>
          <div className={cx('modal__wrap')}>
            <div className={cx('content')}>
              <div className={cx('content__header')}>
                <div className={cx('header')}>
                  <img className={cx('header__image')} src={remiLogo} alt="logo remitano" />
                  <div className={cx('header__content')}>
                    <p className={cx('header__content__title')}>Look Back 2022</p>
                    <p className={cx('header__content__description')}>{SHARE_URL}</p>
                  </div>
                </div>
                <CloseIcon onClick={handleHiddenShare} />
              </div>
              <div className={cx('content__body')} onClick={onCopy}>
                <p className={cx('content__body__text')}>{copied ? 'Copied' : 'Copy'}</p>
                <CopyIcon className={cx('content__icon')} />
              </div>
              <div className={cx('content__body')} onClick={handleDownloadImage}>
                <p className={cx('content__body__text')}>{Locale.translate('download')}</p>
                <DownloadIcon className={cx('content__icon')} />
              </div>
              <div className={cx('content__footer')}>
                <div className={cx('social')}>
                  <FacebookShareButton
                    url={SHARE_URL}
                    title={Locale.translate('lookback_share')}
                    onClick={onClickShare}
                  >
                    <FacebookIcon size={60} round />
                  </FacebookShareButton>
                  <p className={cx('social__name')}>Facebook</p>
                </div>
                <div className={cx('social')}>
                  <TwitterShareButton
                    url={SHARE_URL}
                    title={Locale.translate('lookback_share')}
                    onClick={onClickShare}
                  >
                    <TwitterIcon size={60} round />
                  </TwitterShareButton>
                  <p className={cx('social__name')}>Twitter</p>
                </div>
                <div className={cx('social')}>
                  <EmailShareButton
                    url={SHARE_URL}
                    subject="Look back 2022 on Remitano"
                    body={Locale.translate('lookback_share')}
                    onClick={onClickShare}
                  >
                    <EmailIcon size={60} round />
                  </EmailShareButton>
                  <p className={cx('social__name')}>Email</p>
                </div>
                <div className={cx('social')}>
                  <TelegramShareButton
                    url={SHARE_URL}
                    title={Locale.translate('lookback_share')}
                    onClick={onClickShare}
                  >
                    <TelegramIcon size={60} round />
                  </TelegramShareButton>
                  <p className={cx('social__name')}>Telegram</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ModalShare;
