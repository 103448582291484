import React from 'react';
import classNames from 'classnames/bind';
import { IAction, IRenderer, IStory } from 'look-back/interfaces';
import { useGlobalContext } from 'look-back/contexts/Global';
import styles from './Story.module.scss';

interface Props {
  story: IStory;
  action: IAction;
  playState: boolean;
  bufferAction: boolean;
}

const cx = classNames.bind(styles);

const Story = (props: Props) => {
  const { action, playState, story } = props;
  const globalContext = useGlobalContext();

  const { storyStyles } = globalContext;
  const InnerContent = story.content as IRenderer;
  const config = { storyStyles };

  return (
    <div className={cx('story')}>
      <InnerContent
        action={action}
        isPaused={playState}
        story={story}
        config={config}
        data={story.data}
      />
    </div>
  );
};

export default Story;
