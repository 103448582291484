import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import Progress from 'look-back/components/Progress/Progress';
import { useGlobalContext } from 'look-back/contexts/Global';
import { useStoriesContext } from 'look-back/contexts/Stories';
import { useProgressContext } from 'look-back/contexts/Progress';
import { DEFAULT_INTERVAL } from 'modules/look-back/constants';
import styles from './ProgressList.module.scss';

const cx = classNames.bind(styles);

export default () => {
  const [count, setCount] = useState<number>(0);
  const { currentId, next, pause } = useProgressContext();
  const { onStoryEnd, onStoryStart, onAllStoriesEnd } = useGlobalContext();
  const { stories } = useStoriesContext();

  useEffect(() => {
    setCount(0);
  }, [currentId, stories]);

  useEffect(() => {
    if (!pause) {
      animationFrameId.current = requestAnimationFrame(incrementCount);
    }
    return () => {
      cancelAnimationFrame(animationFrameId.current ?? 1);
    };
  }, [currentId, pause]);

  const animationFrameId = useRef<number>();

  let countCopy = count;
  const incrementCount = () => {
    if (countCopy === 0) storyStartCallback();
    setCount((count: number) => {
      const interval = getCurrentInterval() as number;
      countCopy = count + 100 / ((interval / 1000) * 60);
      return count + 100 / ((interval / 1000) * 60);
    });
    if (countCopy < 100) {
      animationFrameId.current = requestAnimationFrame(incrementCount);
    } else {
      storyEndCallback();
      if (currentId === stories.length - 1) {
        allStoriesEndCallback();
      }
      cancelAnimationFrame(animationFrameId.current ?? 1);
      next();
    }
  };

  const storyStartCallback = () => {
    onStoryStart && onStoryStart(currentId, stories[currentId]);
  };

  const storyEndCallback = () => {
    onStoryEnd && onStoryEnd(currentId, stories[currentId]);
  };

  const allStoriesEndCallback = () => {
    onAllStoriesEnd && onAllStoriesEnd(currentId, stories);
  };

  const getCurrentInterval = () => {
    if (typeof stories[currentId].duration === 'number') return stories[currentId].duration;
    return DEFAULT_INTERVAL;
  };

  return (
    <div className={cx('progress')}>
      {stories.map((_, i) => (
        <Progress
          key={i}
          count={count}
          width={1 / stories.length}
          active={i === currentId ? 1 : i < currentId ? 2 : 0}
        />
      ))}
    </div>
  );
};
