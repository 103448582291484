export type NavHead = {
  id: string;
  name: string;
  to: string;
  tabName?: string | null;
};

export const navs: NavHead[] = [
  {
    id: '1',
    name: 'group_state',
    to: '/',
    tabName: null,
  },
  {
    id: '2',
    name: 'round_of_16',
    to: '/?tab=round-of-16-teams',
    tabName: 'round-of-16-teams',
  },
  {
    id: '3',
    name: 'quarter_finals',
    to: '/?tab=quater-finals',
    tabName: 'quater-finals',
  },
  {
    id: '4',
    name: 'semi_final',
    to: '/?tab=semi-final',
    tabName: 'semi-final',
  },
  {
    id: '5',
    name: 'final_round',
    to: '/?tab=final-round',
    tabName: 'final-round',
  },
  {
    id: '6',
    name: 'my_vote',
    to: '/?tab=my-voted',
    tabName: 'my-voted',
  },
];
