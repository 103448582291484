import ARGENTINA from 'assets/world-cup/flags/ARGENTINA.png';
import AUSTRALIA from 'assets/world-cup/flags/AUSTRALIA.png';
import BELGIUM from 'assets/world-cup/flags/BELGIUM.png';
import BRAZIL from 'assets/world-cup/flags/BRAZIL.png';
import CAMEROON from 'assets/world-cup/flags/CAMEROON.png';
import CANADA from 'assets/world-cup/flags/CANADA.png';
import COSTA_RICA from 'assets/world-cup/flags/COSTA RICA.png';
import CROATIA from 'assets/world-cup/flags/CROATIA.png';
import DENMARK from 'assets/world-cup/flags/DENMARK.png';
import ECUADOR from 'assets/world-cup/flags/ECUADOR.png';
import ENGLAND from 'assets/world-cup/flags/ENGLAND.png';
import FRANCE from 'assets/world-cup/flags/FRANCE.png';
import GERMANY from 'assets/world-cup/flags/GERMANY.png';
import GHANA from 'assets/world-cup/flags/GHANA.png';
import IRAN from 'assets/world-cup/flags/IRAN.png';
import JAPAN from 'assets/world-cup/flags/JAPAN.png';
import MEXICO from 'assets/world-cup/flags/MEXICO.png';
import MOROCCO from 'assets/world-cup/flags/MOROCCO.png';
import NETHERLANDS from 'assets/world-cup/flags/NETHERLANDS.png';
import POLAND from 'assets/world-cup/flags/POLAND.png';
import PORTUGAL from 'assets/world-cup/flags/PORTUGAL.png';
import SAUDI_ARABIA from 'assets/world-cup/flags/SAUDI ARABIA.png';
import SENEGAL from 'assets/world-cup/flags/SENEGAL.png';
import SERBIA from 'assets/world-cup/flags/SERBIA.png';
import SOUTH_KOREA from 'assets/world-cup/flags/SOUTH KOREA.png';
import QATAR from 'assets/world-cup/flags/QATAR.png';
import SPAIN from 'assets/world-cup/flags/SPAIN.png';
import SWITZERLAND from 'assets/world-cup/flags/SWITZERLAND.png';
import TUNISIA from 'assets/world-cup/flags/TUNISIA.png';
import URUGUAY from 'assets/world-cup/flags/URUGUAY.png';
import USA from 'assets/world-cup/flags/USA.png';
import WALES from 'assets/world-cup/flags/WALES.png';

export const FLAG_MAPPING_IMG: { [key: string]: string } = {
  ARGENTINA: ARGENTINA,
  AUSTRALIA: AUSTRALIA,
  BELGIUM: BELGIUM,
  BRAZIL: BRAZIL,
  CAMEROON: CAMEROON,
  CANADA: CANADA,
  'COSTA RICA': COSTA_RICA,
  CROATIA: CROATIA,
  DENMARK: DENMARK,
  ECUADOR: ECUADOR,
  ENGLAND: ENGLAND,
  FRANCE: FRANCE,
  GERMANY: GERMANY,
  GHANA: GHANA,
  IRAN: IRAN,
  JAPAN: JAPAN,
  MEXICO: MEXICO,
  MOROCCO: MOROCCO,
  NETHERLANDS: NETHERLANDS,
  POLAND: POLAND,
  PORTUGAL: PORTUGAL,
  QATAR: QATAR,
  'SAUDI ARABIA': SAUDI_ARABIA,
  SENEGAL: SENEGAL,
  SERBIA: SERBIA,
  'SOUTH KOREA': SOUTH_KOREA,
  SPAIN: SPAIN,
  SWITZERLAND: SWITZERLAND,
  TUNISIA: TUNISIA,
  URUGUAY: URUGUAY,
  USA: USA,
  WALES: WALES,
};
