import React from 'react';
import Layout from 'world-cup/components/Layout';
import MatchProvider from 'world-cup/contexts/MatchContext';
import GroupStage from 'world-cup/pages/home/GroupStage';

const Home: React.FC = () => {
  return (
    <MatchProvider>
      <Layout>
        <GroupStage />
      </Layout>
    </MatchProvider>
  );
};

export default Home;
