import { useMutation } from '@tanstack/react-query';
import MatchService from 'world-cup/services/MatchService';
const getMatch = async () => {
  return await MatchService.getMatchs();
};

export const useMatchsMutation = () => {
  return useMutation({ mutationFn: () => getMatch() });
};

export default useMatchsMutation;
