import { Link } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';
import classNames from 'classnames/bind';
import Locale from 'locale';
import { useStoriesContext } from 'look-back/contexts/Stories';
import styles from './Button.module.scss';

const cx = classNames.bind(styles);

interface Props {
  currentId: number;
  onClick: (e: any) => void;
}

const Button: React.FC<Props> = (props) => {
  const { currentId, onClick } = props;
  const { stories } = useStoriesContext();
  const { username } = useAuthContext();

  return (
    <>
      {currentId === stories.length - 1 ? (
        <Link to={'/'} onClick={onClick}>
          <button className={cx('button-last')}>
            {!username ? Locale.translate('login') : Locale.translate('close')}
          </button>
        </Link>
      ) : (
        <Link to={'/'} onClick={onClick}>
          <button className={cx('button', currentId >= stories.length - 4 && 'button--variant2')}>
            {Locale.translate('next')}
          </button>
        </Link>
      )}
    </>
  );
};

export default Button;
