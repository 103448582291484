import React from 'react';
import classNames from 'classnames/bind';
import { useStoriesContext } from 'look-back/contexts/Stories';
import { useProgressContext } from 'look-back/contexts/Progress';
import styles from './Progress.module.scss';

export interface Props {
  width: number;
  active: number;
  count: number;
}

const cx = classNames.bind(styles);

export default (props: Props) => {
  const { width, active } = props;
  const { currentId, bufferAction, pause } = useProgressContext();
  const { stories } = useStoriesContext();

  const getProgressStyle = ({ active }: any) => {
    switch (active) {
      case 2:
        return { width: '100%' };
      case 1:
        return { transform: `scaleX(${props.count / 100})` };
      case 0:
        return { width: 0 };
      default:
        return { width: 0 };
    }
  };

  const getProgressWrapperStyle = () => ({
    width: `${width * 100}%`,
    opacity: pause && !bufferAction ? 0 : 1,
  });

  return (
    <div className={cx('progress')} style={getProgressWrapperStyle()}>
      <div
        className={cx('progress-inner')}
        style={{
          ...getProgressStyle({ active }),
          background: currentId < stories.length - 4 ? '#fff' : '#9B59B6',
        }}
      />
    </div>
  );
};
