import christmas from 'assets/world-cup/christmas.png';
import coinlympia from 'assets/world-cup/coinlympia.png';

export interface Activities {
  title?: string;
  description?: string;
  src?: string;
  url?: string;
}

export const activitiesData: Activities[] = [
  {
    title: 'Christmas',
    description: 'activities_description_01',
    src: christmas,
    url: 'https://christmas.remitano.com/?app=1&utm_source=homebanner&utm_medium=221201_br&utm_campaign=christmas-advent',
  },
  {
    title: 'Coinlympia',
    description: 'activities_description_02',
    src: coinlympia,
    url: 'https://remitano.com/game-in-app/coinlympia/?utm_source=wgame&utm_medium=221122_br&utm_campaign=aimforthevictory_wgame_221122_br',
  },
];
