import React from 'react';
import { Route, Routes } from 'react-router-dom';
import useGA from 'hooks/useGA';
import Home from './pages/home';
import ToastWrapper from 'base-components/ToastWrapper';

const MainApp: React.FC = () => {
  useGA();

  return (
    <ToastWrapper>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </ToastWrapper>
  );
};

export default MainApp;
