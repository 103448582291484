import Locale from 'locale';
import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './MissionModal.module.scss';
import classNames from 'classnames/bind';
import useMediaQuery from 'hooks/useMediaQuery';
import { GIFT } from 'modules/birthday/constants/common';

const cx = classNames.bind(styles);

export interface ModalConfirmRef {
  show(giftId: number, hasGift: boolean): void;
}

interface Props {
  onClose?(): void;
}

const MissionModal = React.forwardRef<ModalConfirmRef, Props>((props, ref) => {
  const [isShowModal, setShowModal] = React.useState(false);
  const [hasGift, setHasGift] = React.useState(false);
  const [giftId, setGiftId] = React.useState<number>(0);
  const isXs = useMediaQuery('(max-width: 576px)');

  React.useImperativeHandle(ref, () => ({
    show: (giftId: number, hasGift: boolean) => {
      setGiftId(giftId);
      setShowModal(true);
      setHasGift(hasGift);
    },
  }));

  const onClose = () => {
    setShowModal(false);
    props.onClose && props.onClose();
  };

  const missionImage = React.useMemo(() => {
    switch (giftId) {
      case 1:
        return (
          <img
            src={require(`assets/birthday/images/mission-modal-usdt.png`)}
            className={cx('image')}
          />
        );
      case 2:
        return (
          <img
            src={require(`assets/birthday/images/mission-modal-ada.png`)}
            className={cx('image')}
          />
        );
      case 3:
        return (
          <img
            src={require(`assets/birthday/images/mission-modal-eth.png`)}
            className={cx('image')}
          />
        );
      case 4:
        return (
          <img
            src={require(`assets/birthday/images/mission-modal-shiba.png`)}
            className={cx('image')}
          />
        );
      case 5:
        return (
          <img
            src={require(`assets/birthday/images/mission-modal-btc.png`)}
            className={cx('image')}
          />
        );
      default:
        return (
          <img
            src={require(`assets/birthday/images/mission-modal-ohno.png`)}
            className={cx('image')}
          />
        );
    }
  }, [giftId, isXs]);

  const missionImageNoGift = React.useMemo(() => {
    if (giftId === 5)
      return (
        <img
          src={require(`assets/birthday/images/mission-modal-thankyou.png`)}
          className={cx('image')}
        />
      );
    return (
      <img src={require(`assets/birthday/images/mission-modal-ohno.png`)} className={cx('image')} />
    );
  }, [giftId]);

  const missionContent = React.useMemo(() => {
    if (hasGift)
      return {
        title: '',
        currency: GIFT[`${giftId}`],
      };
    if (giftId === 5)
      return {
        title: Locale.translate('thank_you'),
        des_1: Locale.translate('thank_des_1'),
        des_2: Locale.translate('thank_des_2'),
      };
    return {
      title: Locale.translate('oh_no'),
      des_1: Locale.translate('oh_no_des_1'),
      des_2: Locale.translate('oh_no_des_2'),
    };
  }, [giftId, hasGift]);

  return (
    <Modal show={isShowModal} onHide={onClose} className={cx('container')} centered>
      <div>
        <div className={cx('title')}>{missionContent.title}</div>
        {hasGift ? missionImage : missionImageNoGift}
        {hasGift ? (
          <div className={cx('currency-box')}>
            <div className={cx('currency-content')}>
              <span className={cx('currency')}>{missionContent.currency}</span>
            </div>
          </div>
        ) : (
          <div className={cx('no-gift-box')}>
            <span className={cx('des-1')}>{missionContent.des_1}</span>
            <span className={cx('des-2')}>{missionContent.des_2}</span>
          </div>
        )}
        <div className={cx('action-box')}>
          <div className={cx('action-content')}>
            <div onClick={onClose}>
              <img src={require(`assets/birthday/images/close.png`)} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

MissionModal.displayName = 'MissionModal';

export default MissionModal;
