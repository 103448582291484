import { useEffect, useState } from 'react';
import Description from 'bring-coin-home/components/Description/Description';
import Cards from 'modules/bring-coin-home/components/Cards/Cards';
import { getCurrentWeek, getCurrentWeekFromDay } from 'bring-coin-home/utils/date';
import RequiedKyc from 'bring-coin-home/components/RequiedKyc';
import Layout from 'bring-coin-home/components/Layout/Layout';
import useQuery from 'hooks/useQuery';

const Home = () => {
  const [currentWeek, setCurrentWeek] = useState(1);
  const query = useQuery();

  useEffect(() => {
    const day = query.get('day');
    if (day) {
      setCurrentWeek(getCurrentWeekFromDay(+day));
    } else {
      setCurrentWeek(getCurrentWeek());
    }
  }, []);

  return (
    <Layout>
      <RequiedKyc />
      <Description />
      <Cards />
    </Layout>
  );
};

export default Home;
