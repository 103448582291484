export const CloseIcon = ({
  size,
  width = '30',
  height = '30',
  className,
  onClick,
  color = 'white',
}: any) => (
  <svg
    onClick={onClick}
    style={{
      cursor: 'pointer',
    }}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="#E7E6E8" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.2732 9.21235C9.9803 8.91945 9.50542 8.91945 9.21253 9.21235C8.91964 9.50524 8.91964 9.98011 9.21253 10.273L13.9331 14.9936L9.21253 19.7141C8.91964 20.007 8.91964 20.4819 9.21253 20.7748C9.50542 21.0677 9.9803 21.0677 10.2732 20.7748L14.9938 16.0542L19.7143 20.7748C20.0072 21.0677 20.4821 21.0677 20.775 20.7748C21.0679 20.4819 21.0679 20.007 20.775 19.7141L16.0544 14.9936L20.775 10.273C21.0679 9.98011 21.0679 9.50524 20.775 9.21235C20.4821 8.91945 20.0072 8.91945 19.7143 9.21235L14.9938 13.9329L10.2732 9.21235Z"
      fill="black"
    />
  </svg>
);
