export const UploadIcon = ({ size, width = '3.2rem', height = '3.2rem', className }: any) => (
  <svg
    className={className}
    width={size || width}
    height={size || height}
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.1571 13.8359L21.9247 12.3786C21.4686 9.51917 18.9876 7.3335 16 7.3335C12.6863 7.3335 10 10.0197 9.99996 13.3334L10.0011 15.2304L8.11578 15.3398C6.19293 15.4513 4.66663 17.0482 4.66663 19.0002C4.66663 21.0252 6.30825 22.6668 8.33329 22.6668H15.3333V17.0813L14.1785 18.236C13.9182 18.4964 13.4961 18.4964 13.2357 18.236L12.7643 17.7646C12.504 17.5043 12.504 17.0822 12.7643 16.8218L15.862 13.7242C16.1223 13.4638 16.5444 13.4638 16.8048 13.7242L19.9024 16.8218C20.1628 17.0822 20.1628 17.5043 19.9024 17.7646L19.431 18.236C19.1706 18.4964 18.7485 18.4964 18.4882 18.236L17.3333 17.0811V22.6668H23C25.3932 22.6668 27.3333 20.7267 27.3333 18.3335C27.3333 16.151 25.7179 14.3423 23.6181 14.0437L22.1571 13.8359ZM8.33329 24.6668H15.3333H17.3333H23C26.4978 24.6668 29.3333 21.8313 29.3333 18.3335C29.3333 15.1411 26.9714 12.5005 23.8997 12.0636C23.2913 8.24881 19.9861 5.3335 16 5.3335C11.5817 5.3335 7.99996 8.91522 7.99996 13.3335L7.99996 13.3431C5.0255 13.5157 2.66663 15.9824 2.66663 19.0002C2.66663 22.1298 5.20368 24.6668 8.33329 24.6668Z"
    ></path>
  </svg>
);

export const MessageIcon = ({ size, width = '2.6rem', height = '2.6rem', className }: any) => (
  <svg
    className={className}
    width={size || width}
    height={size || height}
    viewBox="0 0 48 48"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.17877 7.17357C2.50304 6.45894 3.21528 6 4.00003 6H44C44.713 6 45.372 6.37952 45.7299 6.99615C46.0877 7.61278 46.0902 8.37327 45.7365 8.99228L25.7365 43.9923C25.3423 44.6821 24.5772 45.0732 23.7872 44.9886C22.9972 44.9041 22.3321 44.3599 22.0929 43.6023L16.219 25.0017L2.49488 9.31701C1.97811 8.72642 1.85449 7.88819 2.17877 7.17357ZM20.377 24.8856L24.531 38.0397L40.5537 10H8.40757L18.3918 21.4106L30.1002 14.2054C30.5705 13.9159 31.1865 14.0626 31.4759 14.533L32.5241 16.2363C32.8136 16.7066 32.6669 17.3226 32.1966 17.612L20.377 24.8856Z"
    ></path>
  </svg>
);

export const LanguageIcon = ({ size, width = '3.2rem', height = '3.2rem', className }: any) => (
  <svg
    className={className}
    width={size || width}
    height={size || height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2ZM16.8279 9.42942H13.4072C13.3458 7.32659 12.8079 5.26513 11.834 3.40039C14.5938 4.17082 16.5847 6.57444 16.8279 9.42942ZM10.0001 16.858C9.87184 16.8578 9.74368 16.8494 9.61643 16.833C8.4422 14.9511 7.79211 12.7897 7.7334 10.5723H12.2669C12.2082 12.7897 11.5581 14.9511 10.3839 16.833C10.2566 16.8494 10.1284 16.8578 10.0001 16.858ZM7.7334 9.42829C7.79211 7.21089 8.4422 5.04944 9.61643 3.16755C9.87115 3.13425 10.1291 3.13425 10.3839 3.16755C11.5581 5.04944 12.2082 7.21089 12.2669 9.42829H7.7334ZM8.16576 3.40039C7.19189 5.26513 6.65398 7.32659 6.59256 9.42942H3.17188C3.41503 6.57444 5.40596 4.17082 8.16576 3.40039ZM3.17188 10.5723H6.59256C6.65398 12.6751 7.19189 14.7366 8.16576 16.6013C5.40596 15.8309 3.41503 13.4272 3.17188 10.5723ZM11.834 16.6013C12.8079 14.7366 13.3458 12.6751 13.4072 10.5723H16.8279C16.5847 13.4272 14.5938 15.8309 11.834 16.6013Z"
      fill="#FBFBFC"
    />
    <mask id="mask0_89_2664" maskUnits="userSpaceOnUse" x="2" y="2" width="16" height="16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2ZM16.8279 9.42942H13.4072C13.3458 7.32659 12.8079 5.26513 11.834 3.40039C14.5938 4.17082 16.5847 6.57444 16.8279 9.42942ZM10.0001 16.858C9.87184 16.8578 9.74368 16.8494 9.61643 16.833C8.4422 14.9511 7.79211 12.7897 7.7334 10.5723H12.2669C12.2082 12.7897 11.5581 14.9511 10.3839 16.833C10.2566 16.8494 10.1284 16.8578 10.0001 16.858ZM7.7334 9.42829C7.79211 7.21089 8.4422 5.04944 9.61643 3.16755C9.87115 3.13425 10.1291 3.13425 10.3839 3.16755C11.5581 5.04944 12.2082 7.21089 12.2669 9.42829H7.7334ZM8.16576 3.40039C7.19189 5.26513 6.65398 7.32659 6.59256 9.42942H3.17188C3.41503 6.57444 5.40596 4.17082 8.16576 3.40039ZM3.17188 10.5723H6.59256C6.65398 12.6751 7.19189 14.7366 8.16576 16.6013C5.40596 15.8309 3.41503 13.4272 3.17188 10.5723ZM11.834 16.6013C12.8079 14.7366 13.3458 12.6751 13.4072 10.5723H16.8279C16.5847 13.4272 14.5938 15.8309 11.834 16.6013Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_89_2664)"></g>
  </svg>
);

export const RightArrowIcon = ({
  size,
  width = '3.2rem',
  height = '3.2rem',
  className,
  fillColor,
}: any) => (
  <svg
    className={className}
    width={size || width}
    height={size || height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_324_563)">
      <path
        d="M11.25 3.75L10.3562 4.62063L15.0938 9.375H2.5V10.625H15.0938L10.3562 15.3581L11.25 16.25L17.5 10L11.25 3.75Z"
        fill={fillColor || 'currentColor'}
      />
    </g>
    <defs>
      <clipPath id="clip0_324_563">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const CheckMarkIcon = ({ size, width = '3.2rem', height = '3.2rem', className }: any) => (
  <svg
    className={className}
    width={size || width}
    height={size || height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
  >
    <circle cx="12" cy="12" r="12" fill="#299D82" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2857 16.7142L6 12.4285L7.36286 11.1428L10.2857 14.0142L16.6371 7.71423L18 9.06852L10.2857 16.7142Z"
      fill="white"
    />
    <mask id="mask0_12_1305" maskUnits="userSpaceOnUse" x="6" y="7" width="12" height="10">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2857 16.7142L6 12.4285L7.36286 11.1428L10.2857 14.0142L16.6371 7.71423L18 9.06852L10.2857 16.7142Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_12_1305)"></g>
  </svg>
);

export const DownArrowIcon = ({ size, width = '3.2rem', height = '3.2rem', className }: any) => (
  <svg
    className={className}
    width={size || width}
    height={size || height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
    fill="none"
  >
    <path
      d="M17.1017 7.72391L16.0985 6.7207L10.5809 12.2383L5.06326 6.7207L4.06006 7.72391L9.57769 13.2415L10.5809 14.2447L11.5841 13.2415L17.1017 7.72391Z"
      fill="white"
    />
  </svg>
);

export const LeftPrevIcon = ({ size, width = '3.2rem', height = '3.2rem', className }: any) => (
  <svg
    className={className}
    width={size || width}
    height={size || height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 24"
    fill="none"
  >
    <path
      d="M11.5 2L1.5 12L11.5 22"
      stroke="#333333"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RightNextIcon = ({ size, width = '3.2rem', height = '3.2rem', className }: any) => (
  <svg
    className={className}
    width={size || width}
    height={size || height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 24"
    fill="none"
  >
    <path
      d="M1.5 2L11.5 12L1.5 22"
      stroke="#333333"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PlusIcon = ({ size, width = '3.2rem', height = '3.2rem', className }: any) => (
  <svg
    className={className}
    width={size || width}
    height={size || height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      stroke="#9B59B6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MinusIcon = ({ size, width = '3.2rem', height = '3.2rem', className }: any) => (
  <svg
    className={className}
    width={size || width}
    height={size || height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M7 11H15M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
      stroke="#9B59B6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
