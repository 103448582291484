const words = {
  required_login: 'Yêu cầu đăng nhập / đăng ký',
  required_login_des: 'Đăng nhập hoặc đăng ký để tiếp tục',
  login: 'Đăng nhập / Đăng ký',
  back: 'Trở lại',
  group_state: 'Vòng Bảng',
  round_of_16: 'Vòng 1/8',
  quarter_finals: 'Vòng tứ kết',
  semi_final: 'Vòng bán kết',
  final_round: 'Chung kết tranh hạng',
  banner_title_one: 'Nhận quà “hot”',
  banner_title_two: 'Thổi bừng cảm xúc',
  banner_title_third: 'Cùng hàng nghìn voucher đang chờ bạn',
  voted: 'Đã bình chọn',
  match_end: 'Đã kết thúc',
  submit: 'Bình chọn đội thắng cuộc',
  gift_name: 'Tổng giải thưởng lên đến {amount} Shib',
  voteable_matches: 'Vòng bình chọn',
  voteable_not_matches: 'Trận đấu không thuộc danh sách bình chọn',
  match_select: 'Chọn trận đấu để bình chọn',
  view_match_voting: 'Trở lại vòng bình chọn',
  view_match_not_voting: 'Xem những trận đấu khác',
  expired_match: 'Quá hạn dự đoán',
  read_more: 'Xem chi tiết',
  win: 'Thắng',
  lose: 'Thua',
  waiting: 'Đang chờ',
  draw: 'Hòa',
  empty_voting: 'Bạn vẫn chưa tham gia bình chọn.',
  vote_now: 'Tham gia ngay!',
  my_vote: 'Bình chọn của tôi',
  match_not_vote: 'Không thể bình chọn trận đấu này',
  date_time: 'NGÀY / GIỜ',
  comming_soon: 'Sắp diễn ra',
  activities_sub_title: 'Tổng giải thương hơn 2000$ và hơn 1000 voucher đang chờ đón bạn!',
  activities_description_01:
    'Cùng đón giáng sinh an lành với bao la quà tặng hơn 1000 Coins và muôn vạn voucher đang chờ bạn mỗi ngày.',
  activities_description_02: `Chinh phục và tìm hiểu kiến thức Crypto cùng Coinlympia. Hãy là những nhà đầu tư thông thái, chinh phục đỉnh núi Coinlympia ngay nhé!`,
  play_now: 'Chơi ngay',
  button_back: 'Trở về trang chủ',
  button_back_small: 'Trở về',
  intro_1: 'CHÚC MỪNG NĂM MỚI 2023',
  intro_2:
    'Một năm bình an, muôn vàn tài lộc, triệu điều thành công đang chờ đón bạn. Hái lộc nghênh xuân sang cùng Remitano bạn nhé!',
  outro_1: 'Mỗi ô cửa sẽ được giữ mở trong 1 ngày.',
  week: 'Tuần',
  open_in: 'Mở trong',
  days: 'ngày',
  hours: 'giờ',
  minutes: 'phút',
  seconds: 'giây',
  days_left_first: 'Còn',
  days_left: 'ngày',
  expired: 'Hết hạn',
  view_rewards: 'Nhấn để xem phần thưởng',
  miss_round: 'Xin lỗi, bạn đã lỡ lượt này',
  terms_and_conditions_title: 'Điều khoản & Điều kiện',
  terms_and_conditions_intro:
    'Người dùng Remitano cần eKYC Cấp 2 để nhận phần thưởng từ chương trình.',
  terms_and_conditions_part_1: 'A. Quy tắc Chung',
  terms_and_conditions_part_1_1:
    'Dựa trên kết quả trò chơi, Remitano sẽ gửi phần thưởng trực tiếp vào ví Remitano của người chơi. Trong trường hợp có khiếu nại, Remitano sẽ giải quyết dựa trên sự công bằng và kết quả của trò chơi. Remitano có quyền đưa ra quyết định cuối cùng.',
  terms_and_conditions_part_1_2:
    'Trong trường hợp phát hiện người chơi có dấu hiệu bất thường hoặc gian lận, Remitano có quyền dừng việc tham gia chương trình của người chơi mà không cần thông báo trước. Remitano có quyền từ chối trả thưởng hoặc thu hồi một phần hay toàn bộ giải thưởng trong trường hợp phát hiện hành vi gian lận của người chơi. Trong mọi trường hợp, Remitano có quyền đưa ra quyết định cuối cùng.',
  terms_and_conditions_part_1_3:
    'Nếu có bất kỳ sự khác biệt nào giữa thông tin hiển thị trên ứng dụng của người chơi và hệ thống của Remitano, quyết định cuối cùng sẽ dựa trên bản chất tình huống; thể lệ chương trình có thể được điều chỉnh hoặc chấm dứt theo quyết định của Remitano trong suốt thời gian như thông tin từ hệ thống của Remitano.',
  terms_and_conditions_part_2: 'B. Điều kiện tham gia',
  terms_and_conditions_part_2_1:
    'Người chơi phải thực hiện đầy đủ các hướng dẫn và tuân thủ các quy định của Ban tổ chức.',
  terms_and_conditions_part_2_2:
    'Người chơi cần giữ ảnh và bài đăng được tạo theo yêu cầu của Christmas Advent Calendar trong ít nhất hai tuần sau chương trình.',
  terms_and_conditions_part_2_3:
    'Người chơi phải đặt hình ảnh và bài đăng ở chế độ công khai để dễ xác minh.',
  terms_and_conditions_part_2_4:
    'Chương trình áp dụng cho tất cả người dùng Remitano sử dụng ứng dụng Remitano cập nhật phiên bản mới nhất hoặc liên kết trực tiếp với Website của Remitano.',
  terms_and_conditions_part_2_5: 'Chương trình không áp dụng cho công dân dưới 18 tuổi.',
  terms_and_conditions_part_3: 'C. Thể lệ nhận thưởng',
  terms_and_conditions_part_3_1:
    'Người chơi có thể nhận được một lượt mới bằng cách hoàn thành một nhiệm vụ như yêu cầu.',
  terms_and_conditions_part_3_2:
    'Người dùng Remitano cần eKYC Cấp 2 để nhận thưởng từ chương trình.',
  terms_and_conditions_part_3_3:
    'Người chơi phải điền những thông tin cần thiết đầy đủ và chính xác. Ban tổ chức không chịu trách nhiệm giải quyết các thông tin cung cấp không chính xác.',
  terms_and_conditions_part_3_4: 'Phần thưởng phải tuân theo quy định của Remitano.',
  terms_and_conditions_part_4: 'D. Quy định về Quyền và Nghĩa vụ của Ban Tổ chức',
  terms_and_conditions_part_4_1:
    'BTC có toàn quyền sử dụng tên và nội dung (bài viết, hình ảnh) do người chơi cung cấp cho mục đích quảng cáo, truyền thông và các mục đích thương mại khác mà không phải trả bất kỳ chi phí nào, bao gồm các hình thức quảng bá trên mạng xã hội, các loại hình quảng bá trực tuyến (PR, Quảng cáo trực tuyến, Website) và các hình thức quảng bá truyền thông (phim, quảng cáo báo chí)',
  terms_and_conditions_part_4_2:
    'Ban tổ chức có nghĩa vụ giữ bí mật thông tin cá nhân do người chơi cung cấp.',
  terms_and_conditions_part_5: 'E. Các quy tắc khác',
  terms_and_conditions_part_5_1:
    'BTC có quyền dừng, điều chỉnh hoặc thay đổi nội dung và điều khoản của chương trình trước khi kết thúc chương trình và thông báo các điều khoản được cập nhật trên fanpage Remitano.',
  terms_and_conditions_part_5_2:
    'Ban Tổ chức có thể rút ngắn hoặc kéo dài thời lượng chương trình tùy theo quyết định của mình.',
  terms_and_conditions_part_5_3:
    'Chương trình có thể kết thúc sớm hơn dự kiến ​​khi hết ngân sách khuyến mại.',
  introduction: 'Giới thiệu chương trình christmas advent calendar',
  introduction_description:
    'Chào mừng bạn đến với Hành trình đếm ngược chào đón Giáng sinh thú vị cùng Remitano. Mỗi ngày là một cơ hội để giành lấy những giải thưởng hấp dẫn! Kho quà tặng trị giá $10.000, bao gồm các coin “chất" như USDT, BTC, DOGE, MATIC, ADA và nhiều Phiếu ưu đãi đang chờ bạn khám phá.',
  rules: 'Quy tắc chương trình christmas advent calendar',
  rules_part_1:
    'Từ <b>01 tháng 12 đến 24 tháng 12</b>, mỗi ngày người chơi có thể truy cập Trang Christmas Advent Calendar qua GameHub/Trang web/Đường link trực tiếp của Remitano và mở cánh cửa ngày hôm đó. Người chơi có thể được nhận Lượt miễn phí hoặc cần thực hiện một nhiệm vụ để có cơ hội giành được quà tặng ngày giá trị từ Remitano.',
  rules_part_2:
    'Remitano sẽ trao phần thưởng trên cơ sở <b>“First Come, First Served” (Ưu tiên người chơi trước)</b>, vì vậy người chơi hoàn thành nhiệm vụ sớm sẽ có khả năng cao hơn giành được giải thưởng. Mỗi ngày, sẽ có hơn 1000 người chiến thắng khi chọn mở ô cửa và hoàn thành nhiệm vụ, những người chiến thắng sẽ nhận được giải thưởng dành cho ngày hôm đó.',
  rules_part_3:
    'Mỗi ngày, người chơi chỉ có thể mở một cửa. Người chơi sẽ mở cửa đầu tiên vào ngày <b>01/12</b>, cửa thứ 2 vào ngày <b>02/12</b>, cửa thứ 3 vào ngày <b>03/12</b>, v.v. Tuy nhiên, các cửa đã mở có thể được giữ mở trong năm ngày, vì vậy người dùng vẫn có thể khám phá chiếc cửa đang bỏ lỡ trước khi hết hạn.',
  rules_part_4:
    'Hàng nghìn phần quà may mắn, bao gồm những đồng coin được săn đón như USDT, DOGE, BTC, TRX, MATIC, XRP và Phiếu ưu đãi Trading & Swap, đang chờ bạn khám phá mỗi ngày.',
  how_to_participate: 'Cách thức tham gia',
  how_to_participate_step_1: 'Bước Một',
  how_to_participate_step_1_description: 'Đăng nhập/Đăng ký tài khoản Remitano',
  how_to_participate_step_2: 'Bước Hai',
  how_to_participate_step_2_description_1:
    'Truy cập trò chơi trên Website hoặc Ứng dụng Remitano qua mục GameHub (Trò chơi)',
  how_to_participate_step_2_description_2:
    'Nhấp vào banner Christmas Advent Calendar để tham gia trò chơi',
  how_to_participate_step_2_description_3:
    'Sau khi đăng nhập vào Trò chơi, hãy nhấp vào chiếc cửa ngày hôm đó để nhận ngay lượt miễn phí hoặc nhiệm vụ của bạn.',
  how_to_participate_step_3: 'Bước Ba',
  how_to_participate_step_3_description_1:
    'Sử dụng Lượt thưởng hoặc Hoàn thành nhiệm vụ để nhận phần thưởng của bạn.',
  how_to_participate_step_3_description_2:
    'Phần thưởng sẽ được chuyển trực tiếp vào Ví Remitano của bạn sau khi Remitano xác nhận danh sách người chiến thắng đạt đủ điều kiện.',
  no_rewards_title: 'Cung chúc tân xuân Quý Mão 2023',
  no_rewards_description:
    'Hãy quay lại sớm vào ngày mai, mở một cánh cửa và thực hiện các nhiệm vụ để nhận phần thưởng của bạn.',
  verify_account: 'Xác thực tài khoản',
  button_discover: 'Khám phá ngay',
  welcome_text: 'Xin chào',
  friend: 'Bạn',
  story1_description:
    'Cùng nhau nhìn lại năm 2022 với những kỉ niệm đáng nhớ. Trong năm 2022 bạn đã đầu tư:',
  story2_desceiption:
    'Nạp và rút "fiat" trên Remitano thật dễ dàng và an toàn, nên bạn đã tất tay giao dịch mà không cần suy nghĩ.',
  deposit: 'Nạp',
  withdraw: 'Rút',
  call_yout: 'Gọi bạn là',
  about_remitano_title1: 'Trong năm 2022',
  about_remitano_title2: 'Remitano không ngừng phát triển',
  about_remitano_product: '___ sản phẩm và tính năng mới đã được ra mắt',
  about_remitano_coin: '___ triệu người dùng mới ',
  about_remitano_action: '___ hoạt động kết nối với người dùng',
  strive: 'Luôn nỗ lực vì khách hàng',
  feedback_quote:
    'Mỗi sự thay đổi để mang đến trải nghiệm tuyệt vời hơn cho nhà đầu tư là động lực để chúng tôi cố gắng mỗi ngày',
  register_now: 'Đăng ký ngay',
  story_end_title: '2022 thật tuyệt',
  story_end_description1: 'vì có bạn',
  story_end_description2: 'Hãy cùng nhau trải qua năm 2023 đầy hứa hẹn!',
  share: 'Chia sẻ',
  close: 'Đóng',
  next: 'Tiếp tục',
  get_turn: 'Nhận 1 lượt quay',
  lookback_share:
    'Nhìn lại một năm đầu tư crypto ở Remitano #2022withRemitano #Remitano #2022lookback',
  look_back: 'Nhìn lại',
  look_back_description: 'Remitano trong năm 2022',
  share_with_friends: 'Chia sẻ với bạn bè',
  download: 'Tải xuống hình ảnh',
  get_the_best_experience_witha_reliable_9_year_old_exchange:
    'Trải nghiệm dịch vụ tốt nhất với sàn giao dịch uy tín 9 năm tuổi',
  we_are_available_on: 'Tải ứng dụng',
  or_visit: 'Hoặc truy cập',
  explore_more_with_remitano_9_truths:
    'Khám phá thêm với #Remitano9Truths trên Facebook và Twitter của Remitano!',
  explore_more_with_remitano_9_truths_short: 'Khám phá thêm với #Remitano9Truths trên',
  birthday_terms_description:
    'Các nhiệm vụ sẽ lần lượt xuất hiện. Bạn có thể hoàn thành những lượt đã bỏ lỡ để giành về phần thưởng cho người chơi thường xuyên. Số lượng quà tặng có hạn và sẽ được trao thưởng sau khi người chơi hoàn thành nhiệm vụ theo cơ chế ai đến trước được trước.',
  terms_and_conditions: 'Điều khoản & Điều kiện',
  thank_you_title: 'Cảm ơn bạn đồng hành cùng Remitano!',
  thank_you_text:
    'Vì có bạn, Remitano vững vàng kỷ niệm năm thứ 9 phát triển với những dấu ấn thú vị sau:',
  highlight_1:
    'Ra đời năm 2014, Remitano là sàn giao dịch tiền mã hóa tiên phong mang crypto đến gần với mọi người',
  highlight_2:
    'Đạt cột mốc 1 triệu người dùng với khối lượng giao dịch là 3 tỷ đô la trong 3 năm đầu',
  highlight_3:
    'Chúng tôi luôn đặt người dùng làm trọng tâm và đảm bảo 7 Quyền lợi khách hàng tại Remitano',
  highlight_4:
    'Remitano hiện có 14 sản phẩm khác nhau đáp ứng đa dạng nhu cầu của các nhà đầu tư Crypto',
  highlight_5:
    '67% khối lượng giao dịch trên Remitano là từ các cặp crypto-fiat. Giao dịch an tâm cùng Remitano!',
  highlight_6:
    '5 trung tâm dịch vụ của Remitano trải dài khắp các múi giờ, sẵn sàng chăm sóc khách hàng 24/7',
  highlight_7: 'Đội ngũ chăm sóc khách hàng 24/7 của Remitano xử lý 1815 yêu cầu hỗ trợ/ngày',
  highlight_8: 'Gần 6 triệu người dùng tin tưởng và giao dịch với Remitano trong những năm qua',
  highlight_9:
    'Được đánh giá mức độ hài lòng cao 4.7/5.0 trên Trustpilot bới 11,935 người từ khắp nơi trên thế giới',
  complete: 'Hoàn thành',
  complete_and_earn: 'Hoàn thành và nhận quà',
  open_on: 'Bắt đầu vào',
  special_content:
    'Gửi lời chúc mừng sinh nhật tới @remitano trên Twitter cùng hashtag #RemitanoTurns9',
  mission_content_1: 'Cùng đếm ngược sinh nhật Remitano với bạn bè',
  mission_content_2: `Chia sẻ tweet từ Remitano lên Twitter cá nhân`,
  mission_content_3: `Tương tác với bài post của Remitano trên facebook`,
  mission_content_4: 'Remitano Trivia 1',
  mission_content_5: 'Tham gia cuộc thi "How I met Remitano" trên cộng đồng Remitano ',
  mission_content_6: `Chia sẻ tweet từ Remitano lên Twitter cá nhân`,
  mission_content_7: `Nhận phần thưởng cho user trung thành`,
  mission_content_8: `Remitano Trivia 2`,
  share_now: 'Chia sẻ ngay',
  thank_you: 'Thank you!',
  thank_des_1: 'Cảm ơn bạn vì lời chúc mừng sinh nhật',
  thank_des_2: `Số lượng người tham gia tiệc sinh nhật đã vượt quá mong đợi và rất tiếc, Remitano đã hết quà rồi. Chúng tôi hy vọng bạn đã có những kỉ niệm đáng nhớ với Remitano.`,
  oh_no: 'Oh No!',
  oh_no_des_1: 'Ủa! Có vẻ như phần thưởng của hôm nay đã hết!',
  oh_no_des_2: `Hãy quay lại sớm vào ngày mai và thực hiện các nhiệm vụ để mở các phần quà của bạn nhé.`,
};

export default words;
