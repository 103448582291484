import React, { useEffect } from 'react';
import { IRenderer } from 'look-back/interfaces';

export const Renderer: IRenderer = (props) => {
  useEffect(() => {
    props.action('play');
  }, [props.story]);

  const Content = props.story.originalContent as IRenderer;
  return <Content {...props} />;
};

export default Renderer;
