import { useGlobalContext } from 'contexts/GlobalContext';
import { Container } from 'react-bootstrap';
import classNames from 'classnames/bind';
import BootstrapWrapper from 'base-components/BootstrapWrapper';
import BirthdayProvider from '../../contexts/BirthdayContext';
import GlobalStyle from 'base-components/GlobalStyle';
import Header from '../Header/Header';
import styles from './Layout.module.scss';

const cx = classNames.bind(styles);

type Props = {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  const { isRemiApp, deviceInfo } = useGlobalContext();

  const { status_bar_height: statusBarHeight } = deviceInfo || {};
  return (
    <>
      {isRemiApp && (
        <div className={cx('navbar')} style={{ height: (statusBarHeight || 40) + 'px' }}></div>
      )}
      <BootstrapWrapper>
        <GlobalStyle>
          <BirthdayProvider>
            <div className={cx('background')}>
              <Header />
              <Container>{children}</Container>
            </div>
          </BirthdayProvider>
        </GlobalStyle>
      </BootstrapWrapper>
    </>
  );
};

export default Layout;
