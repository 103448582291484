import React from 'react';
import { useAuthContext } from 'contexts/AuthContext';
import classNames from 'classnames/bind';
import Locale from 'locale';
import { IRenderer } from 'look-back/interfaces';
import remiEndImage from 'assets/look-back/images/remiEnd.png';
import styles from './End.module.scss';

const cx = classNames.bind(styles);

const Story3: IRenderer = (props) => {
  const { username } = useAuthContext();

  return (
    <div
      id="story"
      className={cx('wrap')}
      style={{ maxHeight: window ? window.innerHeight : '100vh' }}
    >
      <div className={cx('story')}>
        <p className={cx('story__label')}>{!username ? '' : Locale.translate('story_end_title')}</p>
        <p className={cx('story__title')}>
          {!username ? '' : Locale.translate('story_end_description1')}
        </p>
        <img src={remiEndImage} className={cx('story__image')} />
        <div className={cx('story__bottom')}>
          <p className={cx('story__description')}>
            {!username
              ? Locale.translate('story_end_description2')
              : Locale.translate('story_end_description2')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Story3;
