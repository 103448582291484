import { useLanguageContext } from 'contexts/LanguageContext';
import { Dropdown } from 'react-bootstrap';
import classNames from 'classnames/bind';
import flagVi from 'assets/merry-christmas/images/flag-vi.png';
import flagEn from 'assets/merry-christmas/images/flag-en.png';
import styles from './ChangeLanguage.module.scss';

const cx = classNames.bind(styles);

const ChangeLanguege: React.FC = () => {
  const { language, onChangeLanguage } = useLanguageContext();

  return (
    <div className={cx('wrap')}>
      <Dropdown>
        <Dropdown.Toggle variant="transparent" id="language-basic">
          <img src={language == 'vi' ? flagVi : flagEn} alt="flag" className={cx('flag')} />
          <span>{language == 'vi' ? 'Tiếng Việt' : 'English'}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => onChangeLanguage?.('en')}>
            <img src={flagEn} alt="flag en" className={cx('flag')} />
            English
          </Dropdown.Item>
          <Dropdown.Item onClick={() => onChangeLanguage?.('vi')}>
            <img src={flagVi} alt="flag en" className={cx('flag')} />
            Tiếng Việt
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ChangeLanguege;
