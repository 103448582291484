import React, { Dispatch, SetStateAction, useMemo } from 'react';
import Locale from 'locale';
import classNames from 'classnames/bind';
import styles from './ChooseWeek.module.scss';
import { NextIcon, PreviousIcon } from '../Icons';

const cx = classNames.bind(styles);

type Props = {
  currentWeek: number;
  setCurrentWeek: Dispatch<SetStateAction<number>>;
};

const ChooseWeek: React.FC<Props> = ({ currentWeek, setCurrentWeek }) => {
  const isPrevDisabled = useMemo(() => currentWeek <= 1, [currentWeek]);
  const isNextDisabled = useMemo(() => currentWeek >= 4, [currentWeek]);

  const onNext = () => {
    if (!isNextDisabled) {
      setCurrentWeek(currentWeek + 1);
    }
  };

  const onPrev = () => {
    if (!isPrevDisabled) {
      setCurrentWeek(currentWeek - 1);
    }
  };
  return (
    <div className={cx('wrap-week')}>
      <div className={cx('week')}>
        <button className={cx(['week__button', 'hello'])}>
          <PreviousIcon
            onClick={() => onPrev()}
            fill={isPrevDisabled ? '#775084' : 'white'}
            size={24}
          />
        </button>
        <span className={cx('week__text')}>
          {Locale.translate('week')} {currentWeek}
        </span>
        <button className={cx('week__button')}>
          <NextIcon
            onClick={() => onNext()}
            fill={isNextDisabled ? '#775084' : 'white'}
            size={24}
          />
        </button>
      </div>
    </div>
  );
};

export default React.memo(ChooseWeek);
