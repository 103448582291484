import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { getTrackingId } from 'App';

const useGA = () => {
  useEffect(() => {
    if (getTrackingId()) ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return;
};

export default useGA;
