export interface ITitle {
  title: string;
  label: string;
  background: number;
  type: string;
  description: string;
}

export const titlesVi: ITitle[] = [
  {
    title: 'Cá "Koin"',
    label: 'Bạn đầu tư',
    background: 2,
    type: 'loại coin',
    description:
      'Trên Remitano có hơn 30 loại coin khác nhau để bạn thoải mái lựa chọn. Chúc bạn năm mới có một danh mục đầu tư tốt để nâng cao tỷ suất lợi nhuận nhé.',
  },
  {
    title: 'Thợ "đào vàng"',
    label: 'Bạn đào được',
    background: 3,
    type: 'RENEC',
    description:
      'Trong năm 2022 bạn đã chăm chỉ đào và kiếm được kha khá RENEC. RENEC đã chính thức mainnet. Giờ đây bạn có thể mua bán RENEC trên Remitano.',
  },
  {
    title: 'Chiến thần thanh Khoản',
    label: 'Bạn đầu tư',
    background: 2,
    type: 'USDT',
    description:
      'Thanh khoản cao của Remitano, không thể kể đến tổng giá trị ___ mà bạn đã bỏ vào hồ thanh khoản',
  },
  {
    title: 'Cao thủ lướt sóng',
    label: 'Bạn thực hiện',
    background: 2,
    type: 'giao dịch',
    description: 'Bạn chuyển từ kèo này sang kèo khác lẹ quá. Giao dịch rôm rả chỉ từ 10k.',
  },
  {
    title: 'Nhà đầu tư thông thái',
    label: 'Bạn cho vay',
    background: 1,
    type: 'USDT',
    description:
      'Bạn nắm bắt ngay lending, tận dụng tiền nhàn rỗi để thu về một cơ số tiền lãi suất nè. Dịch vụ an tâm, APR xứng tầm',
  },
  {
    title: 'Nhà ngoại giao',
    label: 'Kết nối',
    background: 1,
    type: 'người bạn',
    description:
      'Bạn đối nội, đối ngoại giỏi quá mà. Cảm ơn bạn trong năm 2022 đã không ngừng đóng góp và xây dựng cho cộng đồng crypto.',
  },
  {
    title: 'Tàu "ngầm"',
    label: 'Bạn giao dịch',
    background: 1,
    type: 'USDT',
    description:
      'Tiền thì bạn không thiếu mà tiền trong ví Remitano thì hơi ít. Nạp tiền và mua tiền mã hoá trên Remitano an toàn và dễ dàng trong tích tắc.',
  },
  {
    title: 'Baby shark',
    label: 'Bạn giao dịch',
    background: 4,
    type: 'USDT',
    description:
      'Trang bị thêm kiến thức và thông tin về thị trường qua trang Forum trên Remitano để sẵn sàng "chiến đấu" và tăng thêm kinh nghiệm nhé.',
  },
  {
    title: 'Nhà đầu tư Thịnh vượng',
    label: 'Bạn giao dịch',
    background: 1,
    type: 'USDT',
    description:
      'Bạn có những chiến lược đầu tư thật bền bỉ. Tốc độ giao dịch nhanh chóng trên Remitano sẽ giúp bạn nắm bắt thị trường thật tốt.',
  },
];

export const titlesEn: ITitle[] = [
  {
    title: '"Koin" fish',
    label: 'You invest',
    background: 2,
    type: 'types of coin',
    description:
      'There are more than 30 different coins on Remitano. Wish you a great year with more high-profit returns on your investments.',
  },
  {
    title: 'Gold Miner',
    label: 'You mine',
    background: 3,
    type: 'RENEC',
    description:
      'Because in 2022, you have worked hard to mine and earn a lot of RENEC. Now that RENEC is officially on the Main Net, you can Buy/Sell it on Remitano.',
  },
  {
    title: 'God of Liquidity',
    label: 'You invest',
    background: 2,
    type: 'USDT',
    description:
      "Many thanks for your astounding contribution to Remitano's high liquidity. In 2022, you put ___ into the liquidity pool.",
  },
  {
    title: 'Surfer',
    label: 'You made',
    background: 1,
    type: 'transactions',
    description:
      'You are a swing trader, switching quickly from one coin to another. Enjoy seamless transactions with a small amount of money in Remitano.',
  },
  {
    title: 'Wise Investor',
    label: 'You lend',
    background: 1,
    type: 'USDT',
    description:
      'You quickly grasp lending and take advantage of idle money to collect interest. Cheers to a New Year of enjoying peace of mind service and worthy APR!',
  },
  {
    title: 'Collaborator',
    label: 'You refer',
    background: 1,
    type: 'friends',
    description:
      'Because you are exceptional at connecting with people. Thank you for constantly contributing towards building the crypto community in 2022.',
  },
  {
    title: 'Submarine',
    label: 'Trading volume',
    background: 1,
    type: 'USDT',
    description:
      "You are not lacking money, but you don't have much in your Remitano wallet. Depositing and buying Cryptocurrencies on Remitano is safe and easy, so let's do more this new year!",
  },
  {
    title: 'Baby shark',
    label: 'Trading volume',
    background: 4,
    type: 'USDT',
    description:
      "Explore more knowledge about the crypto market through Remitano's Forum Page. Gain the best experience to thrive in the industry.",
  },
  {
    title: 'Crypto Treasure',
    label: 'Trading volume',
    background: 1,
    type: 'USDT',
    description:
      "Your investment strategy is amazing. Remitano's speedy transaction will help you maximise profit and make gains in the market.",
  },
];
