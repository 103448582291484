export const DEFAULT_INTERVAL = 8500;
export const URL_GAME_HUB = 'https://remitano.com/home/gamehub';
export const URL_REGISTER = 'https://remitano.com/home/login';
export const URL_HOMEPAGE = 'https://remitano.com';
export const URL_LOGIN_PAGE =
  'https://remitano.com/home/game-in-app/look-back?utm_source=forumvn&utm_medium=221122_br&utm_campaign=aimforthevictory_forumvn_221122_br';

export const ACTION = {
  Play: 'play',
  Pause: 'pause',
  Next: 'next',
  Previous: 'previous',
};

export const COIN_COLORS = ['#5A3FFF', '#268AFF', '#1ED6FF', '#3DFFDC'];

export const EMPTY_COLOR = '#e0e0e0';

export const SHARE_URL = 'https://lookback.remitano.com/';
