import { useEffect, useState } from 'react';
import { IGlobalContext, IRenderer, IStory } from 'look-back/interfaces';
import GlobalContext from 'look-back/contexts/Global';
import StoriesContext from 'look-back/contexts/Stories';
import Container from 'look-back/components/Container/Container';
import Renderer from 'look-back/components/Renderer/Renderer';

export interface Props {
  stories: IStory[];
  loader?: JSX.Element;
  loop?: boolean;
  isPaused?: boolean;
  currentIndex?: number;
  onAllStoriesEnd?: any;
  onStoryStart?: any;
  onStoryEnd?: any;
  isShared?: boolean;
}

const Stories = function (props: Props) {
  const context: IGlobalContext = {
    loop: props.loop,
    isPaused: props.isPaused,
    currentIndex: props.currentIndex,
    onStoryStart: props.onStoryStart,
    onStoryEnd: props.onStoryEnd,
    onAllStoriesEnd: props.onAllStoriesEnd,
    isShared: props.isShared,
  };
  const [stories, setStories] = useState<{ stories: IStory[] }>({
    stories: generateStories(props.stories),
  });
  useEffect(() => {
    setStories({ stories: generateStories(props.stories) });
  }, [props.stories]);

  return (
    <GlobalContext.Provider value={context}>
      <StoriesContext.Provider value={stories}>
        <Container />
      </StoriesContext.Provider>
    </GlobalContext.Provider>
  );
};

const generateStories = (stories: IStory[]) => {
  return stories.map((s, index: number) => {
    let story: IStory = {};

    if (typeof s === 'object') {
      story = Object.assign(story, s);
    }

    story.originalContent = story.content;
    story.content = Renderer;
    return story;
  });
};

export default Stories;
