import { Language } from 'locale';
import { toast } from 'react-toastify';
import RequestHelper from 'utils/RequestHelper';

class ChristmasAdventService {
  getChristmasAdventAllDay = async (): Promise<any[] | undefined> => {
    try {
      const res = await RequestHelper.post('/api/v1/christmas_advent_calendars/all_days');
      if (res.data && res.code === 200) {
        return res?.data || [];
      }
    } catch (error: any) {
      return error;
    }
  };
  submitReward = async (date: number, lang: string) => {
    try {
      const res = await RequestHelper.post('/api/v1/christmas_advent_calendars/submit_reward', {
        date: `day${date}`,
        lang: lang === Language.vi ? 'vn' : 'en',
      });
      if (res.code === 200) {
        return true;
      } else if (res.code === 404 && res.message.includes('It is out of reward in')) {
        return true;
      } else {
        toast.error(res?.message);
        return false;
      }
    } catch (error: any) {
      toast.error('An error has occurred');
      return false;
    }
  };
}

export default new ChristmasAdventService();
