import React, { useEffect } from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import useGA from 'hooks/useGA';
import BootstrapWrapper from 'base-components/BootstrapWrapper';
import GlobalStyle from 'base-components/GlobalStyle';

import StyleSheetWrapper from './components/StyleSheetWrapper';
import Home from './pages/home';
import ToastWrapper from 'base-components/ToastWrapper';
import RemitanoBrowser from 'utils/RemitanoBrowser';

const MainApp: React.FC = () => {
  useGA();

  useEffect(() => {
    window.scrollTo(0, 0);
    RemitanoBrowser.config({
      barStyle: 'light-content',
      hideNavBar: true,
    });
  }, []);

  return (
    <BootstrapWrapper>
      <ToastWrapper>
        <GlobalStyle>
          <StyleSheetWrapper>
            <Routes>
              <Route path="/" element={<Home />} />
            </Routes>
          </StyleSheetWrapper>
        </GlobalStyle>
      </ToastWrapper>
    </BootstrapWrapper>
  );
};

export default MainApp;
