import React, { useMemo, useState } from 'react';
import { Image } from 'react-bootstrap';
import classNames from 'classnames/bind';
import styles from './Match.module.scss';
import WCButton from '../WCButton';
import { CheckMarkIcon } from 'base-components/Icons';
import matchDisabled from 'assets/world-cup/match-disabled.png';

import giftIcon from 'assets/world-cup/wc-gift.png';
import moment from 'moment';
import { FLAG_MAPPING_IMG } from 'world-cup/flag-mapping';
import Locale from 'locale';
import useMediaQuery from 'hooks/useMediaQuery';
import { useSearchParams } from 'react-router-dom';

const cx = classNames.bind(styles);

const nf = new Intl.NumberFormat();

export type MatchVariant = 'main' | 'white' | 'disabled';
type Props = {
  variant?: MatchVariant;
  onVote?: (key_match: string, team_name?: string) => void;
  isLoading?: boolean;
} & Match & { keyMatch: string };

const DEFAULT_GIFT = '6.666.666';
const mapGiftByRouter: { [key: string]: string } = {
  'round-of-16-teams': '8.888.888',
  'quater-finals': '9.999.999',
  'semi-final': '9.999.999',
  'final-round': '17.000.000',
};

const Match: React.FC<Props> = ({
  variant = 'main',
  startGuessing,
  endGuessing,
  participants,
  guessing,
  team,
  score,
  onVote,
  keyMatch,
  amount = 0,
  isLoading = true,
}) => {
  const [searchParams] = useSearchParams();
  const [teamSelected, setTeamSelected] = useState(-1);
  const matches = useMediaQuery('(min-width: 992px)');

  const isBanner = variant === 'main';
  const invalidVote = !amount;
  const canVote =
    Date.now() >= moment(startGuessing).valueOf() &&
    Date.now() <= moment(endGuessing).valueOf() &&
    amount;
  const formatTimeHead = isBanner ? 'HH:ss' : 'DD/MM';

  const teamOneName = team?.split('-')?.[0]?.toUpperCase();
  const teamTwoName = team?.split('-')?.[1]?.toUpperCase();
  const flagOne = FLAG_MAPPING_IMG[teamOneName as any];
  const flagTwo = FLAG_MAPPING_IMG[teamTwoName as any];

  const isExpired = moment(endGuessing).valueOf() < Date.now();
  const isCanVote = canVote && flagTwo && flagOne;
  let amountGift = mapGiftByRouter?.[searchParams.get('tab') as any] || DEFAULT_GIFT;
  if (keyMatch === 'third-match1') {
    amountGift = '11.111.111';
  }

  const getStatusMatch = useMemo(() => {
    if (guessing) {
      return Locale.translate('voted');
    }

    if (score) {
      return Locale.translate('match_end');
    }

    if (isExpired) {
      return Locale.translate('expired_match');
    }

    if (isCanVote) {
      return Locale.translate('submit');
    }

    return Locale.translate('comming_soon');
  }, [canVote, flagTwo, flagOne, score, guessing]);

  const mapClassOfStatus = useMemo(() => {
    if (guessing) {
      return 'match-btn-voted';
    }
    if (score) {
      return 'match-btn-end';
    }

    if (moment(endGuessing).valueOf() < Date.now()) {
      return 'match-btn-expired';
    }

    if (isCanVote) {
      return 'match-btn-can-vote';
    }

    return 'match-btn-not-yet-open';
  }, [canVote, flagTwo, flagOne, score, guessing]);

  const handleVote = () => {
    onVote?.(keyMatch, team?.split('-')?.[teamSelected - 1]);
    setTeamSelected(-1);
  };

  return (
    <div className={cx('match')}>
      <div className={cx('match-head')}>
        <span className={cx('match-head_name')}>{teamOneName}</span>
        <span className={cx('match-head_name')}>{teamTwoName}</span>
        <div className={cx('match-head_time')}>
          <span>{score || (endGuessing && moment(endGuessing).format(formatTimeHead))}</span>
        </div>
      </div>
      <div className={cx('match-content', variant)}>
        {endGuessing && isBanner && (
          <span className={cx('match-content_time', variant)}>
            {endGuessing && moment(endGuessing).format('DD/MM')}
          </span>
        )}
        <div className={cx('match-content_vs')}>
          <div
            className={cx(
              'match-content_vs_flag',
              (!flagOne || !canVote || guessing) && 'not-vote',
              teamSelected === 1 && 'active'
            )}
          >
            <CheckMarkIcon className={cx('icon')} size={25} />
            <Image
              className={cx(!flagOne && 'flag-valid', variant)}
              onClick={() => setTeamSelected(1)}
              src={flagOne ? flagOne : (matchDisabled as string)}
            />
          </div>
          <div className={cx('match-content_vs_text')}>
            <span className={cx('vs_text', variant)}>VS</span>
          </div>
          <div
            className={cx(
              'match-content_vs_flag',
              (!flagTwo || !canVote || guessing) && 'not-vote',
              teamSelected === 2 && 'active'
            )}
          >
            <CheckMarkIcon className={cx('icon')} size={25} />
            <Image
              fluid
              className={cx(!flagTwo && 'flag-valid', variant)}
              onClick={() => setTeamSelected(2)}
              src={flagTwo ? flagTwo : (matchDisabled as string)}
            />
          </div>
        </div>
        {!invalidVote ? (
          <>
            {/* <span className={cx('match-content_participants', variant)}>
              {Locale.translate('participants')}:{' '}
              {participants ? nf.format(Number(participants)).replace(/,/g, '.') : 'XXX'}
            </span> */}
            {amount && !isBanner ? (
              <div className={cx('gift')}>
                <Image fluid src={giftIcon} className={cx('gift-icon')} />
                <span className={cx('gift-name')}>
                  {Locale.translate('gift_name', { amount: amountGift })}
                </span>
              </div>
            ) : (
              <></>
            )}

            <div className={cx('match-content_form', 'form')}>
              <span className={cx('form-text', variant)}>
                {isCanVote && Locale.translate('match_select')}
                {matches && <>&nbsp;</>}
              </span>
              <WCButton
                block
                onClick={handleVote}
                disabled={Boolean(teamSelected === -1 || guessing)}
                className={cx('match-btn', mapClassOfStatus)}
              >
                {getStatusMatch}
              </WCButton>
            </div>
          </>
        ) : (
          <div className={cx('disabled-match', isLoading && 'loading')}>
            <span>{isLoading ? '' : Locale.translate('date_time')}</span>
            <span>{isLoading ? '' : Locale.translate('match_not_vote')}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Match);
