export interface Gift {
  id: number;
  title: string;
  description?: string;
  image: string;
  messageEnTitle: string;
  messageEnDescription: string;
  messageViTitle: string;
  messageViDescription: string;
}

export const gifts: Gift[] = [
  {
    id: 0,
    title: 'Unknown',
    description: '',
    image: 'comeback',
    messageEnTitle: 'Unknown',
    messageEnDescription: 'Unknown',
    messageViTitle: 'Unknown',
    messageViDescription: 'Unknown',
  },
  {
    id: 1,
    title: 'Voucher',
    description: '',
    image: 'cash_back',
    messageEnTitle: 'Congratulations!🎉 You just won a 10% Cashback Swap Voucher! ',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results. Play More and Win More!',
    messageViTitle: 'Xin chúc mừng! 🎉 Bạn vừa giành được phiếu ưu đãi 10% Cashback Swap Voucher! ',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng. Cùng chơi nhiều và Thắng lớn hơn nữa nhé!',
  },
  {
    id: 2,
    title: 'Voucher',
    description: '',
    image: 'cash_back',
    messageEnTitle: 'Congratulations!🎉 You just won a 20% Cashback Swap Voucher!',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results. Play More and Win More!',
    messageViTitle:
      'Chúc mừng bạn!🎉 Bạn vừa giành được một phiếu ưu đãi 20% Cashback Swap Voucher.',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng.',
  },
  {
    id: 3,
    title: 'Voucher',
    description: '',
    image: 'cash_back',
    messageEnTitle:
      'Congratulations on your Winning Streak!😍 You just won a 50% Cashback Swap Voucher!😊',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results.',
    messageViTitle:
      'Xin chúc mừng chuỗi chiến thắng của bạn!😍 Bạn vừa giành được một Phiếu hoán đổi tiền mặt 50%!😊',
    messageViDescription:
      'Số tiền này sẽ được ghi có trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả tổng giải thưởng.',
  },
  {
    id: 4,
    title: 'Tezos',
    description: '(XTZ)',
    image: 'tezos',
    messageEnTitle: 'Congratulations Champ!🎉 You"ve done it again. Here"s 0.389190 TRX as a gift.',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results.',
    messageViTitle:
      'Xin chúc mừng Nhà vô địch!🎉 Bạn đã làm được điều đó một lần nữa. Đây là 0,389190 TRX làm quà tặng.',
    messageViDescription:
      'Số tiền này sẽ được ghi có trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả tổng giải thưởng.',
  },
  {
    id: 5,
    title: 'Shiba Inu',
    description: '(SHIB)',
    image: 'shiba',
    messageEnTitle:
      'Hearty Congratulations!❤️ Christmas just got better for you as you just won 1,100.33 SHIB.',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results.',
    messageViTitle:
      'Nồng nhiệt chúc mừng!❤️ Mùa giáng sinh vừa trở nên tuyệt vời hơn với bạn vì bạn đã giành được Phiếu ưu đãi 1,100.33 SHIB.',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng.',
  },
  {
    id: 6,
    title: 'Shiba Inu',
    description: '(SHIB)',
    image: 'shiba',
    messageEnTitle:
      'Hearty Congratulations!❤️ Christmas just got better for you as you just won 2,195.56 SHIB.',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results.',
    messageViTitle:
      'Nồng nhiệt chúc mừng!❤️ Mùa giáng sinh vừa trở nên tuyệt vời hơn với bạn vì bạn đã giành đãi 2,195.56 SHIB.',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng.',
  },
  {
    id: 7,
    title: 'Dogecoin',
    description: '(DOGE)',
    image: 'doge',
    messageEnTitle: 'Congratulations!🎉 You just received 0.12402085 DOGE! ',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results. Want to win more? Come back tomorrow!',
    messageViTitle: 'Xin chúc mừng! 🎉 Bạn vừa nhận được 0.12402085 DOGE!',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng. Muốn thắng nhiều hơn? Quay lại tiếp vào ngày mai nhé!',
  },
  {
    id: 8,
    title: 'Dogecoin',
    description: '(DOGE)',
    image: 'doge',
    messageEnTitle: 'Congratulations!🎉 You just received 0.24844188 DOGE! ',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results. Want to win more? Come back tomorrow!',
    messageViTitle: 'Xin chúc mừng! 🎉 Bạn vừa nhận được 0.24844188 DOGE!',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng. Muốn thắng nhiều hơn? Quay lại tiếp vào ngày mai nhé!',
  },
  {
    id: 9,
    title: 'Stellar',
    description: '(XLM)',
    image: 'stellar',
    messageEnTitle:
      'Congratulations Friend!😁 A perfect way to end the year because you just won 0.11397455 XLM.',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results. Keep Winning!',
    messageViTitle:
      'Chúc mừng người bạn!😁 Một kết năm hoàn hảo vì bạn vừa giành được 0.11397455 XLM.',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng. Tiếp tục chiến thắng nhé!',
  },
  {
    id: 10,
    title: 'TRON',
    description: '(TRX)',
    image: 'tron',
    messageEnTitle: 'Congrats to you, Friend!😊 You just won 0.197172 TRX. Isn"t that exciting?',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results.',
    messageViTitle: 'Ăn mừng thôi! Bạn vừa giành được 0.197172 TRX. Quá đỉnh đúng không!',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng.',
  },
  {
    id: 11,
    title: 'TRON',
    description: '(TRX)',
    image: 'tron',
    messageEnTitle: 'Congrats to you, Friend!😊 You just won 0.389190 TRX. Isn"t that exciting?',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results.',
    messageViTitle: 'Ăn mừng thôi! Bạn vừa giành được 0.389190 TRX. Quá đỉnh đúng không!',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng.',
  },
  {
    id: 12,
    title: 'Cardano',
    description: '(ADA)',
    image: 'cardano',
    messageEnTitle: 'Happy Holidays!🎉 You just received a Gift of 0.03194332 ADA!',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results. You can still win more!😊',
    messageViTitle: 'Chúc Giáng sinh an lành! Bạn vừa nhận được Quà tặng là 0.03194332 ADA!',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng. Chiến thắng tiếp theo vẫn đợi bạn đấy! 😊',
  },
  {
    id: 13,
    title: 'Cardano',
    description: '(ADA)',
    image: 'cardano',
    messageEnTitle: 'Happy Holidays!🎉 You just received a Gift of 0.06389098 ADA!',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results. You can still win more!😊',
    messageViTitle: 'Chúc Giáng sinh an lành! Bạn vừa nhận được Quà tặng là 0.06389098 ADA!',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng. Chiến thắng tiếp theo vẫn đợi bạn đấy! 😊',
  },
  {
    id: 14,
    title: 'Bitcoin',
    description: '(BTC)',
    image: 'bitcoin',
    messageEnTitle: 'Happy Holidays to you and yours!🎉 You just won 0.00003 BTC!',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results.',
    messageViTitle:
      'Chúc bạn và những người thân yêu có kỳ nghỉ lễ vui vẻ!🎉 Bạn vừa giành được 0,00003 BTC!',
    messageViDescription:
      'Số tiền này sẽ được ghi có trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả tổng giải thưởng.',
  },
];
