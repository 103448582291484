import { useCallback, useState } from 'react';

const usePopup = () => {
  const [isRequiredLoginPopup, setRequiredLoginPopup] = useState(false);

  const handlSetRequiredLoginPopup = useCallback((status: boolean) => {
    setRequiredLoginPopup(status);
  }, []);

  return {
    isRequiredLoginPopup,
    onSetRequiredLoginPopup: handlSetRequiredLoginPopup,
  };
};

export default usePopup;
