import { useEffect, useState } from 'react';
import UserService from 'services/UserService';

const useAuth = ({ initLoad = false }: { initLoad?: boolean }) => {
  const [loading, setLoading] = useState(initLoad);
  const [userInfo, setUserInfo] = useState<any>(null);
  const fetch = async () => {
    setLoading(true);
    const response: any = await UserService.getCurrentUser();
    setUserInfo(response);
    setLoading(false);
  };

  useEffect(() => {
    if (initLoad) {
      fetch();
    }
  }, []);

  return {
    fetch,
    loading,
    userInfo,
  };
};

export default useAuth;
