export const getCurrentWeek = (): number => {
  const date = new Date();
  const startWeekDayIndex = 1;
  const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const firstDay = firstDate.getDay();

  let weekNumber = Math.ceil((date.getDate() + firstDay) / 7);
  if (startWeekDayIndex === 1) {
    if (date.getDay() === 0 && date.getDate() > 1) {
      weekNumber -= 1;
    }

    if (firstDate.getDate() === 1 && firstDay === 0 && date.getDate() > 1) {
      weekNumber += 1;
    }
  }
  return weekNumber <= 4 ? weekNumber : 4;
};

export const getCurrentWeekFromDay = (day: number) => {
  const count = (day + 2) / 7 + 1;
  const weekNumber = Number.parseInt(count.toString());

  return weekNumber <= 0 ? 1 : weekNumber > 4 ? 4 : weekNumber;
};

export const getDate = (date: string): Date => {
  return new Date(date.replace(' ', 'T'));
};
