import React from 'react';
import classNames from 'classnames/bind';
import { Event } from 'birthday/contexts/BirthdayContext';
import styles from './MissionItem.module.scss';
import useMediaQuery from 'hooks/useMediaQuery';
import { useSpring, animated } from 'react-spring';
import { getDate } from 'merry-christmas/utils/date';
import moment from 'moment';
import Locale from 'locale';
interface Props {
  event: Event;
  onPress?(event: Event): void;
}

const cx = classNames.bind(styles);

const MissionItem = ({ event, onPress }: Props) => {
  const { startDate, endDate, date, isCompleted, hasGift } = event;
  const isXs = useMediaQuery('(max-width: 576px)');
  const [isHover, setIsHover] = React.useState(false);
  const [currenDate, setCurrentDate] = React.useState<Date>(new Date(getDate(event.currentDate)));

  React.useEffect(() => {
    setCurrentDate(getDate(event.currentDate));
  }, [event]);

  const missionImage = React.useMemo(() => {
    switch (date) {
      case 1:
      case 8:
        return (
          <img src={require(`assets/birthday/images/gift-usdt.png`)} className={cx('image')} />
        );
      case 2:
      case 4:
        return <img src={require(`assets/birthday/images/gift-ada.png`)} className={cx('image')} />;
      case 3:
      case 6:
        return <img src={require(`assets/birthday/images/gift-eth.png`)} className={cx('image')} />;
      case 5:
      case 7:
        return (
          <img src={require(`assets/birthday/images/gift-shiba.png`)} className={cx('image')} />
        );
      default:
        return (
          <img src={require(`assets/birthday/images/gift-usdt.png`)} className={cx('image')} />
        );
    }
  }, [date, isXs]);

  const isExpiry: boolean = React.useMemo(() => {
    return currenDate > getDate(endDate) && !isCompleted;
  }, [currenDate, isCompleted, endDate]);

  const canOpen: boolean = React.useMemo(
    () => !isExpiry && currenDate > getDate(startDate),
    [currenDate, isExpiry, startDate]
  );

  const missionStatus = React.useMemo(() => {
    if (isCompleted)
      return {
        status: Locale.translate('complete'),
        background: '#15D160',
        color: '#fff',
      };
    if (isExpiry)
      return {
        status: Locale.translate('expired'),
        background: '#DA3855',
        color: '#fff',
      };
    if (canOpen)
      return {
        status: Locale.translate('complete_and_earn'),
        background: 'linear-gradient(180deg, #71D069 0%, #31A961 100%)',
        color: '#fff',
      };
    return {
      status: (
        <span>
          {Locale.translate('open_on')} <b>{moment(startDate).format('MMM YY')}</b>
        </span>
      ),
      background: 'linear-gradient(180deg, #ecd495 0%, #dfa73c 100%)',
      color: '#400857',
    };
  }, [isExpiry, canOpen, isCompleted, startDate]);

  const handleMouseEnter = () => {
    if (canOpen) {
      setIsHover(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleOpen = () => {
    if (!isXs) return;
    if (canOpen) {
      setIsHover(!isHover);
    } else {
      setIsHover(false);
    }
  };

  const flyoutAnimation = useSpring({
    maxHeight: isHover && canOpen ? '0px' : '367px',
    overflow: 'hidden',
  });

  return (
    <div
      className={cx('container', isXs ? 'col-6' : 'col-3')}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleOpen}
    >
      <animated.div className={cx('flyout')} style={flyoutAnimation}>
        {(isCompleted || isExpiry) && <div className={cx('shadow-box')} />}
        <div className={cx('status-box')}>
          <div
            className={cx('status-content')}
            style={{
              background: missionStatus.background,
              padding: isXs ? '0px 3px' : '3px 24px',
              color: missionStatus.color,
            }}
          >
            <span className={cx('status')}>{missionStatus.status}</span>
          </div>
        </div>
        {missionImage}
      </animated.div>

      <div className={cx('action-box')}>
        <span style={{ fontSize: isXs ? '16px' : '20px' }}>
          {Locale.translate(`mission_content_${date}`)}
        </span>
        <div className={cx('action-btn')} onClick={() => onPress && onPress(event)}>
          {Locale.translate('complete')}
        </div>
      </div>
    </div>
  );
};

export default MissionItem;
