import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from 'contexts/GlobalContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import classNames from 'classnames/bind';

import style from './RequiedLoginPopup.module.scss';
import Locale from 'locale';
import CommonHelper from 'utils/CommonHelper';
import { useLanguageContext } from 'contexts/LanguageContext';

const cx = classNames.bind(style);

const RequiedLoginPopup: React.FC = () => {
  const navigate = useNavigate();

  const { isRequiredLoginPopup, isRemiApp } = useGlobalContext();
  const { language } = useLanguageContext();
  const handleBack = () => {
    navigate(-1);
  };

  const handleLogin = () => {
    CommonHelper.redirectUrl(
      isRemiApp,
      `/home/${language === 'vi' ? 'vn' : 'au'}/game-in-app/${
        process.env.REACT_APP_GAME
      }?utm_source=forumvn&utm_medium=221122_br&utm_campaign=aimforthevictory_forumvn_221122_br`
    );
  };

  return (
    <Modal show={isRequiredLoginPopup} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <h4>{Locale.translate('required_login')}</h4>
        <p>{Locale.translate('required_login_des')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleBack} className={cx('btn-back')} variant="outline-dark">
          {Locale.translate('back')}
        </Button>
        <Button className={cx('btn-login')} onClick={handleLogin}>
          {Locale.translate('required_login')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RequiedLoginPopup;
