import React from 'react';
import { ThemeProvider } from 'react-bootstrap';

import './BootstrapWrapper.scss';

type Props = {
  children: React.ReactNode;
};

const BootstrapWrapper: React.FC<Props> = ({ children }) => {
  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs"
    >
      {children}
    </ThemeProvider>
  );
};

export default BootstrapWrapper;
