import { useEffect, useState } from 'react';
import useQuery from 'hooks/useQuery';
import { useMutation } from '@tanstack/react-query';
import { IStory } from 'look-back/interfaces';
import { Language } from 'locale';
import { useLanguageContext } from 'contexts/LanguageContext';
import Layout from 'look-back/components/Layout/Layout';
import Stories from 'look-back/components/Stories/Stories';
import StoryStart from 'look-back/components/Stories/Start';
import Story1 from 'look-back/components/Stories/Story1';
import Story2 from 'look-back/components/Stories/Story2';
import Story3 from 'look-back/components/Stories/Story3';
import Story4 from 'look-back/components/Stories/Story4';
import Story5 from 'look-back/components/Stories/Story5';
import StoryEnd from 'look-back/components/Stories/End/End';
import Video from 'look-back/components/Stories/Video';
import LookbackService from 'look-back/services/LookbackService';
import { isEmpty } from 'lodash';

const Home = () => {
  const query = useQuery();
  const [isStart, setIsStart] = useState<boolean>(false);
  const [isShared, setIsShared] = useState<boolean>(false);
  const { onChangeLanguage } = useLanguageContext();
  const [dataStory, setDataStory] = useState<any>({});
  const [stories, setStories] = useState<IStory[]>([
    {
      content: Story3,
    },
    {
      content: Story4,
    },
    {
      content: StoryEnd,
    },
  ]);

  const lang = query.get('lang');

  const {
    isLoading,
    data,
    mutate: getData,
  } = useMutation({
    mutationFn: async () => await LookbackService.getData(),
  });

  useEffect(() => {
    () => onChangeLanguage?.((lang as Language) ?? Language.en);
  }, [lang]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setDataStory(data);
  }, [data]);

  useEffect(() => {
    const stories: IStory[] = [];
    if (dataStory?.summary && !isEmpty(dataStory?.summary)) {
      stories.push({
        content: Story1,
        data: dataStory?.summary ?? [],
      });
    }
    if (dataStory?.fiat && !isEmpty(dataStory?.fiat)) {
      stories.push({
        content: Story2,
        data: dataStory?.fiat,
      });
    }
    if (dataStory?.fish_coin) {
      stories.push({
        content: Story3,
        data: {
          index: 0,
          value: dataStory?.fish_coin,
        },
      });
    }
    if (dataStory?.gold_digger) {
      stories.push({
        content: Story3,
        data: {
          index: 1,
          value: dataStory?.gold_digger,
        },
      });
    }
    if (dataStory?.liquidity_god) {
      stories.push({
        content: Story3,
        data: {
          index: 2,
          value: dataStory?.liquidity_god,
        },
      });
    }
    if (dataStory?.surf_master) {
      stories.push({
        content: Story3,
        data: {
          index: 3,
          value: dataStory?.surf_master,
        },
      });
    }
    if (dataStory?.savvy_investor) {
      stories.push({
        content: Story3,
        data: {
          index: 4,
          value: dataStory?.savvy_investor,
        },
      });
    }
    if (dataStory?.diplomat && dataStory?.diplomat > 1) {
      stories.push({
        content: Story3,
        data: {
          index: 5,
          value: dataStory?.diplomat,
        },
      });
    }
    if (dataStory?.submarine_baby_prosperous) {
      if (dataStory?.submarine_baby_prosperous < 100) {
        stories.push({
          content: Story3,
          data: {
            index: 6,
            value: dataStory?.submarine_baby_prosperous,
          },
        });
      } else if (dataStory?.submarine_baby_prosperous < 10000) {
        stories.push({
          content: Story3,
          data: {
            index: 7,
            value: dataStory?.submarine_baby_prosperous,
          },
        });
      } else {
        stories.push({
          content: Story3,
          data: {
            index: 8,
            value: dataStory?.submarine_baby_prosperous,
          },
        });
      }
    }
    stories.push({
      content: Video,
      duration: 56000,
    });
    stories.push({
      content: Story4,
    });
    stories.push({
      content: Story5,
    });
    stories.push({
      content: StoryEnd,
    });
    setStories(stories);
    setIsShared(dataStory?.shared);
  }, [dataStory]);

  return (
    <Layout>
      {isStart ? (
        <Stories loop={false} stories={stories} isShared={isShared} />
      ) : (
        <StoryStart setIsStart={setIsStart} isLoading={isLoading} />
      )}
    </Layout>
  );
};

export default Home;
