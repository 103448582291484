import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import ChristmasAdventService from 'merry-christmas/services/ChristmasAdventService';

export interface Event {
  date: number;
  giftId: number;
  isCompleted: boolean;
  startDate: string;
  endDate: string;
  currentDate: string;
  hasGift: boolean;
  campaignUrlVi: string;
  actionCompletedIdVi: number;
  campaignUrlEn: string;
  actionCompletedIdEn: number;
}

interface Week {
  week: number;
  events: Event[];
}

type ChristmasAdventContextType = {
  isLoading: boolean;
  weeks: Week[];
  getChristmasAdventAllDay?: VoidFunction;
};

const ChristmasAdventProviderContext = createContext<ChristmasAdventContextType>({
  isLoading: false,
  weeks: [],
});

type Props = {
  children: React.ReactNode;
};

const formatGift = (events: Event[]): Week[] => {
  const weeks: Week[] = [
    {
      week: 1,
      events: [],
    },
    {
      week: 2,
      events: [],
    },
    {
      week: 3,
      events: [],
    },
    {
      week: 4,
      events: [],
    },
  ];
  if (!events.length) return weeks;
  events.forEach((gift: Event, index: number) => {
    if (index <= 3) weeks[0].events.push(gift);
    else {
      const week = (index + 3) / 7;
      weeks[Number.parseInt(week.toString())].events.push(gift);
    }
  });
  return weeks;
};

const ChristmasAdventProvider: React.FC<Props> = ({ children }) => {
  const [weeks, setWeeks] = useState<Week[]>([]);
  const {
    isLoading,
    data,
    mutate: getChristmasAdventAllDay,
  } = useMutation({
    mutationFn: async () => await ChristmasAdventService.getChristmasAdventAllDay(),
  });

  useEffect(() => {
    getChristmasAdventAllDay();
  }, []);

  useEffect(() => {
    if (data) {
      setWeeks(formatGift(data as Event[]));
    }
  }, [data]);

  const valueContext: ChristmasAdventContextType = {
    weeks: weeks,
    isLoading,
    getChristmasAdventAllDay,
  };
  return (
    <ChristmasAdventProviderContext.Provider value={valueContext}>
      {children}
    </ChristmasAdventProviderContext.Provider>
  );
};

export default ChristmasAdventProvider;

export const useChristmasAdventContext = () => useContext(ChristmasAdventProviderContext);
