import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useAuthContext } from 'contexts/AuthContext';
import { useGlobalContext } from 'look-back/contexts/Global';
import Locale from 'locale';
import { ShareFlatIcon, ShareIcon } from 'look-back/components/Icons';
import { useStoriesContext } from 'look-back/contexts/Stories';
import styles from './Share.module.scss';

const cx = classNames.bind(styles);

interface Props {
  currentId: number;
  handleOpenShare: () => void;
}

const Share: React.FC<Props> = (props) => {
  const { currentId, handleOpenShare } = props;
  const { isAuth } = useAuthContext();
  const { isShared } = useGlobalContext();
  const { stories } = useStoriesContext();
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const idInterval = setInterval(() => {
      setShow(!show);
      clearInterval(idInterval);
    }, 4000);
  }, [show]);

  return (
    <>
      {currentId === stories.length - 1 ? (
        <button className={cx('share-friend')} onClick={handleOpenShare}>
          {Locale.translate('share_with_friends')}
          <ShareFlatIcon className={cx('share-friend__icon')} />
        </button>
      ) : (
        <button className={cx('share')} onClick={handleOpenShare}>
          <ShareIcon color={currentId >= stories.length - 4 ? '#9B59B6' : 'white'} />
          <span
            className={cx(
              'share__text',
              currentId >= stories.length - 4 && 'share__text--variant2'
            )}
          >
            {Locale.translate('share')}
            {isAuth && !isShared && (
              <div className={cx('share__tooltip')} style={{ opacity: show ? 1 : 0 }}>
                {Locale.translate('get_turn')}
              </div>
            )}
          </span>
        </button>
      )}
    </>
  );
};

export default Share;
