import Locale, { Language } from 'locale';
import { isNumber } from 'lodash';

export const formatCurrency = (x: number) => {
  return !x ? 0 : x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const formatNumber2Decimal = (data: string, language: string) =>
  Number.isInteger(+data)
    ? data
    : language === Language.vi
    ? (Math.round(+data * 100) / 100)
        .toFixed(3)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : (Math.round(+data * 100) / 100)
        .toFixed(3)
        .replace('.', ',')
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
