import HeaderImage from 'birthday/components/Header/Image';
import CountdownTimer from 'birthday/components/CountdownTimer';

const CountDown = () => {
  return (
    <>
      <HeaderImage />
      <CountdownTimer targetDate={'2023-06-30T00:00:00.000Z'} />
    </>
  );
};

export default CountDown;
