export const ShareIcon = ({
  size,
  width = '24',
  height = '24',
  className,
  onClick,
  color = 'white',
}: any) => (
  <svg
    onClick={onClick}
    className={className}
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM13.4859 16.6664V13.972C8.6279 13.3082 6.70266 15.3129 5.24961 16.8259C5.16497 16.914 5.08193 17.0005 5 17.0846C5.29502 9.50674 10.6788 8.2476 13.4859 8.56709V6.17355C13.4859 6.01919 13.6726 5.94193 13.7817 6.05116L18.8987 11.1752C19.0338 11.3104 19.0338 11.5295 18.8987 11.6648L13.7817 16.7888C13.6726 16.898 13.4859 16.8208 13.4859 16.6664Z"
      fill={color}
    />
  </svg>
);
