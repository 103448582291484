import React from 'react';
import { COIN_COLORS, EMPTY_COLOR } from 'look-back/constants';
import { DonutChartContext } from 'look-back/contexts/Chart';
import { ICoin, ICoinWithRender } from 'look-back/interfaces';
import { getColor } from 'look-back/util/chart';
import ArcPath from './ArcPath';

export type Colors = string[];

export type Props = {
  usdt: number;
  colorFunction?: (colors: Colors, index: number) => string;
  colors?: Colors;
  data: ICoin[];
  emptyColor?: string;
  formatValues?: (value: number, total: number) => string;
  height?: number;
  innerRadius?: number;
  outerRadius?: number;
  width?: number;
};

const DonutChart: React.FC<Props> = (props) => {
  const {
    usdt = 0,
    data = [
      {
        value: 100,
        isEmpty: true,
      },
    ],
    emptyColor = EMPTY_COLOR,
    height = 100,
    innerRadius = 0.7,
    outerRadius = 0.9,
    width = 100,
  } = props;
  const graphWidth = width;
  const total = data.reduce((sum, { value }) => sum + value, 0);
  const { dataWithRenderProps } = data.reduce(
    ({ angle, dataWithRenderProps }, item, index) => {
      const { isEmpty, value } = item;

      return {
        angle: angle + (value / total) * 360,
        dataWithRenderProps: [
          ...dataWithRenderProps,
          {
            angle,
            index,
            ...item,
            fill: isEmpty ? emptyColor : getColor(index),
            opacity: 1,
          },
        ],
        total: total + value,
      };
    },
    { angle: 0, dataWithRenderProps: [] as ICoinWithRender[] }
  );

  return (
    <DonutChartContext.Provider
      value={{
        graphWidth,
        innerRadius,
        outerRadius,
        total,
        width,
      }}
    >
      <svg style={{ height, width }} viewBox={`0 0 ${width} ${height}`}>
        <g>
          {dataWithRenderProps.map((item) => (
            <ArcPath item={item} key={`arcpath${item.index}`} />
          ))}
        </g>
        <g>
          <text x={graphWidth / 2} y="45%" textAnchor="middle" fill="white" fontSize="24px">
            {usdt}
          </text>
          <text
            x={graphWidth / 2}
            y="60%"
            textAnchor="middle"
            fill="white"
            fontSize="18px"
            fontWeight="300"
          >
            USDT
          </text>
        </g>
      </svg>
    </DonutChartContext.Provider>
  );
};

export default DonutChart;
