import { Col, Row, Spinner } from 'react-bootstrap';
import classNames from 'classnames/bind';
import { Event, useChristmasAdventContext } from 'merry-christmas/contexts/ChristmasAdventContext';
import Card from './Card/Card';
import styles from './Cards.module.scss';

const cx = classNames.bind(styles);

type Props = {
  currentWeek: number;
};

const Cards: React.FC<Props> = ({ currentWeek }) => {
  const { weeks, isLoading } = useChristmasAdventContext();

  return (
    <>
      {isLoading && (
        <div className={cx('loading')}>
          <Spinner animation="border" role="status" variant="light">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Row>
        {!!weeks &&
          weeks?.[currentWeek - 1]?.events.map((event: Event, index: number) => (
            <Col key={event.date} sm={4} lg={3} className="col-6">
              <Card event={event} index={index} />
            </Col>
          ))}
      </Row>
    </>
  );
};

export default Cards;
