import moment from 'moment';

function mathOfCanVote(matchs?: Matchs) {
  return matchs
    ?.filter?.((match: Match) => match?.amount)
    ?.sort(
      (a: Match, b: Match) => moment(a.startGuessing).valueOf() - moment(b.startGuessing).valueOf()
    );
}

export const MatchUtil = {
  matchesCanVote: (matchs?: Matchs) => {
    return mathOfCanVote(matchs);
  },
  matchesNotVote: (matchs?: Matchs) => {
    return matchs
      ?.filter((match: Match) => !match.amount)
      ?.sort(
        (a: Match, b: Match) =>
          moment(a.startGuessing).valueOf() - moment(b.startGuessing).valueOf()
      );
  },
  getMatchCommingSoon: (matchs?: Matchs) => {
    const matchVoting = mathOfCanVote(matchs)?.filter?.((match: Match) => {
      return (
        moment(match?.startGuessing).valueOf() <= Date.now() &&
        moment(match?.endGuessing).valueOf() >= Date.now()
      );
    });

    const matchReplace = mathOfCanVote(matchs)
      ?.filter?.((match: Match) => {
        return moment(match?.startGuessing).valueOf() >= Date.now();
      })
      ?.sort(
        (a: Match, b: Match) =>
          moment(a.startGuessing).valueOf() - moment(b.startGuessing).valueOf()
      )?.[0];

    return matchVoting?.reduce?.((prev: any, curr: any) => {
      return moment(prev?.endGuessing).valueOf() < moment(curr?.endGuessing).valueOf()
        ? prev
        : curr;
    }, matchVoting?.[0] || matchReplace);
  },
  filterVottingMatchs: (matches: Matches): Matches => {
    if (!matches) return {} as Matches;
    let result: any = {};
    for (const [key, matchs] of Object.entries(matches)) {
      result = { ...result, [key]: MatchUtil.getMatchVoted(mathOfCanVote(matchs)) };
    }
    return result;
  },
  getMatchRoundActive: (matches: Matches) => {
    if (!matches) return;
    const findMatchNotScoreInGroupstage = mathOfCanVote(matches?.groupstage)?.find(
      (match: Match) => {
        return !match?.score;
      }
    );
    if (findMatchNotScoreInGroupstage) {
      return 'groupstage';
    }
    const findMatchNotScoreInRound16 = mathOfCanVote(matches?.round16)?.find((match: Match) => {
      return !match?.score;
    });
    if (findMatchNotScoreInRound16) {
      return 'round16';
    }
    const findMatchNotScoreInQuarterfinal = mathOfCanVote(matches?.quarterfinal)?.find(
      (match: Match) => {
        return !match?.score;
      }
    );
    if (findMatchNotScoreInQuarterfinal) {
      return 'quarterfinal';
    }
    const findMatchNotScoreInSemifinal = mathOfCanVote(matches?.semifinal)?.find((match: Match) => {
      return !match?.score;
    });
    if (findMatchNotScoreInSemifinal) {
      return 'semifinal';
    }
    return 'final';
  },
  getMatchVoted: (matchs?: Matchs) => {
    console.log(matchs, 'matchs');
    return mathOfCanVote(matchs)?.filter((match: Match) => Boolean(match.guessing));
  },
};
