import { useGlobalContext } from 'contexts/GlobalContext';
import classNames from 'classnames/bind';
import BootstrapWrapper from 'base-components/BootstrapWrapper';
import GlobalStyle from 'base-components/GlobalStyle';
import styles from './Layout.module.scss';

const cx = classNames.bind(styles);

type Props = {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  const { isRemiApp, deviceInfo } = useGlobalContext();

  const { status_bar_height: statusBarHeight } = deviceInfo || {};
  return (
    <>
      {isRemiApp && (
        <div className={cx('navbar')} style={{ height: (statusBarHeight || 40) + 'px' }}></div>
      )}
      <BootstrapWrapper>
        <GlobalStyle>
          <div className={cx('wrap')} style={{ height: window ? window.innerHeight : '100vh' }}>
            {children}
          </div>
        </GlobalStyle>
      </BootstrapWrapper>
    </>
  );
};

export default Layout;
