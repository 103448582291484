import React from 'react';
import AuthProvider from 'contexts/AuthContext';
import LanguageProvider from 'contexts/LanguageContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import GlobalProvider from 'contexts/GlobalContext';

type Props = {
  children: React.ReactNode;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const AppProvider: React.FC<Props> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <AuthProvider>
          <LanguageProvider>{children}</LanguageProvider>
        </AuthProvider>
      </GlobalProvider>
    </QueryClientProvider>
  );
};

export default AppProvider;
