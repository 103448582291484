import React from 'react';
import classNames from 'classnames/bind';
import IconInfo from 'assets/birthday/images/icon-info.png';
import CelebrationIcon from 'assets/birthday/images/celebration-mobile.png';
import leftDecorationImg from 'assets/birthday/images/gift.png';
import rightDecorationImg from 'assets/birthday/images/celebration-2.png';
import styles from './AnniversaryContent.module.scss';
import useMediaQuery from 'hooks/useMediaQuery';
import Locale from 'locale';
import { useLanguageContext } from 'contexts/LanguageContext';

const cx = classNames.bind(styles);

const AnniversaryHeader: React.FC = () => {
  const isMd = useMediaQuery('(min-width: 768px)');
  return (
    <div className={cx('header')}>
      {!isMd && (
        <img className={cx('header--celebration--icon')} src={CelebrationIcon} alt="icon-cele" />
      )}
      <span className={cx('header--label')}>{Locale.translate('thank_you_title')}</span>
      <span className={cx('header--description')}>{Locale.translate('thank_you_text')}</span>
    </div>
  );
};

const AnniversaryMain: React.FC = () => {
  const titles = ['30.6.2014', '$3B', '7', '14', '67%', '5', '1815', '5966775', '4.7'];
  const DATA = titles.map((title, index) => ({
    title: title,
    text: Locale.translate(`highlight_${index + 1}`),
  }));
  const isMd = useMediaQuery('(min-width: 992px)');
  const calculateItemCol = (index: any) => {
    if (!isMd && index == 0) {
      return 'col-12';
    } else if (!isMd) {
      return 'col-6';
    } else {
      return 'col-4';
    }
  };

  return (
    <div className="container">
      <div className={cx('main', 'row')}>
        {DATA.map((item, index) => (
          <div key={index} className={cx(`${calculateItemCol(index)}`, 'main--item--wrapper')}>
            <div className={cx('main--item')}>
              <div className={cx('main--item--title')}>{item.title}</div>
              <div className={cx('main--item--text')}>{item.text}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Terms: React.FC = () => {
  const { language } = useLanguageContext();
  let url = 'https://docs.google.com/document/d/1Vl9AzFgIIIR6lxxVT6CFwRYT0LZZL2_IArBr_3I_b7A';
  if (language === 'vi') {
    url = 'https://docs.google.com/document/d/10i9aAUz0h8k5NDlbj1Rwr1Z1zFv26QgSEm3N8wKmXRw';
  }

  return (
    <a href={url} target="_blank">
      <div className={cx('terms')}>
        <div>{Locale.translate('birthday_terms_description')}</div>
        <button className={cx('terms--button')}>
          {Locale.translate('terms_and_conditions')}
          <img className={cx('icon')} src={IconInfo} alt="icon-logo" />
        </button>
      </div>
    </a>
  );
};

const AnniversaryContent: React.FC = () => (
  <div className={cx('wrapper')}>
    <div className={cx('wrapper--left--img')}>
      <img src={leftDecorationImg} />
    </div>
    <div className={cx('wrapper--right--img')}>
      <img src={rightDecorationImg} />
    </div>
    <div className={cx('anniversary')}>
      <Terms />
      <AnniversaryHeader />
    </div>
    <AnniversaryMain />
  </div>
);

export default AnniversaryContent;
