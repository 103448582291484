import axios from 'axios';
import Locale from 'locale';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { REMITANO_DOMAIN } from 'utils/RequestHelper';
import App from './App';
import reportWebVitals from './reportWebVitals';

declare let window: any;

axios.defaults.baseURL = REMITANO_DOMAIN;

window.onWebviewReady = (data: any) => {
  let deviceInfo: any = {};
  try {
    deviceInfo = JSON.parse(data);
    localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
    window.dispatchEvent(
      new CustomEvent('deviceInfo', {
        detail: deviceInfo,
      })
    );
  } catch (error) {
    console.log(error, 'error');
  }
  if (deviceInfo.language) {
    Locale.setLang(deviceInfo.language);
  }
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
