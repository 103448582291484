import { toast } from 'react-toastify';
import RequestHelper from 'utils/RequestHelper';

class LookbackService {
  getData = async (): Promise<any[] | undefined> => {
    try {
      const res = await RequestHelper.get('/api/v1/game_look_backs/info');
      if (res.data && res.code === 200) {
        return res?.data || [];
      }
    } catch (error: any) {
      return error;
    }
  };
  submitReward = async () => {
    try {
      const res = await RequestHelper.post('/api/v1/game_look_backs/submit_reward');
      if (res.code === 200) {
        return true;
      } else {
        if (res?.message) {
          toast.error(res?.message);
        }
        return false;
      }
    } catch (error: any) {
      toast.error('An error has occurred');
      return false;
    }
  };
}

export default new LookbackService();
