import React, { useEffect, useMemo } from 'react';
import { Link, NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Dropdown, Image } from 'react-bootstrap';
import { useMatchContext } from 'world-cup/contexts/MatchContext';
import { MatchUtil } from 'world-cup/ultils/MatchUtils';
import { useGlobalContext } from 'contexts/GlobalContext';
import RemitanoBrowser from 'utils/RemitanoBrowser';
import { useAuthContext } from 'contexts/AuthContext';
import classNames from 'classnames/bind';
import logo from 'assets/world-cup/logo-white.png';
import { DownArrowIcon, LanguageIcon } from 'base-components/Icons';
import { NavHead, navs } from './data';

import styles from './Header.module.scss';
import { useLanguageContext } from 'contexts/LanguageContext';
import Locale from 'locale';
import CommonHelper from 'utils/CommonHelper';

const cx = classNames.bind(styles);

export const mapKeyRouter: { [key: string]: string } = {
  round16: 'round-of-16-teams',
  quarterfinal: 'quater-finals',
  semifinal: 'semi-final',
  final: 'final-round',
};

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { language, onChangeLanguage } = useLanguageContext();
  const { isAuth } = useAuthContext();
  const { isRemiApp } = useGlobalContext();
  const [searchParams] = useSearchParams();

  const tab = searchParams.get('tab');
  const activeName = navs.find((nav: NavHead) => String(nav.tabName) === String(tab))?.name;

  const { matches } = useMatchContext();

  const keyActiveRound = useMemo(() => MatchUtil.getMatchRoundActive(matches), [matches]);
  useEffect(() => {
    if (keyActiveRound && keyActiveRound !== 'groupstage' && isAuth) {
      navigate(`/?tab=${mapKeyRouter[keyActiveRound as any]}&init=1`);
    }
  }, [keyActiveRound]);

  const handleRedirect = (to: string) => {
    navigate(to);
  };

  const handleClickLogo = (e: any) => {
    e.preventDefault();
    if (isRemiApp) {
      RemitanoBrowser.closeBrowser();
    } else {
      CommonHelper.redirectUrl(isRemiApp, '/', true);
    }
  };

  return (
    <div className={cx('header')}>
      <Link to={'/'} onClick={handleClickLogo}>
        <Image className={cx('logo')} src={logo} />
      </Link>
      <Dropdown className={cx('menu-mobile')}>
        <Dropdown.Toggle className={cx('toggle-button')} variant="transparent" id="dropdown-basic">
          {Locale.translate(String(activeName))} <DownArrowIcon size={20} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {navs.map((nav: NavHead) => (
            <Dropdown.Item
              onClick={() => handleRedirect(nav.to)}
              key={nav.id}
              className={
                String(tab) === String(nav.tabName)
                  ? cx('dropdown-menu-item', 'active')
                  : cx('dropdown-menu-item')
              }
            >
              {Locale.translate(nav.name)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <div className={cx('menu-desktop')}>
        <ul>
          {navs.map((nav: NavHead) => (
            <li key={nav.id}>
              <NavLink
                className={String(tab) === String(nav.tabName) ? cx('active') : cx('')}
                to={nav.to}
              >
                {Locale.translate(nav.name)}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <Dropdown className={cx('language')}>
        <Dropdown.Toggle className={cx('language-btn')} variant="transparent" id="language-basic">
          <span className={cx('language-btn-text')}>
            {language == 'vi' ? 'Tiếng Việt' : 'English'}
          </span>
          <LanguageIcon width={25} height={25} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            className={cx('dropdown-menu-item', language === 'en' && 'active')}
            onClick={() => onChangeLanguage?.('en')}
          >
            English
          </Dropdown.Item>
          <Dropdown.Item
            className={cx('dropdown-menu-item', language === 'vi' && 'active')}
            onClick={() => onChangeLanguage?.('vi')}
          >
            Tiếng Việt
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Header;
