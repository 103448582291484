export const noRewardContents = {
  1: [
    `Năm phát tài
    Tiền rủng rỉnh
    Coin đầy ví`,
    `Swap có đôi
    Lì xì có cặp
    Gặp được điều may
    Thành công mê say`,
    `Bây giờ mận mới hỏi đào: Năm nay không biết coin nào sẽ bay. 
    Mận hỏi thì Đào xin thưa: Coin nào cũng được gấp hơn năm sáu lần`,
  ],
  2: [
    `Remi cùng bạn đón tết
    Vạn sự mong đều có
    Lộc tài, phú quý sánh đôi`,
    `Xuân lộc phát
    Tiền đầy tay
    Coin đầy túi
    Happy new year`,
    `Xuân sang có hoa anh đào. Tiện đây tôi đoán coin nào cũng tăng!`,
  ],
  3: [
    `Quý Mão tân niên
    Thành công liên miên
    Tiền vào đầy túi`,
    `Xuân trao phú quý
    Tết chúc bình an
    Quý Mão phát tài`,
    `Quý Mão tới
    Vạn điều mới
    Triệu niềm vui
    Tiền đầy túi`,
  ],
  4: [
    `Remitano chúc bạn:
    Rộn ràng xuân sang
    Năm mới rủng rỉnh
    Rôm rả tiếng cười`,
    `Đa lộc - Đa tài - Đa phú quý
    Coin vào đầy ví, vui cả năm`,
    `Quý Mão hân hoan, phúc lộc ngập tràn
    Tiền vàng đầy túi, ví rủng rỉnh coin`,
  ],
  5: [
    `Quý Mão vạn sự thành công
    Trọn năm coin bay phấp phới`,
    `Remi xin chúc: 
    Cả năm sung túc
    Lộc phúc trọn năm
    Trăm sự như ý
    Ví luôn căng đầy`,
    `Quý Mão thảnh thơi
    Cả năm thuận lợi
    Vạn sự hanh thông`,
  ],
  6: [
    `Năm mới Remi chúc bạn:
    Giàu sang nhiều vàng
    Vạn sự hanh thông
    Coin đầy ngập ví`,
    `Đầu năm xin chúc:
    Ví rủng rỉnh coin
    Bền tâm, vững chí
    Lộc phát trọn niên`,
    `Qúy Mão giàu sang
    Hạnh phúc ngập tràn
    Vàng thì đầy túi
    Ví ngập tràn coin`,
  ],
};
