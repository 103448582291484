import React from 'react';
import { ToastContainer, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  children: React.ReactNode;
};

const ToastWrapper: React.FC<Props> = ({ children }) => {
  return (
    <>
      <ToastContainer hideProgressBar transition={Flip} /> {children}
    </>
  );
};

export default ToastWrapper;
