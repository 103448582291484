import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import { Image } from 'react-bootstrap';

import styles from './MyVoteItem.module.scss';
import WCButton from 'world-cup/components/WCButton';
import { FLAG_MAPPING_IMG } from 'world-cup/flag-mapping';
import matchDisabled from 'assets/world-cup/match-disabled.png';
import moment from 'moment';
import Locale from 'locale';

const cx = classNames.bind(styles);

type Props = Match & { giftAmount: string };

const MyVoteItem: React.FC<Props> = ({
  giftAmount = 0,
  endGuessing,
  guessing = '',
  score = '',
  team = '',
  winningTeam = '',
}) => {
  const teamOneName = team?.split('-')?.[0]?.toUpperCase();
  const teamTwoName = team?.split('-')?.[1]?.toUpperCase();
  const flagOne = FLAG_MAPPING_IMG[teamOneName as any];
  const flagTwo = FLAG_MAPPING_IMG[teamTwoName as any];

  const matchResult = useMemo(() => {
    if (!score) {
      return {
        status: Locale.translate('waiting'),
        className: 'waiting',
      };
    }
    if (guessing && winningTeam === 'draw') {
      return {
        status: Locale.translate('draw'),
        className: 'draw',
      };
    }
    if (guessing && guessing === winningTeam) {
      return {
        status: Locale.translate('win'),
        className: 'win',
      };
    }
    return {
      status: Locale.translate('lose'),
      className: 'lose',
    };
  }, [score, guessing, winningTeam]);

  return (
    <div className={cx('vote-item')}>
      <div className={cx('vote-item-team')}>
        <div className={cx('team', guessing?.toUpperCase() !== teamOneName && 'disabled')}>
          <span className={cx('vote-item-team-name')}>{teamOneName}</span>
          <Image className={cx('vote-item-team-flag')} src={flagOne || matchDisabled} />
        </div>
        <span className={cx('vote-item-team-score')}>
          {score || moment(endGuessing).format('DD/MM')}
        </span>
        <div className={cx('team', guessing?.toUpperCase() !== teamTwoName && 'disabled')}>
          <Image className={cx('vote-item-team-flag')} src={flagTwo || matchDisabled} />
          <span className={cx('vote-item-team-name')}>{teamTwoName}</span>
        </div>
      </div>
      <WCButton className={cx('gift-text')} primary>
        {Locale.translate('gift_name', { amount: giftAmount })}
      </WCButton>
      <div className={cx('vote-item-result', matchResult.className)}>
        <span>{matchResult.status}</span>
      </div>
    </div>
  );
};

export default React.memo(MyVoteItem);
