import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Locale from 'locale';
import { useSearchParams } from 'react-router-dom';

type LanguageContextType = {
  language: string;
  onChangeLanguage?: (lang: 'vi' | 'en') => void;
};

const LanguageContext = createContext<LanguageContextType>({
  language: 'vi',
});

type Props = {
  children: React.ReactNode;
};

const LanguageProvider: React.FC<Props> = ({ children }) => {
  const [language, setLanguage] = useState(Locale.currLocale || 'en');

  const [searchParams, setSearchParams] = useSearchParams();

  const handleChangeLanguage = useCallback((lang: 'en' | 'vi') => {
    const paramLang = searchParams.get('lang');
    if (paramLang) {
      searchParams.delete('lang');
      setSearchParams(searchParams);
    }
    localStorage.setItem('lang', lang);
    setLanguage(lang);
    window.location.reload();
  }, []);

  const valueContext: LanguageContextType = useMemo(
    () => ({
      language,
      onChangeLanguage: handleChangeLanguage,
    }),
    [language]
  );

  return <LanguageContext.Provider value={valueContext}>{children}</LanguageContext.Provider>;
};

export default LanguageProvider;

export const useLanguageContext = () => useContext<LanguageContextType>(LanguageContext);
