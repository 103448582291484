import React from 'react';
import 'world-cup/stylesheet/_base.scss';

type Props = {
  children: React.ReactNode;
};

const StyleSheetWrapper: React.FC<Props> = ({ children }) => {
  return <>{children}</>;
};

export default StyleSheetWrapper;
