import React from 'react';
import classNames from 'classnames/bind';
import Locale from 'locale';
import { IRenderer } from 'look-back/interfaces';
import remi2022Image from 'assets/look-back/images/remi2022.png';
import { getFeatureStory } from 'look-back/util/content';
import styles from './Story4.module.scss';

const cx = classNames.bind(styles);

const Story3: IRenderer = (props) => {
  return (
    <div
      id="story"
      className={cx('wrap')}
      style={{ maxHeight: window ? window.innerHeight : '100vh' }}
    >
      <div className={cx('story')}>
        <p className={cx('story__label')}>{Locale.translate('about_remitano_title1')}</p>
        <p className={cx('story__title')}>{Locale.translate('about_remitano_title2')}</p>
        <img src={remi2022Image} className={cx('story__image')} />
        <div className={cx('story__bottom')}>
          <p
            className={cx('story__description')}
            dangerouslySetInnerHTML={{
              __html: getFeatureStory(Locale.translate('about_remitano_product'), '11'),
            }}
          ></p>
          <p
            className={cx('story__description')}
            dangerouslySetInnerHTML={{
              __html: getFeatureStory(Locale.translate('about_remitano_coin'), '1.5'),
            }}
          ></p>
          <p
            className={cx('story__description')}
            dangerouslySetInnerHTML={{
              __html: getFeatureStory(Locale.translate('about_remitano_action'), '12'),
            }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default Story3;
