import React from 'react';
import classNames from 'classnames/bind';
import useCountdown from 'hooks/useCountdown';
import styles from './CountdownTimer.module.scss';
import Locale from 'locale';

const cx = classNames.bind(styles);

type DateTimeDisplayProp = {
  value: number;
  label: string;
};

const DateTimeDisplay: React.FC<DateTimeDisplayProp> = ({ value, label }) => (
  <div className={cx('datetime')}>
    <span className={cx('datetime--value')}>{value}</span>
    <span className={cx('datetime--label')}>{label}</span>
  </div>
);

const SeparateDot = () => (
  <div className={cx('separate')}>
    <div className={cx('separate--dot')}></div>
    <div className={cx('separate--dot')}></div>
  </div>
);

type CountdownTimerProps = {
  targetDate: string | number | Date;
};

const CountdownTimer: React.FC<CountdownTimerProps> = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  return (
    <>
      <div className={cx('countdown-timer')}>
        <DateTimeDisplay value={days} label={Locale.translate('days')} />
        <SeparateDot />
        <DateTimeDisplay value={hours} label={Locale.translate('hours')} />
        <SeparateDot />
        <DateTimeDisplay value={minutes} label={Locale.translate('minutes')} />
        <SeparateDot />
        <DateTimeDisplay value={seconds} label={Locale.translate('seconds')} />
      </div>
      <div className={cx('overall')}>🎂 More than 17,000 Prizes to be won 🎂</div>
    </>
  );
};

export default CountdownTimer;
