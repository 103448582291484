import React, { createRef, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useAuthContext } from 'contexts/AuthContext';
import { useLanguageContext } from 'contexts/LanguageContext';
import { ICoin, IRenderer } from 'look-back/interfaces';
import { formatNumber2Decimal } from 'look-back/util/common';
import sharkImage from 'assets/look-back/images/story1.png';
import DonutChart from 'look-back/components/DonutChart';
import { getColor } from 'look-back/util/chart';
import styles from './Story1.module.scss';
import Locale from 'locale';

const cx = classNames.bind(styles);

const Story1: IRenderer = (props) => {
  const { data } = props;
  const [coins, setCoins] = useState<ICoin[]>([]);
  const [totalUsdt, setTotalUsdt] = useState<number>(0);
  const { username } = useAuthContext();
  const { language } = useLanguageContext();

  const formatCoin = (data: any): ICoin => {
    return {
      label: data.coin,
      valueCoin: data.amount,
      isEmpty: false,
      value: parseInt(data.usd_amount),
    };
  };

  const getUsdtAmount = (data: any) =>
    data.reduce((sum: number, { usd_amount }: any) => sum + usd_amount, 0);

  useEffect(() => {
    const coinsSort = data?.sort((a: any, b: any) => +b.usd_amount - +a.usd_amount);
    const usdt = getUsdtAmount(data);
    const otherCoin = coinsSort?.slice(4);
    const coinsFormatted = coinsSort?.slice(0, 4)?.map((item: any) => formatCoin(item)) as ICoin[];
    const otherCoinFormatted = {
      label: 'other',
      isEmpty: true,
      valueCoin: '...',
      value: getUsdtAmount(otherCoin),
    } as ICoin;
    setCoins([...coinsFormatted, otherCoinFormatted]);
    setTotalUsdt(parseInt(usdt));
  }, [data]);

  return (
    <div
      id="story"
      className={cx('wrap')}
      style={{ maxHeight: window ? window.innerHeight : '100vh' }}
    >
      <div className={cx('story')}>
        <p className={cx('story__welcome')}>{Locale.translate('welcome_text')}</p>
        <p className={cx('story__username')}>{username || Locale.translate('friend')}</p>
        <img src={sharkImage} className={cx('story__image')} />
        <div className={cx('story__bottom')}>
          <p className={cx('story__description')}>{Locale.translate('story1_description')}</p>
          <div className={cx('story__chart')}>
            <DonutChart height={140} width={140} data={coins} usdt={totalUsdt} />
            <div className={cx('chart')}>
              {coins.map((item: any, index: number) => (
                <>
                  {!item?.isEmpty && (
                    <div className={cx('chart__row')} key={`story_${index}`}>
                      <div
                        style={{ background: getColor(index) }}
                        className={cx('chart__row__color')}
                      ></div>
                      <span className={cx('chart__row__label')}>{item.label}</span>
                      <span className={cx('chart__row__coin')}>
                        {formatNumber2Decimal(item.valueCoin, language)}
                      </span>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story1;
