import React, { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { MinusIcon, PlusIcon } from 'base-components/Icons';
import { Accordion, AccordionContext, Container, useAccordionButton } from 'react-bootstrap';
import WCButton from 'world-cup/components/WCButton';
import { useNavigate } from 'react-router-dom';
import { mapKeyRouter } from 'world-cup/components/Header/Header';

import MyVoteItem from './MyVoteItem';
import { MatchUtil } from 'world-cup/ultils/MatchUtils';
import { useMatchContext } from 'world-cup/contexts/MatchContext';
import Locale from 'locale';

import styles from './MyVote.module.scss';
const cx = classNames.bind(styles);

function ContextAwareToggle({ children, eventKey, callback }: any) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <div className={cx('card-head')} onClick={decoratedOnClick}>
      <span className={cx('card-head-title')}>{children}</span>
      <span className={cx('card-head-icon')}>
        {!isCurrentEventKey ? <PlusIcon size={25} /> : <MinusIcon size={25} />}
      </span>
    </div>
  );
}

const mappingKeyValue: { [key: string]: number } = {
  groupstage: 0,
  round16: 1,
  quarterfinal: 2,
  semifinal: 3,
  final: 4,
};

const MyVote: React.FC = () => {
  const { matches, isLoadingMatchs } = useMatchContext();
  const navigate = useNavigate();
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const votes = useMemo(() => {
    return MatchUtil.filterVottingMatchs(matches);
  }, [matches]);

  const isEmptyVote = useMemo(() => {
    return (
      !votes?.groupstage?.length &&
      !votes?.round16?.length &&
      !votes?.quarterfinal?.length &&
      !votes?.semifinal?.length &&
      !votes?.final?.length
    );
  }, [votes]);

  const keyActiveRound = useMemo(() => MatchUtil.getMatchRoundActive(matches), [matches]);
  const currentActive = mappingKeyValue?.[keyActiveRound as keyof Matches];

  useEffect(() => {
    setCurrentActiveTab(currentActive);
  }, [currentActive]);

  const onSelectAccordion = (value: any) => {
    setCurrentActiveTab(value);
  };

  const handleVoteNow = () => {
    if (keyActiveRound !== 'groupstage') {
      navigate(`/?.tab=${mapKeyRouter?.[keyActiveRound as any]}`);
    } else {
      navigate('/');
    }
  };

  if (isLoadingMatchs) return null;

  if (isEmptyVote) {
    return (
      <div className={cx('vote-wrong')}>
        <h5>{Locale.translate('empty_voting')}</h5>
        <WCButton onClick={handleVoteNow} outline className={cx('vote-wrong-btn')}>
          {Locale.translate('vote_now')}
        </WCButton>
      </div>
    );
  }

  return (
    <Container>
      <Accordion
        defaultActiveKey={'0'}
        activeKey={String(currentActiveTab)}
        onSelect={onSelectAccordion}
      >
        {votes?.groupstage?.length ? (
          <>
            <ContextAwareToggle eventKey="0">{Locale.translate('group_state')}</ContextAwareToggle>
            <Accordion.Collapse eventKey="0">
              <>
                {votes?.groupstage?.map((match: Match) => (
                  <MyVoteItem {...match} giftAmount={'6.666.666'} />
                ))}
              </>
            </Accordion.Collapse>
          </>
        ) : (
          <></>
        )}
        {votes?.round16?.length ? (
          <>
            <ContextAwareToggle eventKey="1">
              {Locale.translate('round_of_16')}{' '}
              {mappingKeyValue?.round16 > currentActive && (
                <>({Locale.translate('comming_soon')})</>
              )}
            </ContextAwareToggle>
            <Accordion.Collapse eventKey="1">
              <>
                {votes?.round16?.map((match: Match) => (
                  <MyVoteItem {...match} giftAmount={'8.888.888'} />
                ))}
              </>
            </Accordion.Collapse>
          </>
        ) : (
          <></>
        )}
        {votes?.quarterfinal?.length ? (
          <>
            <ContextAwareToggle eventKey="2">
              {Locale.translate('quarter_finals')}{' '}
              {mappingKeyValue?.quarterfinal > currentActive && (
                <>({Locale.translate('comming_soon')})</>
              )}
            </ContextAwareToggle>
            <Accordion.Collapse eventKey="2">
              <>
                {votes?.quarterfinal?.map((match: Match) => (
                  <MyVoteItem {...match} giftAmount={'9.999.999'} />
                ))}
              </>
            </Accordion.Collapse>
          </>
        ) : (
          <></>
        )}
        {votes?.semifinal?.length ? (
          <>
            <ContextAwareToggle eventKey="3">
              {Locale.translate('semi_final')}{' '}
              {mappingKeyValue?.semifinal > currentActive && (
                <>({Locale.translate('comming_soon')})</>
              )}
            </ContextAwareToggle>
            <Accordion.Collapse eventKey="3">
              <>
                {votes?.semifinal?.map((match: Match) => (
                  <MyVoteItem {...match} giftAmount={'9.999.999'} />
                ))}
              </>
            </Accordion.Collapse>
          </>
        ) : (
          <></>
        )}
        {votes?.final?.length || votes?.third?.length ? (
          <>
            <ContextAwareToggle eventKey="4">
              {Locale.translate('final_round')}{' '}
              {mappingKeyValue?.final > currentActive && <>({Locale.translate('comming_soon')})</>}
            </ContextAwareToggle>
            <Accordion.Collapse eventKey="4">
              <>
                {[...(votes?.final || []), ...(votes?.third || [])]?.map((match: Match) => (
                  <MyVoteItem
                    {...match}
                    giftAmount={match.key == 'final-match1' ? '17.000.000' : '11.111.111'}
                  />
                ))}
              </>
            </Accordion.Collapse>
          </>
        ) : (
          <></>
        )}
      </Accordion>
    </Container>
  );
};

export default MyVote;
