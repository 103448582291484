import React, { createContext, useContext, useEffect, useState } from 'react';
import { UNAUTHENCATION_EVENT, UNAUTHENCATION_EVENT_DATA } from 'constants/commons';
import useAuth from 'hooks/useAuth';
import { useGlobalContext } from './GlobalContext';

type AuthContextType = {
  isAuth: boolean;
  email: string;
  username: string;
  level: number;
};

const AuthContext = createContext<AuthContextType>({
  isAuth: false,
  email: '',
  username: '',
  level: 0,
});

type Props = {
  children: React.ReactNode;
};

const AuthProvider: React.FC<Props> = ({ children }) => {
  const { onSetRequiredLoginPopup } = useGlobalContext();
  const [userInfor, setUserInfor] = useState({ isAuth: false, email: '', username: '', level: 0 });
  const { userInfo } = useAuth({ initLoad: true });

  useEffect(() => {
    function event(evt: any) {
      if (evt?.detail === UNAUTHENCATION_EVENT_DATA) {
        setUserInfor({
          ...userInfor,
          isAuth: false,
        });
        onSetRequiredLoginPopup?.(true);
      }
    }
    window.addEventListener(UNAUTHENCATION_EVENT, event, false);
    return () => {
      window.removeEventListener(UNAUTHENCATION_EVENT, event, false);
    };
  }, []);

  useEffect(() => {
    if (userInfo) {
      setUserInfor({
        ...userInfor,
        isAuth: true,
        email: userInfo.email,
        username: userInfo.username,
        level: userInfo.level,
      });
    }
  }, [userInfo]);

  return <AuthContext.Provider value={userInfor}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuthContext = () => useContext(AuthContext);
