import axios from 'axios';
import { isArray, isObject } from 'lodash';
import Locale from 'locale';
import CustomError from './CustomError';
import RemitanoBrowser from './RemitanoBrowser';
import TimeHelper from './TimeHelper';
import { UNAUTHENCATION_EVENT, UNAUTHENCATION_EVENT_DATA } from 'constants/commons';

export const REMITANO_DOMAIN = process.env.REACT_APP_REMITANO_DOMAIN;

const unAuthenEvent: any = new CustomEvent(UNAUTHENCATION_EVENT, {
  detail: UNAUTHENCATION_EVENT_DATA,
});

export const getMobileInfoApiHeaders = () => ({
  'X-Mobile-Platform': 'web',
  'X-Mobile-identifier': null,
  'X-App-Type': 'betamano',
  'X-Device-Uuid': null,
  Os: 'web',
  Browser: 'chorme',
  'User-Agent': `native.remitano-web-app`,
});

const execptionHandle = async (error: any) => {
  if (error?.status === 401) {
    window.dispatchEvent(unAuthenEvent);
  }
};

const withErrorHandler =
  (generator: any) =>
  async (...args: any) => {
    try {
      return await generator(...args);
    } catch (error: any) {
      TimeHelper.runOnceIn(
        'API_EXCEPTION_HANDLER',
        1000
      )(() => {
        execptionHandle(error);
      });
      throw error;
    }
  };

const queryParams = (params: any = {}) => {
  const arrayParams: any = [];

  Object.keys(params).forEach((key) => {
    const value: any = params[key];

    if (isArray(value)) {
      value.forEach((subValue) => {
        const encodedKey = encodeURIComponent(`${key}[]`);
        const encodedValue = encodeURIComponent(subValue);

        arrayParams.push(`${encodedKey}=${encodedValue}`);
      });
    } else if (isObject(value)) {
      Object.keys(value).forEach((subKey: any) => {
        // @ts-ignore
        const subValue = value[subKey];
        const encodedKey = encodeURIComponent(`${key}[${subKey}]`);
        const encodedValue = encodeURIComponent(subValue);

        arrayParams.push(`${encodedKey}=${encodedValue}`);
      });
    } else {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value);

      arrayParams.push(`${encodedKey}=${encodedValue}`);
    }
  });

  return arrayParams.join('&');
};

export const generateHeader = (additionalHeaders = {}) => {
  const mobileHeaders = getMobileInfoApiHeaders();
  const Lang = Locale.currLocale;

  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // Coin: getCurrentWalletPageCoinInfo().coin || Coin,
    Lang,
    // Country,
    // Mode: window.REACT_RAILS_ENV.mode,
    // Wholesale: window.REACT_RAILS_ENV.wholesale,
    'Mobile-Model': 'mobileModel',
    ...mobileHeaders,
    ...additionalHeaders,
  };
};

export const request = async (path: string, options: any) => {
  const requestHeader = generateHeader(options.headers);
  options.headers = requestHeader;

  try {
    // @ts-ignore
    const requester = window.RemitanoBrowser ? RemitanoBrowser.axiosRequest : axios.request;
    const result = await requester({
      url: path,
      ...options,
      withCredentials: true,
    });
    // @ts-ignore
    if (window.RemitanoBrowser && result.error && result.isException) {
      throw result.error;
    }
    return result.data;
  } catch (error: any) {
    const { response } = error || {};
    if (error?.status === 500 || response?.status === 500) {
      reportError(error);
      throw new CustomError(Locale.translate('other_something_went_wrong'), 500);
    }
    if (!response) {
      throw new CustomError(Locale.translate('network_disconnected'), 503);
    }
    const message =
      response?.data?.error || response.statusText || Locale.translate('unknown_error_occurred');
    throw new CustomError(message, response); // v1
  }
};

const get = (path: string, params = null, headers = {}, opts = null) => {
  let path2 = path;
  if (params) {
    path2 += (path.indexOf('?') === -1 ? '?' : '&') + queryParams(params);
  }

  const options = {
    method: 'get',
    headers,
    ...(opts || {}),
  };

  return request(path2, options);
};

const post = (path: string, params = null, headers = {}) => {
  const options = {
    method: 'post',
    data: JSON.stringify(params),
    headers,
  };
  return request(path, options);
};

const upload = (path: string, params = null, headers = {}) => {
  const uploadHeaders = {
    ...headers,
  };
  // @ts-ignore
  delete uploadHeaders['Content-Type'];
  const options = {
    method: 'post',
    data: params,
    headers: uploadHeaders,
  };
  return request(path, options);
};

const putRequest = (path: string, params = null, headers = {}) => {
  const options = {
    method: 'put',
    data: JSON.stringify(params),
    headers,
  };
  return request(path, options);
};

const deleteRequest = (path: string, params = null, headers = {}) => {
  const options = {
    method: 'delete',
    data: JSON.stringify(params),
    headers,
  };

  return request(path, options);
};

const getWithErrorHandler = withErrorHandler(get);
const postWithErrorHandler = withErrorHandler(post);
const uploadWithErrorHandler = withErrorHandler(upload);
const putRequestWithErrorHandler = withErrorHandler(putRequest);
const deleteRequestWithErrorHandler = withErrorHandler(deleteRequest);

const RequestHelper = {
  withErrorHandler,
  get: getWithErrorHandler,
  post: postWithErrorHandler,
  upload: uploadWithErrorHandler,
  putRequest: putRequestWithErrorHandler,
  deleteRequest: deleteRequestWithErrorHandler,
  queryParams,
};

export default RequestHelper;
