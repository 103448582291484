import React, { useContext } from 'react';
import { IProgressContext } from '../interfaces';

const ProgressContext = React.createContext<IProgressContext>({
  currentId: 0,
  bufferAction: false,
  pause: false,
  next: () => {
    console.log('next');
  },
});

export const useProgressContext = () => useContext<IProgressContext>(ProgressContext);

export default ProgressContext;
