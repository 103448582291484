export const PreviousIcon = ({
  size,
  width = '24',
  height = '24',
  className,
  fill,
  onClick,
}: any) => (
  <svg
    onClick={onClick}
    className={className}
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    fill={fill ? fill : 'currentColor'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_21_2597)">
      <path
        d="M10.5 4.5L11.5725 5.54475L5.8875 11.25H21V12.75H5.8875L11.5725 18.4297L10.5 19.5L3 12L10.5 4.5Z"
        fill={fill ? fill : 'white'}
      />
    </g>
    <defs>
      <clipPath id="clip0_21_2597">
        <rect
          width="24"
          height="24"
          fill={fill ? fill : 'white'}
          transform="matrix(-1 0 0 1 24 0)"
        />
      </clipPath>
    </defs>
  </svg>
);
