import { useMutation } from '@tanstack/react-query';
import MatchService from 'world-cup/services/MatchService';

export type VoteData = { match_key: string; team_name?: string };

const voteMatch = async ({ match_key, team_name }: VoteData) => {
  return await MatchService.voteMatch(match_key, team_name);
};

export const useVoteMutation = ({ onSuccess }: { onSuccess?: (data: any) => void }) => {
  return useMutation({
    mutationFn: (voteData: VoteData) => voteMatch(voteData),
    onSuccess: (data) => {
      onSuccess?.(data);
    },
  });
};

export default useVoteMutation;
