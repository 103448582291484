export interface Gift {
  id: number;
  title: string;
  description?: string;
  image: string;
  messageEnTitle: string;
  messageEnDescription: string;
  messageViTitle: string;
  messageViDescription: string;
}

export const gifts: Gift[] = [
  {
    id: 0,
    title: 'Unknown',
    description: '',
    image: 'comeback',
    messageEnTitle: 'Unknown',
    messageEnDescription: 'Unknown',
    messageViTitle: 'Unknown',
    messageViDescription: 'Unknown',
  },
  {
    id: 1,
    title: 'Shiba Inu',
    description: '(SHIB)',
    image: 'shiba',
    messageEnTitle: 'Congratulations!🎉 You just received 666 SHIBA! ',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results. Want to win more? Come back tomorrow!',
    messageViTitle: 'Xin chúc mừng! 🎉 Bạn vừa nhận được 666 SHIBA!',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng. Muốn thắng nhiều hơn? Quay lại tiếp vào ngày mai nhé!',
  },
  {
    id: 2,
    title: 'Shiba Inu',
    description: '(SHIB)',
    image: 'shiba',
    messageEnTitle: 'Congratulations!🎉 You just received 888 SHIBA! ',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results. Want to win more? Come back tomorrow!',
    messageViTitle: 'Xin chúc mừng! 🎉 Bạn vừa nhận được 888 SHIBA!',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng. Muốn thắng nhiều hơn? Quay lại tiếp vào ngày mai nhé!',
  },
  {
    id: 3,
    title: 'Shiba Inu',
    description: '(SHIB)',
    image: 'shiba',
    messageEnTitle: 'Congratulations!🎉 You just received 2023 SHIBA! ',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results. Want to win more? Come back tomorrow!',
    messageViTitle: 'Xin chúc mừng! 🎉 Bạn vừa nhận được 2023 SHIBA!',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng. Muốn thắng nhiều hơn? Quay lại tiếp vào ngày mai nhé!',
  },
  {
    id: 4,
    title: 'Shiba Inu',
    description: '(SHIB)',
    image: 'shiba',
    messageEnTitle: 'Congratulations!🎉 You just received 999 SHIBA! ',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results. Want to win more? Come back tomorrow!',
    messageViTitle: 'Xin chúc mừng! 🎉 Bạn vừa nhận được 999 SHIBA!',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng. Muốn thắng nhiều hơn? Quay lại tiếp vào ngày mai nhé!',
  },
  {
    id: 5,
    title: 'Shiba Inu',
    description: '(SHIB)',
    image: 'shiba',
    messageEnTitle: 'Congratulations!🎉 You just received 6868 SHIBA! ',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results. Want to win more? Come back tomorrow!',
    messageViTitle: 'Xin chúc mừng! 🎉 Bạn vừa nhận được 6868 SHIBA!',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng. Muốn thắng nhiều hơn? Quay lại tiếp vào ngày mai nhé!',
  },
  {
    id: 6,
    title: 'Shiba Inu',
    description: '(SHIB)',
    image: 'shiba',
    messageEnTitle: 'Congratulations!🎉 You just received 686 SHIBA! ',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results. Want to win more? Come back tomorrow!',
    messageViTitle: 'Xin chúc mừng! 🎉 Bạn vừa nhận được 686 SHIBA!',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng. Muốn thắng nhiều hơn? Quay lại tiếp vào ngày mai nhé!',
  },
  {
    id: 7,
    title: 'Shiba Inu',
    description: '(SHIB)',
    image: 'shiba',
    messageEnTitle: 'Congratulations!🎉 You just received 666 SHIBA! ',
    messageEnDescription:
      'This will be credited directly to your Remitano Wallet account within seven days after confirming the prize pool results. Want to win more? Come back tomorrow!',
    messageViTitle: 'Xin chúc mừng! 🎉 Bạn vừa nhận được 666 SHIBA!',
    messageViDescription:
      'Phần thưởng này sẽ được chuyển trực tiếp vào tài khoản Ví Remitano của bạn trong vòng bảy ngày sau khi xác nhận kết quả giải thưởng. Muốn thắng nhiều hơn? Quay lại tiếp vào ngày mai nhé!',
  },
];
