import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import BringCoinHomeService from 'modules/bring-coin-home/services/BringCoinHomeService';

export interface Day {
  date: number;
  giftId: number;
  isCompleted: boolean;
  startDate: string;
  endDate: string;
  currentDate: string;
  hasGift: boolean;
  campaignUrlVi: string;
  actionCompletedIdVi: number;
  campaignUrlEn: string;
  actionCompletedIdEn: number;
}

type BringCoinHomeContextType = {
  isLoading: boolean;
  days: Day[];
  getBringCoinHomeAllDay?: VoidFunction;
};

const BringCoinHomeProviderContext = createContext<BringCoinHomeContextType>({
  isLoading: false,
  days: [],
});

type Props = {
  children: React.ReactNode;
};

const BringCoinHomeProvider: React.FC<Props> = ({ children }) => {
  const [days, setDays] = useState<Day[]>([]);
  const {
    isLoading,
    data,
    mutate: getBringCoinHomeAllDay,
  } = useMutation({
    mutationFn: async () => await BringCoinHomeService.getBringCoinHomeAllDay(),
  });

  useEffect(() => {
    getBringCoinHomeAllDay();
  }, []);

  useEffect(() => {
    if (data) {
      setDays(data);
    }
  }, [data]);

  const valueContext: BringCoinHomeContextType = {
    isLoading,
    days: days,
    getBringCoinHomeAllDay,
  };

  return (
    <BringCoinHomeProviderContext.Provider value={valueContext}>
      {children}
    </BringCoinHomeProviderContext.Provider>
  );
};

export default BringCoinHomeProvider;

export const useBringCoinHomeContext = () => useContext(BringCoinHomeProviderContext);
