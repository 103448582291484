import Locale, { Language } from 'locale';
import styles from './Result.module.scss';
import Modal from 'react-bootstrap/Modal';
import classNames from 'classnames/bind';
import './override.scss';
import { useLanguageContext } from 'contexts/LanguageContext';
import { CloseIcon } from 'merry-christmas/components/Icons';
import { Gift } from 'merry-christmas/components/Cards/gifts';
import comebackTomorrow from 'assets/merry-christmas/images/comeback.svg';

const cx = classNames.bind(styles);

type Props = {
  hasGift: boolean;
  show: boolean;
  onHide: () => void;
  gift: Gift;
};

const ModalResult: React.FC<Props> = (props) => {
  const { show, onHide, gift, hasGift } = props;
  const { language } = useLanguageContext();

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-result"
    >
      <Modal.Body>
        <div className={cx('content')}>
          <div className={cx('content__top')}></div>
          <div className={cx('content__bottom', !hasGift && 'content__bottom--no-reward')}>
            <CloseIcon className={cx('close-button')} onClick={onHide} />
            {hasGift ? (
              <>
                <p className={cx('content__title')}>
                  {language === Language.vi ? gift.messageViTitle : gift.messageEnTitle}
                </p>
                <img
                  className={cx('content__image')}
                  src={require(`assets/merry-christmas/images/${gift.image}.svg`)}
                  alt={gift.title}
                />
                <p className={cx('content__description')}>
                  {language === Language.vi ? gift.messageViDescription : gift.messageEnDescription}
                </p>
              </>
            ) : (
              <>
                <p className={cx('content__title')}>{Locale.translate('no_rewards_title')}</p>
                <img
                  className={cx('content__image')}
                  src={comebackTomorrow}
                  alt="comeback tomorrow"
                />
                <p className={cx('content__description')}>
                  {Locale.translate('no_rewards_description')}
                </p>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalResult;
