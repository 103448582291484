import { useCallback, useEffect, useMemo, useState } from 'react';
import Locale, { Language } from 'locale';
import { useLanguageContext } from 'contexts/LanguageContext';
import classNames from 'classnames/bind';
import { getDate } from 'merry-christmas/utils/date';
import ModalGame from 'merry-christmas/components/Modal/Game/Game';
import ModalResult from 'merry-christmas/components/Modal/Result/Result';
import { Event } from 'merry-christmas/contexts/ChristmasAdventContext';
import styles from './Card.module.scss';
import { Gift, gifts } from '../gifts';

const cx = classNames.bind(styles);

type Props = {
  event: Event;
  index: number;
};

const Card: React.FC<Props> = ({ event }) => {
  const { language } = useLanguageContext();
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [resultShow, setResultShow] = useState<boolean>(false);
  const [currenDate, setCurrentDate] = useState<Date>(new Date(getDate(event.currentDate)));

  const hiddenResult = () => {
    setResultShow(false);
  };

  const showResult = () => {
    setResultShow(true);
  };

  useEffect(() => {
    setCurrentDate(getDate(event.currentDate));
  }, [event]);

  const isExpiry: boolean = useMemo(() => {
    return currenDate > getDate(event.endDate) && !event.isCompleted;
  }, [currenDate]);
  const canOpen: boolean = useMemo(
    () => !isExpiry && currenDate > getDate(event.startDate),
    [currenDate, isExpiry]
  );

  const countDate = useMemo(() => {
    const differenceInTime = currenDate.getTime() - getDate(event.startDate).getTime();
    return 6 - Math.floor(differenceInTime / (1000 * 3600 * 24));
  }, [currenDate, event.startDate]);

  const imageBackground = useMemo(() => {
    return isExpiry
      ? require(`assets/merry-christmas/images/gift${event.date}.png`)
      : !canOpen
      ? require(`assets/merry-christmas/images/card${event.date}.png`)
      : event.isCompleted
      ? require(`assets/merry-christmas/images/completed${event.date}.png`)
      : require(`assets/merry-christmas/images/gift${event.date}.png`);
  }, [isExpiry, canOpen, event.isCompleted]);

  const handleClickCard = () => {
    if (event.isCompleted) {
      setResultShow(true);
    } else {
      setModalShow(true);
    }
  };

  const getDay = useMemo(() => {
    const getOrder = () => {
      switch (event.date) {
        case 1:
        case 21:
          return 'st';
        case 2:
        case 22:
          return 'nd';
        case 3:
        case 23:
          return 'rd';
        default:
          return 'th';
      }
    };
    return language === Language.vi ? `${event.date}/12` : `Dec ${event.date}${getOrder()}`;
  }, [language, event]);

  const getGift = (): Gift => gifts.find((gift: Gift) => gift.id === event?.giftId) ?? gifts[0];

  return (
    <>
      <ModalGame
        show={modalShow}
        onHide={() => setModalShow(false)}
        event={event}
        showResult={showResult}
      />
      <ModalResult
        hasGift={event.hasGift}
        show={resultShow}
        onHide={hiddenResult}
        gift={getGift()}
      />
      {canOpen ? (
        <div
          onClick={handleClickCard}
          className={cx('m-card')}
          style={{
            backgroundImage: `url('${imageBackground}')`,
          }}
        >
          {countDate === 6 || event.isCompleted ? (
            <p className={cx('m-card__date')}>{getDay}</p>
          ) : (
            <>
              <p className={cx('m-card__date', 'm-card__date--open')}>{getDay}</p>
              <p className={cx('m-card__expiry')}>{`${Locale.translate(
                'days_left_first'
              )} ${countDate} ${Locale.translate('days_left')}`}</p>
            </>
          )}
          {event.isCompleted ? (
            <>
              {event.hasGift ? (
                <p className={cx('m-card__description')}>{Locale.translate('view_rewards')}</p>
              ) : (
                <p className={cx('m-card__description')}>{Locale.translate('miss_round')}</p>
              )}
            </>
          ) : (
            !isExpiry && (
              <p className={cx('m-card__description')}>
                {gifts.find((gift: Gift) => gift.id === event?.giftId)?.['title']}
              </p>
            )
          )}
        </div>
      ) : (
        <div
          className={cx('m-card', isExpiry && 'm-card--expired')}
          style={{
            backgroundImage: `url('${imageBackground}')`,
          }}
        >
          {!isExpiry ? (
            <>
              <p className={cx('m-card__date')}>{getDay}</p>
              <div className={cx('m-card__wrap')}>
                <p className={cx('m-card__wrap__date-open')}>
                  {Locale.translate('open_in')} {countDate - 6} {Locale.translate('days')}
                </p>
              </div>
            </>
          ) : (
            <>
              <p className={cx('m-card__date', 'm-card__date--open')}>{getDay}</p>
              <p className={cx('m-card__expiry', 'm-card__expiry--expired')}>
                {Locale.translate('expired')}
              </p>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Card;
