export const URL_GAME_HUB = 'https://remitano.com/home/vn/gamehub';
export const URL_HOMEPAGE = 'https://remitano.com';

export const GIFT: { [key: number]: string } = {
  0: 'Unknow',
  1: '0.01 USDT',
  2: '0.04 ADA',
  3: '0.000006 ETH',
  4: '1,515 SHIB',
  5: '0.0003994 BTC',
};
