import React, { useState, useRef, useEffect, MouseEvent, TouchEvent } from 'react';
import classNames from 'classnames/bind';
import RemitanoBrowser from 'utils/RemitanoBrowser';
import { useGlobalContext } from 'look-back/contexts/Global';
import { useGlobalContext as useGlobalRemiContext } from 'contexts/GlobalContext';
import { useStoriesContext } from 'look-back/contexts/Stories';
import { useAuthContext } from 'contexts/AuthContext';
import ProgressList from 'look-back/components/ProgressList/ProgressList';
import ModalShare from 'look-back/components/ModalShare/ModalShare';
import ProgressContext from 'look-back/contexts/Progress';
import Story from 'look-back/components/Story/Story';
import { ACTION, URL_HOMEPAGE, URL_LOGIN_PAGE } from 'look-back/constants';
import Share from 'look-back/components/Share/Share';
import Button from 'look-back/components/Button/Button';
import styles from './Container.module.scss';

const cx = classNames.bind(styles);

export default function () {
  const { username } = useAuthContext();
  const [show, setShow] = useState(false);
  const [currentId, setCurrentId] = useState<number>(0);
  const [pause, setPause] = useState<boolean>(true);
  const [bufferAction, setBufferAction] = useState<boolean>(true);

  const handleOpenShare = () => {
    setPause(true);
    setShow(true);
  };

  const handleHiddenShare = () => {
    setShow(false);
    toggleState(ACTION.Play);
  };

  const mousedownId = useRef<any>();

  const { loop, currentIndex, isPaused } = useGlobalContext();
  const { isRemiApp } = useGlobalRemiContext();
  const { stories } = useStoriesContext();

  useEffect(() => {
    if (typeof currentIndex === 'number') {
      if (currentIndex >= 0 && currentIndex < stories.length) {
        setCurrentIdWrapper(() => currentIndex);
      } else {
        console.error(
          'Index out of bounds. Current index was set to value more than the length of stories array.',
          currentIndex
        );
      }
    }
  }, [currentIndex]);

  useEffect(() => {
    if (typeof isPaused === 'boolean') {
      setPause(isPaused);
    }
  }, [isPaused]);

  const handleClickCloseOrNext = (e: any) => {
    e.preventDefault();
    if (currentId === stories.length - 1) {
      if (isRemiApp) {
        RemitanoBrowser.closeBrowser();
      } else if (!username) {
        window.open(`${URL_LOGIN_PAGE}`);
      } else {
        window.open(`${URL_HOMEPAGE}`);
      }
    } else {
      next();
    }
  };

  const toggleState = (action: string, bufferAction?: boolean) => {
    setPause(action === ACTION.Pause);
    setBufferAction(!!bufferAction);
  };

  const setCurrentIdWrapper = (callback: any) => {
    setCurrentId(callback);
    toggleState(ACTION.Pause, true);
  };

  const previous = () => {
    setCurrentIdWrapper((prev: any) => (prev > 0 ? prev - 1 : prev));
  };

  const next = () => {
    if (loop) {
      updateNextStoryIdForLoop();
    } else {
      updateNextStoryId();
    }
  };

  const updateNextStoryIdForLoop = () => {
    setCurrentIdWrapper((prev: any) => (prev + 1) % stories.length);
  };

  const updateNextStoryId = () => {
    setCurrentIdWrapper((prev: any) => {
      if (prev < stories.length - 1) return prev + 1;
      return prev;
    });
  };

  const debouncePause = (e: MouseEvent | TouchEvent) => {
    e.preventDefault();
    mousedownId.current = setTimeout(() => {
      toggleState(ACTION.Pause);
    }, 200);
  };

  const mouseUp = (type: string) => (e: MouseEvent | TouchEvent) => {
    e.preventDefault();
    mousedownId.current && clearTimeout(mousedownId.current);
    if (currentId === stories.length - 1 && type === ACTION.Previous) {
      previous();
    } else if (pause) {
      toggleState(ACTION.Play);
    } else {
      type === ACTION.Next ? next() : previous();
    }
  };

  return (
    <div className={cx('container')} style={{ maxHeight: window ? window.innerHeight : '100vh' }}>
      <ProgressContext.Provider
        value={{
          bufferAction,
          currentId,
          pause,
          next,
        }}
      >
        <ProgressList />
      </ProgressContext.Provider>
      <Story
        action={toggleState}
        bufferAction={bufferAction}
        playState={pause}
        story={stories[currentId]}
      />
      <div className={cx('overlay')}>
        <div
          className={cx('prev')}
          onTouchStart={debouncePause}
          onTouchEnd={mouseUp(ACTION.Previous)}
          onMouseDown={debouncePause}
          onMouseUp={mouseUp(ACTION.Previous)}
        />
        <div
          className={cx('next')}
          onTouchStart={debouncePause}
          onTouchEnd={mouseUp(ACTION.Next)}
          onMouseDown={debouncePause}
          onMouseUp={mouseUp(ACTION.Next)}
        />
      </div>
      <ModalShare show={show} handleHiddenShare={handleHiddenShare} />
      <Share currentId={currentId} handleOpenShare={handleOpenShare} />
      <Button currentId={currentId} onClick={handleClickCloseOrNext} />
    </div>
  );
}
