import React from 'react';
import classNames from 'classnames/bind';
import styles from './Footer.module.scss';

const cx = classNames.bind(styles);

const Footer: React.FC = () => {
  return (
    <div className={cx('footer')}>
      <span>
        © 2022{' '}
        <a href="https://remitano.com" target="_blank" className={cx('remitano-homepage')}>
          Remitano
        </a>
        . All rights reserved.
      </span>
    </div>
  );
};

export default Footer;
