import { useRef } from 'react';
import classNames from 'classnames/bind';
import Locale from 'locale';
import { FacebookIcon, TwitterIcon } from 'react-share';
import useMediaQuery from 'hooks/useMediaQuery';
import decorationImg from 'assets/birthday/images/celebrate-2-2.png';
import styles from './ExploreRemitanoNiceTruths.module.scss';

const cx = classNames.bind(styles);
const facebookUrl = 'https://www.facebook.com/remitano';
const twitterUrl = 'https://twitter.com/remitano';

const ExploreRemitanoNiceTruths = () => {
  const isLg = useMediaQuery('(min-width: 992px)');
  const ref = useRef<any>(null);

  return (
    <div ref={ref} className={cx('explore-more-section')}>
      <h5 className={cx('explore-more-section--heading')}>
        {isLg
          ? Locale.translate('explore_more_with_remitano_9_truths')
          : Locale.translate('explore_more_with_remitano_9_truths_short')}
      </h5>
      <div className={cx('explore-more-section--share-icons')}>
        <a href={facebookUrl} target="_blank">
          <FacebookIcon round />
        </a>
        <a href={twitterUrl} target="_blank">
          <TwitterIcon round />
        </a>
      </div>
      <div className={cx('explore-more-section--celebrate-img')}>
        <img src={decorationImg} />
      </div>
    </div>
  );
};

export default ExploreRemitanoNiceTruths;
