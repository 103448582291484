import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import BirthdayService from '../services/BirthdayService';

export interface Event {
  date: number;
  giftId: number;
  isCompleted: boolean;
  startDate: string;
  endDate: string;
  currentDate: string;
  hasGift: boolean;
  campaignUrlVi: string;
  actionCompletedIdVi: number;
  campaignUrlEn: string;
  actionCompletedIdEn: number;
}

type BirthdayContextType = {
  isLoading: boolean;
  events: Event[];
  getBirthdayAllDay?: VoidFunction;
};

const BirthdayProviderContext = createContext<BirthdayContextType>({
  isLoading: false,
  events: [],
});

type Props = {
  children: React.ReactNode;
};

const BirthdayProvider: React.FC<Props> = ({ children }) => {
  const [events, setEvents] = useState<Event[]>([]);
  const {
    isLoading,
    data,
    mutate: getBirthdayAllDay,
  } = useMutation({
    mutationFn: async () => await BirthdayService.getBirthdayAllDay(),
  });

  useEffect(() => {
    getBirthdayAllDay();
  }, []);

  useEffect(() => {
    if (data) {
      setEvents(data as Event[]);
    }
  }, [data]);

  const valueContext: BirthdayContextType = {
    events,
    isLoading,
    getBirthdayAllDay,
  };
  return (
    <BirthdayProviderContext.Provider value={valueContext}>
      {children}
    </BirthdayProviderContext.Provider>
  );
};

export default BirthdayProvider;

export const useBirthdayContext = () => useContext(BirthdayProviderContext);
