import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMatchsMutation } from 'world-cup/hooks/useMatchsMutation';

type MathContextType = {
  matches: Matches;
  isLoadingMatchs?: boolean;
  getMatchs?: VoidFunction;
};

const MatchContext = createContext<MathContextType>({
  matches: {
    final: [],
    groupstage: [],
    quarterfinal: [],
    round16: [],
    semifinal: [],
    third: [],
  },
});

type Props = {
  children: React.ReactNode;
};

const MatchProvider: React.FC<Props> = ({ children }) => {
  const { mutate: getMatchs, data: matchesData, isLoading: isLoadingMatchs } = useMatchsMutation();

  const [matchs, setMatchs] = useState<Matches>();

  useEffect(() => {
    if (matchesData) {
      setMatchs(matchesData as Matches);
    }
  }, [matchesData]);

  useEffect(() => {
    getMatchs();
  }, []);

  const valueContext: MathContextType = {
    matches: matchs as Matches,
    isLoadingMatchs,
    getMatchs,
  };
  return <MatchContext.Provider value={valueContext}>{children}</MatchContext.Provider>;
};

export default MatchProvider;

export const useMatchContext = () => useContext(MatchContext);
