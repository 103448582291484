import Locale from 'locale';
import classNames from 'classnames/bind';
import styles from './Description.module.scss';

const cx = classNames.bind(styles);

const Description = () => {
  return (
    <div className={cx('description')}>
      <p className={cx('description__second')}>{Locale.translate('intro_1')}</p>
      <p className={cx('description__second')}>{Locale.translate('intro_2')}</p>
    </div>
  );
};

export default Description;
