import classNames from 'classnames/bind';
import Locale from 'locale';
import footerImg from 'assets/birthday/images/footer.png';
import appstoreImg from 'assets/birthday/images/appstore.png';
import chPlayImg from 'assets/birthday/images/ch-play.png';
import footerConfettiImg from 'assets/birthday/images/footer-confetti.png';
import styles from './Footer.module.scss';

const cx = classNames.bind(styles);
const chplayUrl = 'https://play.google.com/store/apps/details?id=com.remitano.remitano';
const appstoreUrl = 'https://apps.apple.com/ng/app/remitano/id1116327021';

const Footer = () => (
  <div className={cx('footer')}>
    <div className={cx('footer--right')}>
      <div className={cx('footer--img-wrapper')}>
        <img src={footerConfettiImg} className={cx('footer--confetti-img')} />
        <img src={footerImg} className={cx('footer--img')} />
      </div>
    </div>

    <div className={cx('footer--left')}>
      <h5>{Locale.translate('get_the_best_experience_witha_reliable_9_year_old_exchange')}</h5>
      <p>{Locale.translate('we_are_available_on')}</p>
      <div className={cx('footer--download')}>
        <a href={appstoreUrl} target="_blank">
          <img src={appstoreImg} alt="download remitano app for ios" />
        </a>
        <a href={chplayUrl} target="_blank">
          <img src={chPlayImg} alt="download remitano app for android" />
        </a>
      </div>
      <p>{Locale.translate('or_visit')}</p>
      <a className={cx('footer--visit-remitano-link')} href="https://remitano.com" target="_blank">
        <h6>www.remitano.com</h6>
      </a>
    </div>
  </div>
);

export default Footer;
