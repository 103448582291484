export const ShareFlatIcon = ({
  size,
  width = '28',
  height = '24',
  className,
  onClick,
  color = 'white',
}: any) => (
  <svg
    onClick={onClick}
    className={className}
    width={size || width}
    height={size || height}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.48589 7.97203V10.6664C8.48589 10.8208 8.67257 10.898 8.78165 10.7888L13.8987 5.66476C14.0338 5.52952 14.0338 5.31045 13.8987 5.1752L8.78165 0.051161C8.67257 -0.058066 8.48589 0.0191862 8.48589 0.173551V2.56709C5.67881 2.2476 0.295018 3.50674 0 11.0846C0.081926 11.0005 0.164968 10.914 0.249612 10.8259C1.70266 9.31287 3.6279 7.3082 8.48589 7.97203Z"
      fill="white"
    />
  </svg>
);
