import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { find, toNumber } from 'lodash';
import useQuery from 'hooks/useQuery';
import { Event, useBirthdayContext } from 'birthday/contexts/BirthdayContext';
import { useMutation } from '@tanstack/react-query';
import BirthdayService from 'birthday/services/BirthdayService';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useAuthContext } from 'contexts/AuthContext';
import { sendEventLog } from 'utils/EventLogger';
import MissionItem from './components/MissionItem/MissionItem';
import MissionItemSpecial from './components/MissionItemSpecial/MissionItemSpecial';
import MissionModal, { ModalConfirmRef } from './components/MissionModal/MissionModal';
import { Language } from 'locale';
import classNames from 'classnames/bind';
import styles from './MissionBoard.module.scss';

const cx = classNames.bind(styles);

const MissionBoard = () => {
  const modalRef = React.useRef<ModalConfirmRef>(null);
  const [currentEvent, setCurrentEvent] = React.useState<Event | undefined>();
  const { isAuth } = useAuthContext();
  const { language } = useLanguageContext();
  const { events, isLoading, getBirthdayAllDay } = useBirthdayContext();
  const query = useQuery();

  const dayNumber = toNumber(query.get('day'));
  const hasCheck = !!query.get('check');
  const [submitted, setSubmitted] = useState(false);

  const { data: isSuccess, mutate: submitReward } = useMutation({
    mutationFn: async (date: number) => await BirthdayService.submitReward(date, language),
  });

  const handleShowModal = (event: Event) => {
    const url =
      language === Language.vi
        ? `https://gleam.io/${event.campaignUrlVi}`
        : `https://gleam.io/${event.campaignUrlEn}`;
    window.open(url);
    sendEventLog(`open_birthday_mission`, { day: `day${event.date}` });
  };

  useEffect(() => {
    if (hasCheck && !!dayNumber) {
      setCurrentEvent(find(events, { date: dayNumber }));
    }
  }, [events]);

  useEffect(() => {
    const isValidCallback = hasCheck && !!dayNumber && currentEvent?.date == dayNumber;
    if (isAuth && isValidCallback && !submitted) {
      setSubmitted(true);
      if (currentEvent && !currentEvent.isCompleted && currentEvent.hasGift) {
        submitReward(currentEvent.date);
      }
    }
  }, [currentEvent, dayNumber, hasCheck, isAuth, events]);

  useEffect(() => {
    if (isSuccess) {
      modalRef?.current?.show(currentEvent?.giftId || 0, currentEvent?.hasGift || false);
      if (getBirthdayAllDay) getBirthdayAllDay();
    }
  }, [isSuccess]);

  return (
    <div className={cx('container-box')}>
      {isLoading && (
        <div>
          <Spinner animation="border" role="status" variant="light">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}

      <div className="row">
        {!!events?.length &&
          events.map((event: Event) =>
            event.date === 9 ? (
              <MissionItemSpecial event={event} key={event.date} />
            ) : (
              <MissionItem key={event.date} event={event} onPress={handleShowModal} />
            )
          )}
      </div>

      <MissionModal ref={modalRef} onClose={getBirthdayAllDay} />
    </div>
  );
};

export default MissionBoard;
