import { isString, replace } from 'lodash';
import localeEn from './en';
import localeVi from './vi';

const getUserLocalesInternal = () => {
  let languageList: any[] = [];

  if (typeof window !== 'undefined') {
    const { navigator } = window;
    languageList = languageList.concat(navigator.languages, navigator.language);
  }
  if (languageList.some((i) => ['vi', 'vi-VN'].includes(i))) return 'vi';
  return undefined;
};

const getLangParam = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const langPraram = urlParams.get('lang');
  if (['vi', 'en'].includes(String(langPraram))) {
    localStorage.setItem('lang', String(langPraram));
    return langPraram;
  }
  return null;
};

class LocaleService {
  currLocale: 'en' | 'vi' = 'en';
  words: any = {};
  constructor() {
    const langPraram = getLangParam();
    const _locale: any =
      langPraram ||
      localStorage.getItem('lang') ||
      getUserLocalesInternal() ||
      this.currLocale ||
      'en';
    this.currLocale = _locale;
    this.words = this.getLang(_locale);
  }

  getLang = (locale: 'en' | 'vi') => {
    switch (locale) {
      case 'vi':
        return localeVi;
      default:
        return localeEn;
    }
  };

  setLang = (locale: string) => {
    const _locale = locale === 'vi' ? 'vi' : 'en';
    this.currLocale = _locale;
    this.words = this.getLang(_locale);
  };

  translate = (key: string, params?: any) => {
    if (!key) return '';
    let str: string = this.words[key] || key;
    if (!params) return str;
    const paramsKeys = Object.keys(params);
    if (paramsKeys.length > 0) {
      paramsKeys.map((key) => {
        if (isString(str)) {
          str = replace(str, new RegExp(`{${key}}`, 'g'), params[key]);
        }
      });
      return String(str);
    }
    return String(str);
  };
}

const Locale = new LocaleService();
export default Locale;

export enum Language {
  vi = 'vi',
  en = 'en',
}
