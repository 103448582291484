import classNames from 'classnames/bind';
import { useGlobalContext } from 'contexts/GlobalContext';
import React from 'react';
import Activities from 'world-cup/components/Activities';
import Banner from 'world-cup/components/Banner';
import Footer from 'world-cup/components/Footer';
import Header from 'world-cup/components/Header';

import style from './Layout.module.scss';

const cx = classNames.bind(style);

type Props = {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  const { isRemiApp, deviceInfo } = useGlobalContext();

  const { status_bar_height: statusBarHeight } = deviceInfo || {};
  return (
    <>
      {isRemiApp && (
        <div className={cx('navbar')} style={{ height: (statusBarHeight || 40) + 'px' }}></div>
      )}
      <Header />
      <Banner />
      {children}
      <Activities />
      <Footer />
    </>
  );
};

export default Layout;
