import { useGlobalContext } from 'contexts/GlobalContext';
import { Container } from 'react-bootstrap';
import classNames from 'classnames/bind';
import BootstrapWrapper from 'base-components/BootstrapWrapper';
import BringCoinHomeProvider from 'bring-coin-home/contexts/BringCoinHomeContext';
import GlobalStyle from 'base-components/GlobalStyle';
import Footer from 'bring-coin-home/components/Footer/Footer';
import Header from 'bring-coin-home/components/Header/Header';
import Logo from 'bring-coin-home/components/Logo/Logo';
import styles from './Layout.module.scss';

const cx = classNames.bind(styles);

type Props = {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  const { isRemiApp, deviceInfo } = useGlobalContext();

  const { status_bar_height: statusBarHeight } = deviceInfo || {};
  return (
    <>
      {isRemiApp && (
        <div className={cx('navbar')} style={{ height: (statusBarHeight || 40) + 'px' }}></div>
      )}
      <BootstrapWrapper>
        <GlobalStyle>
          <BringCoinHomeProvider>
            <div className={cx('background')}>
              <div className={cx('wrap')}>
                <Header />
                <Container>
                  <Logo />
                  {children}
                  <Footer />
                </Container>
              </div>
            </div>
          </BringCoinHomeProvider>
        </GlobalStyle>
      </BootstrapWrapper>
    </>
  );
};

export default Layout;
