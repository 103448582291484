import React, { useMemo } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  GAME_MERRY_CHRISMAS,
  GAME_WORLD_CUP,
  GAME_LOOK_BACK,
  GAME_BRING_COIN_HOME,
  GAME_BIRTHDAY,
} from 'constants/commons';
import AppProvider from 'AppProvider';
import RequiedLoginPopup from 'base-components/RequiedLoginPopup';

export const getTrackingId = (): string => {
  switch (process.env.REACT_APP_GAME) {
    case GAME_WORLD_CUP:
      return process.env.REACT_APP_GA_WORLDCUP as string;
    case GAME_MERRY_CHRISMAS:
      return process.env.REACT_APP_GA_CHRISTMAST as string;
    case GAME_LOOK_BACK:
      return process.env.REACT_APP_GA_LOOKBACK as string;
    case GAME_BRING_COIN_HOME:
      return process.env.REACT_APP_GA_BRINGCOINHOME as string;
    case GAME_BIRTHDAY:
      return process.env.REACT_APP_GA_BIRTHDAY as string;
    default:
      return '';
  }
};

if (getTrackingId()) ReactGA.initialize(getTrackingId());

const App = () => {
  const pickGame = (gameType: string | undefined) => {
    switch (gameType) {
      case GAME_WORLD_CUP:
        return require('./modules/world-cup').default;
      case GAME_MERRY_CHRISMAS:
        return require('./modules/merry-christmas').default;
      case GAME_LOOK_BACK:
        return require('./modules/look-back').default;
      case GAME_BRING_COIN_HOME:
        return require('./modules/bring-coin-home').default;
      case GAME_BIRTHDAY:
        return require('./modules/birthday').default;
      default:
        throw new Error("Can't find game");
    }
  };

  const MainApp = useMemo(() => {
    return pickGame(process.env.REACT_APP_GAME);
  }, []);

  const needLogin = useMemo(() => process.env.REACT_APP_GAME !== GAME_LOOK_BACK, []);

  return (
    <Router>
      <AppProvider>
        <MainApp />
        {needLogin && <RequiedLoginPopup />}
      </AppProvider>
    </Router>
  );
};

export default App;
