import Layout from '../../components/Layout/Layout';
import { AnniversaryScreen, CountDownScreen, MissionBoardScreen } from '../';
import RequiedKyc from '../../components/RequiedKyc';

const Home = () => {
  return (
    <Layout>
      <RequiedKyc />
      <CountDownScreen />
      <MissionBoardScreen />
      <AnniversaryScreen />
    </Layout>
  );
};

export default Home;
