import React, { useContext } from 'react';
import { IGlobalContext } from '../interfaces';

export const initialContext = {};

const GlobalContext = React.createContext<IGlobalContext>(initialContext);

export const useGlobalContext = () => useContext<IGlobalContext>(GlobalContext);

export default GlobalContext;
