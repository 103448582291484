import { toast } from 'react-toastify';
import RequestHelper from 'utils/RequestHelper';

class MatchService {
  getMatchs = async (): Promise<any[] | undefined> => {
    try {
      const res = await RequestHelper.post('/api/v1/game_world_cups/all_matches');
      if (res.data && res.code === 200) {
        return res?.data?.matches || [];
      }
    } catch (error: any) {
      return error;
    }
  };
  voteMatch = async (match_key: string, team_name?: string) => {
    try {
      const res = await RequestHelper.post('/api/v1/game_world_cups/guess_a_match', {
        match_key,
        team_name,
      });
      if (res.ok && res.code === 200) {
        return { result: 'success' };
      } else {
        return toast.error(res?.message);
      }
    } catch (error: any) {
      return error;
    }
  };
}

export default new MatchService();
