import classNames from 'classnames/bind';
import ButtonBack from './ButtonBack/ButtonBack';
import Logo from './Logo';
import styles from './Header.module.scss';
import ChangeLanguege from './ChangeLanguage/ChangeLanguage';

const cx = classNames.bind(styles);

const Header = () => {
  return (
    <div className={cx('wrap')}>
      <ButtonBack />
      <Logo />
      <ChangeLanguege />
    </div>
  );
};

export default Header;
