import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames/bind';
import { Col, Container, Row, Image } from 'react-bootstrap';
import logoWorldCup from 'assets/world-cup/logo-word-cup.png';
import { RightArrowIcon } from 'base-components/Icons';
import Match from '../Match';

import styles from './Banner.module.scss';
import WCButton from '../WCButton';
import { useMatchContext } from 'world-cup/contexts/MatchContext';
import { MatchUtil } from 'world-cup/ultils/MatchUtils';
import useVoteMutation from 'world-cup/hooks/useVoteMutation';
import Locale from 'locale';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalContext } from 'contexts/GlobalContext';
import CommonHelper from 'utils/CommonHelper';

const cx = classNames.bind(styles);

const Banner: React.FC = () => {
  const { matches, getMatchs, isLoadingMatchs } = useMatchContext();
  const { language } = useLanguageContext();
  const { isRemiApp } = useGlobalContext();

  const keyActiveRound = useMemo(() => MatchUtil.getMatchRoundActive(matches), [matches]);

  const match = useMemo(() => {
    const third_match = matches?.third || [];
    return MatchUtil.getMatchCommingSoon([
      ...(matches?.[keyActiveRound as keyof Matches] || []),
      ...third_match,
    ]) as Match;
  }, [matches]);

  const onSuccess = (data: any) => {
    getMatchs?.();
  };

  const { mutate: onVoteMatch } = useVoteMutation({ onSuccess });

  const handleVote = useCallback((match_key: string, team_name?: string) => {
    onVoteMatch({ match_key, team_name });
  }, []);

  const handleReadMore = () => {
    if (language === 'en') {
      CommonHelper.redirectUrl(
        isRemiApp,
        '/forum/au/121517-lets-join-aim-for-the-victory?utm_source=wgame&utm_medium=221122_br&utm_campaign=aimforthevictory_wgame_221122_br'
      );
    } else {
      CommonHelper.redirectUrl(
        isRemiApp,
        '/forum/au/121418-the-thao-tung-bung-cung-aim-to-the-victory?utm_source=wgame&utm_medium=221122_br&utm_campaign=aimforthevictory_wgame_221122_br'
      );
    }
  };

  return (
    <div className={cx('banner')}>
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <div className={cx('banner-left')}>
              <Image className={cx('banner-left-logo')} src={logoWorldCup} />
              <span className={cx('banner-left-title')}>
                {Locale.translate('banner_title_one')}
              </span>
              <span className={cx('banner-left-title')}>
                {Locale.translate('banner_title_two')}
              </span>
              <span>{Locale.translate('banner_title_third')}</span>
              <WCButton
                onClick={handleReadMore}
                className={cx('banner-left-btn')}
                large
                primary
                rounded
                rightIcon={<RightArrowIcon size="1.8rem" />}
              >
                {Locale.translate('read_more')}
              </WCButton>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className={cx('banner-right')}>
              <Match
                isLoading={isLoadingMatchs}
                onVote={handleVote}
                keyMatch={match?.key}
                variant="main"
                {...match}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Banner;
