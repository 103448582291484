import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import Slider from 'react-slick';
import ActivitiesItem from '../ActivitiesItem';
import { LeftPrevIcon, RightNextIcon } from 'base-components/Icons';
import { activitiesData } from 'world-cup/components/Activities/data';
import styles from './ActivitesDesktop.module.scss';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const cx = classNames.bind(styles);

const ActivitesDesktop: React.FC = () => {
  let refSlider: any = useRef();

  const [slideIndex, setSlideIndex] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    afterChange: () => setUpdateCount(updateCount + 1),
    beforeChange: (current: number, next: number) => setSlideIndex(next),
    customPaging: (i: number) => (
      <span className={cx('navigate', i === slideIndex && 'navigate-active')}></span>
    ),
  };

  return (
    <div className={cx('slider-wrapper', 'activities-desktop')}>
      {activitiesData.length > 2 && (
        <>
          <span
            className={cx('btn-slick', 'btn-slick-prev')}
            onClick={() => {
              refSlider.slickGoTo(slideIndex - 1);
            }}
          >
            <LeftPrevIcon size={25} />
          </span>
          <span
            className={cx('btn-slick', 'btn-slick-next')}
            onClick={() => {
              refSlider.slickGoTo(slideIndex + 1);
            }}
          >
            <RightNextIcon size={25} />
          </span>
        </>
      )}
      <Slider {...settings} ref={(slider) => (refSlider = slider)}>
        {activitiesData.map((activities, index: number) => (
          <ActivitiesItem key={index} {...activities} />
        ))}
      </Slider>
    </div>
  );
};

export default ActivitesDesktop;
