import usePopup from 'hooks/usePopup';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

type GlobalContextType = {
  isRemiApp: boolean;
  deviceInfo?: any;

  isRequiredLoginPopup?: boolean;
  onSetRequiredLoginPopup?: (status: boolean) => void;
};

const GlobalContext = createContext<GlobalContextType>({
  isRemiApp: false,
});

type Props = {
  children: React.ReactNode;
};

const GlobalProvider: React.FC<Props> = ({ children }) => {
  const [deviceInfo, setDeviceInfo] = useState(null);
  const { isRequiredLoginPopup, onSetRequiredLoginPopup } = usePopup();

  useEffect(() => {
    function event(data: any) {
      if (data?.detail) {
        setDeviceInfo(data?.detail);
      }
    }
    window.addEventListener('deviceInfo', event, false);
    try {
      setDeviceInfo(JSON.parse(localStorage.getItem('deviceInfo') as any));
    } catch {
      console.log('Json invalid');
    }

    return () => {
      window.removeEventListener('deviceInfo', event, false);
    };
  }, []);

  const valueContext = useMemo(
    () => ({
      isRemiApp: Boolean(deviceInfo),
      deviceInfo,
      isRequiredLoginPopup,
      onSetRequiredLoginPopup,
    }),
    [deviceInfo, deviceInfo, isRequiredLoginPopup]
  );

  return <GlobalContext.Provider value={valueContext}>{children}</GlobalContext.Provider>;
};

export default GlobalProvider;

export const useGlobalContext = () => useContext(GlobalContext);
