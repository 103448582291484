import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames/bind';
import { Language } from 'locale';
import { useLanguageContext } from 'contexts/LanguageContext';
import { CloseIcon } from 'merry-christmas/components/Icons';
import ChristmasAdventService from 'merry-christmas/services/ChristmasAdventService';
import { Event, useChristmasAdventContext } from 'merry-christmas/contexts/ChristmasAdventContext';
import { useAuthContext } from 'contexts/AuthContext';
import styles from './Game.module.scss';
import './override.scss';
import { useLocation } from 'react-router-dom';
import useQuery from 'hooks/useQuery';

const cx = classNames.bind(styles);

type Props = {
  show: boolean;
  onHide: () => void;
  event: Event;
  showResult: () => void;
};

const ModalGame: React.FC<Props> = (props) => {
  const query = useQuery();
  const { show, onHide, showResult } = props;
  const { event } = props;
  const { language } = useLanguageContext();
  const { getChristmasAdventAllDay } = useChristmasAdventContext();
  const { email, username } = useAuthContext();

  const { data: isSuccess, mutate: submitReward } = useMutation({
    mutationFn: async (date: number) => await ChristmasAdventService.submitReward(date, language),
  });

  useEffect(() => {
    const check = query.get('check');
    const day = query.get('day');
    if (!!check && !!day && +day === event.date) {
      submitReward(+day);
    }
  }, []);

  useEffect(() => {
    if (show) {
      const win = window as any;
      win.Gleam = win.Gleam || [];

      win.onGleamEvent = async (gleam: any) => {
        if (
          gleam?.action?.id &&
          (gleam?.action?.id === event.actionCompletedIdEn ||
            gleam?.action?.id === event.actionCompletedIdVi)
        ) {
          submitReward(event.date);
        }
      };
    }
  }, [show]);

  useEffect(() => {
    if (isSuccess) {
      onHide();
      showResult();
      if (getChristmasAdventAllDay) getChristmasAdventAllDay();
    }
  }, [isSuccess]);

  useEffect(() => {
    function embedCompetition(el: any, html: any) {
      el.innerHTML = html;

      Array.from(el.querySelectorAll('script')).forEach((oldScript: any) => {
        const newScript = document.createElement('script');
        Array.from(oldScript.attributes).forEach((attr: any) =>
          newScript.setAttribute(attr.name, attr.value)
        );
        newScript.appendChild(document.createTextNode(oldScript.innerHTML));
        oldScript.parentNode.replaceChild(newScript, oldScript);
      });
    }

    const embedCode = `<a class="e-widget no-button generic-loader" href="https://gleam.io/${
      language === Language.vi ? event.campaignUrlVi : event.campaignUrlEn
    }" rel="nofollow"></a><script type="text/javascript" src="https://widget.gleamjs.io/e.js" defer="true" async="true"></script>
    
    `;
    if (show) embedCompetition(document.querySelector('#gleam_competition'), embedCode);
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-game"
    >
      <CloseIcon className={cx('close-button')} onClick={onHide} />
      <iframe
        style={{ display: 'none' }}
        src={`https://gleam.io/${
          language === Language.vi ? event.campaignUrlVi : event.campaignUrlEn
        }?name=${username}&email=${email}`}
      ></iframe>
      <div id="gleam_competition"></div>
    </Modal>
  );
};

export default ModalGame;
