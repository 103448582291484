import React from 'react';
import classNames from 'classnames/bind';
import Locale from 'locale';
import { IRenderer } from 'look-back/interfaces';
import styles from './Video.module.scss';

const cx = classNames.bind(styles);

const Video: IRenderer = (props) => {
  return (
    <div
      id="story"
      className={cx('wrap')}
      style={{ maxHeight: window ? window.innerHeight : '100vh' }}
    >
      <div className={cx('story')}>
        <p className={cx('story__label')}>{Locale.translate('look_back')}</p>
        <p className={cx('story__title')}>{Locale.translate('look_back_description')}</p>
        <iframe
          className={cx('story__iframe')}
          width="100%"
          height="290"
          src="https://www.youtube.com/embed/dZ36EM-BVUw?autoplay=1&mute=1"
        ></iframe>
        <div className={cx('story__bottom')}></div>
      </div>
    </div>
  );
};

export default Video;
