import React from 'react';
import classNames from 'classnames/bind';
import { useAuthContext } from 'contexts/AuthContext';
import { IRenderer } from 'look-back/interfaces';
import { formatCurrency } from 'look-back/util/common';
import sharkImage from 'assets/look-back/images/story2.png';
import styles from './Story2.module.scss';
import Locale from 'locale';

const cx = classNames.bind(styles);

const Story2: IRenderer = (props) => {
  const { username } = useAuthContext();

  const { data } = props;
  return (
    <div
      id="story"
      className={cx('wrap')}
      style={{ maxHeight: window ? window.innerHeight : '100vh' }}
    >
      <div className={cx('story')}>
        <p className={cx('story__welcome')}>{Locale.translate('welcome_text')}</p>
        <p className={cx('story__username')}>{username || Locale.translate('friend')}</p>
        <img src={sharkImage} className={cx('story__image')} />
        <div className={cx('story__bottom')}>
          <p className={cx('story__description')}>{Locale.translate('story2_desceiption')}</p>
          <div className={cx('story__detail')}>
            <p>
              <span className={cx('story__detail__label')}>{Locale.translate('deposit')}</span>
              {formatCurrency(data?.deposit)} {data?.currency}
            </p>
            <p>
              <span className={cx('story__detail__label')}>{Locale.translate('withdraw')}</span>
              {formatCurrency(data?.withdrawal)} {data?.currency}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story2;
