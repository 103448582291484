import React from 'react';
import { Container } from 'react-bootstrap';
import classNames from 'classnames/bind';
import useMediaQuery from 'hooks/useMediaQuery';
import Locale from 'locale';

import ActivitiesDesktop from './components/ActivitiesDesktop';
import ActivitiesMobile from './components/ActivitiesMobile';

import styles from './Activities.module.scss';
const cx = classNames.bind(styles);

const Activities: React.FC = () => {
  const matches = useMediaQuery('(min-width: 994px)');
  return (
    <Container>
      <div className={cx('activities')}>
        <h1 className={cx('activities-title')}>AIM FOR THE VICTORY</h1>
        <p className={cx('activities-description')}>{Locale.translate('activities_sub_title')}</p>
      </div>
      {matches ? <ActivitiesDesktop /> : <ActivitiesMobile />}
    </Container>
  );
};

export default Activities;
