import React from 'react';
import Locale, { Language } from 'locale';
import classNames from 'classnames/bind';
import { useAuthContext } from 'contexts/AuthContext';
import startImage from 'assets/look-back/images/start.png';
import flagVi from 'assets/look-back/images/flag-vi.png';
import flagEn from 'assets/look-back/images/flag-en.png';
import styles from './Start.module.scss';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { URL_LOGIN_PAGE } from 'modules/look-back/constants';
import { useLanguageContext } from 'contexts/LanguageContext';

const cx = classNames.bind(styles);

type Props = {
  setIsStart: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
};

const Start: React.FC<Props> = (props) => {
  const { setIsStart, isLoading } = props;
  const { username } = useAuthContext();
  const { language, onChangeLanguage } = useLanguageContext();

  const handleClickExplore = () => {
    if (isLoading) return;
    setIsStart(true);
  };

  const handleClickLogin = () => {
    window.open(`${URL_LOGIN_PAGE}`);
  };

  const handleChangeLanguage = () => {
    language === Language.vi ? onChangeLanguage?.(Language.en) : onChangeLanguage?.(Language.vi);
  };

  return (
    <div className={cx('wrap')} style={{ maxHeight: window ? window.innerHeight : '100vh' }}>
      <button className={cx('button-language')} onClick={handleChangeLanguage}>
        <img
          src={language == 'vi' ? flagVi : flagEn}
          alt="flag"
          className={cx('button-language__image')}
        />
        {language === Language.vi ? 'Việt Nam' : 'English'}
      </button>
      <div className={cx('start')}>
        <img src={startImage} className={cx('start__image')} />
        <button onClick={handleClickExplore} className={cx('start__button')}>
          {isLoading ? (
            <Spinner animation="border" role="status" variant="secondary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            Locale.translate('button_discover')
          )}
        </button>
        {!username && (
          <Link to={'/'} onClick={handleClickLogin}>
            <button className={cx('button-login')}>{Locale.translate('login')}</button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Start;
