export const DownloadIcon = ({
  size,
  width = '24',
  height = '24',
  className,
  onClick,
  color = 'black',
}: any) => (
  <svg
    className={className}
    focusable="false"
    aria-hidden="true"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    data-testid="DownloadIcon"
  >
    <path d="M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z"></path>
  </svg>
);
