import Modal from 'react-bootstrap/Modal';
import Locale from 'locale';
import classNames from 'classnames/bind';
import { CloseIcon } from 'bring-coin-home/components/Icons';
import styles from './TermAndCondition.module.scss';

const cx = classNames.bind(styles);

type Props = {
  show: boolean;
  onHide: () => void;
};

const ModalTermAndCondition: React.FC<Props> = (props) => {
  const { show, onHide } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-game"
    >
      <CloseIcon className={cx('close-button')} onClick={onHide} />
      <div className={cx('content')}>
        <h3 className={cx('title')}>Giới thiệu chương trình Hái Lộc</h3>
        <p>
          Chào mừng bạn đến với Bring Coin Home 2023 và cùng Remitano "hái lộc" cho những ngày đầu
          năm mới!
        </p>
        <p>
          Từ 20/01 (29/12 AL) đến 25/01/2023 (04/01 AL), mỗi ngày người dùng có thể truy cập Bring
          Coin Home qua GameHub/Website/Đường dẫn trực tiếp và mở cánh cửa ngày hôm đó bằng cách
          thực hiện nhiệm vụ đơn giản từ chương trình.
        </p>
        <p>
          Mỗi ngày, người dùng chỉ có thể mở cửa 01 lần để nhận phần quà may mắn từ Remitano dựa
          theo nguyên tắc ngẫu nhiên.
        </p>
        <h3 className={cx('title')}>QUY TẮC CHƯƠNG TRÌNH</h3>
        <p>
          Remitano chuẩn bị 6 bao lì xì dành cho mỗi người dùng tương ứng với mỗi ngày trong suốt
          thời gian diễn ra chương trình.
        </p>
        <p>Người dùng truy cập vào trang chương trình để mở bao lì xì may mắn của mình.</p>
        <p>
          Mỗi người dùng chỉ được mở 01 lần mỗi ngày và KHÔNG thể mở bao cho những ngày trước đó.
        </p>
        <p>
          Hái lộc là chương trình dựa vào yếu tố may mắn. Mỗi người dùng đều có cơ hội như nhau để
          nhận những phần quà bất ngờ từ Remitano.
        </p>
        <p>
          Nếu bạn nhận được quà tặng hiện kim, quà tặng sẽ được chuyển thẳng trực tiếp vào ví
          Remitano của bạn ngay lập tức.
        </p>
        <h3 className={cx('title')}>ĐIỀU KHOẢN VÀ ĐIỀU KIỆN</h3>
        <p>Chương trình dành cho tất cả người dùng Remitano.</p>
        <p>
          Remitano có quyền từ chối tham gia đối với các trường hờp gian lận, không tuân thủ bất kỳ
          điều khoản và điều kiện nào của chương trình, và/ hoặc có dấu hiệu tạo tài khoản hàng loạt
          trái phép.
        </p>
        <p>
          Remitano có toàn quyền quyết định điều chỉnh hoặc thay đổi điều khoản chương trình, luật
          chơi, thời gian tham gia, ... dựa vào điều kiện thực tế và quyết định của Ban Tổ Chức.
        </p>
      </div>
    </Modal>
  );
};

export default ModalTermAndCondition;
