import React, { useEffect } from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import useGA from 'hooks/useGA';
import ToastWrapper from 'base-components/ToastWrapper';
import Home from 'look-back/pages/Home';

const MainApp: React.FC = () => {
  useGA();

  return (
    <ToastWrapper>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </ToastWrapper>
  );
};

export default MainApp;
