import RequestHelper from 'utils/RequestHelper';

class UserService {
  getCurrentUser = async () => {
    try {
      const res = await RequestHelper.get('/api/v1/users/me');
      return res;
    } catch (error: any) {
      // AlertHelper.showError(error);
    }
  };
}

export default new UserService();
