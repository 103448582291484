import { Col, Row, Spinner } from 'react-bootstrap';
import classNames from 'classnames/bind';
import { Day, useBringCoinHomeContext } from 'bring-coin-home/contexts/BringCoinHomeContext';
import Card from './Card/Card';
import styles from './Cards.module.scss';

const cx = classNames.bind(styles);

const Cards: React.FC = () => {
  const { days, isLoading } = useBringCoinHomeContext();
  return (
    <>
      {isLoading && (
        <div className={cx('loading')}>
          <Spinner animation="border" role="status" variant="light">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Row>
        {days?.length &&
          days.map((day: Day, index: number) => (
            <Col key={day.date} sm={4} lg={4} className="col-6">
              <Card day={day} index={index} />
            </Col>
          ))}
      </Row>
    </>
  );
};

export default Cards;
