import { useState } from 'react';
import Locale from 'locale';
import classNames from 'classnames/bind';
import { InforIcon } from 'bring-coin-home/components/Icons';
import ModalTermAndCondition from 'bring-coin-home/components/Modal/TermAndCondition/TermAndCondition';
import styles from './Footer.module.scss';

const cx = classNames.bind(styles);

const Footer = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <ModalTermAndCondition show={modalShow} onHide={() => setModalShow(false)} />
      <div className={cx('footer')}>
        <div className={cx('footer__description')}>
          <p className={cx('footer__description--first')}>{Locale.translate('outro_1')}</p>
        </div>
        <button className={cx('footer__button')} onClick={() => setModalShow(true)}>
          {Locale.translate('terms_and_conditions_title')}{' '}
          <InforIcon className={cx('footer__button__icon')} />
        </button>
      </div>
    </>
  );
};

export default Footer;
