import React from 'react';
import { activitiesData } from 'world-cup/components/Activities/data';
import ActivitiesItem from '../ActivitiesItem';

const ActivitiMobile: React.FC = () => {
  return (
    <>
      {activitiesData.map((activities, index: number) => (
        <ActivitiesItem key={index} {...activities} />
      ))}
    </>
  );
};

export default ActivitiMobile;
