import React, { useContext } from 'react';
import { IStory, IStoriesContext } from 'look-back/interfaces';

export const initialContext: { stories: IStory[] } = {
  stories: [],
};

const StoriesContext = React.createContext<IStoriesContext>(initialContext);

export const useStoriesContext = () => useContext<IStoriesContext>(StoriesContext);

export default StoriesContext;
