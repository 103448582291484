import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import useMediaQuery from 'hooks/useMediaQuery';
import { useGlobalContext } from 'contexts/GlobalContext';
import Locale from 'locale';
import RemitanoBrowser from 'utils/RemitanoBrowser';
import { PreviousIcon } from 'merry-christmas/components/Icons';
import { URL_GAME_HUB } from 'merry-christmas/constants/common';
import styles from './ButtonBack.module.scss';

const cx = classNames.bind(styles);

const ButtonBack = () => {
  const isXs = useMediaQuery('(max-width: 576px)');
  const { isRemiApp } = useGlobalContext();

  const handleClickBack = (e: any) => {
    e.preventDefault();
    if (isRemiApp) {
      RemitanoBrowser.closeBrowser();
    } else {
      window.open(`${URL_GAME_HUB}`);
    }
  };

  return (
    <Link to={'/'} onClick={handleClickBack}>
      <button className={cx('button', isXs && 'button--small')}>
        <PreviousIcon size={isXs ? 12 : 24} className={cx('button__icon')} />
        {isXs ? Locale.translate('button_back_small') : Locale.translate('button_back')}
      </button>
    </Link>
  );
};

export default ButtonBack;
