import React from 'react';
import classNames from 'classnames/bind';
import Locale, { Language } from 'locale';
import { IRenderer } from 'look-back/interfaces';
import remiChatImage from 'assets/look-back/images/remiChat.svg';
import remiChatEnImage from 'assets/look-back/images/remiChatEn.svg';
import styles from './Story5.module.scss';
import { useLanguageContext } from 'contexts/LanguageContext';

const cx = classNames.bind(styles);

const Story5: IRenderer = (props) => {
  const { language } = useLanguageContext();

  return (
    <div
      id="story"
      className={cx('wrap')}
      style={{ maxHeight: window ? window.innerHeight : '100vh' }}
    >
      <div className={cx('story')}>
        <p className={cx('story__label')}>Remitano</p>
        <p className={cx('story__title')}>{Locale.translate('strive')}</p>
        <img
          src={language === Language.vi ? remiChatImage : remiChatEnImage}
          className={cx('story__image')}
        />
        <div className={cx('story__bottom')}>
          <p className={cx('story__description')}>{Locale.translate('feedback_quote')}</p>
        </div>
      </div>
    </div>
  );
};

export default Story5;
