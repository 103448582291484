import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import useMediaQuery from 'hooks/useMediaQuery';
import { useGlobalContext } from 'contexts/GlobalContext';
import RemitanoBrowser from 'utils/RemitanoBrowser';
import LogoImage from 'assets/bring-coin-home/images/logo.png';
import LogoSmallImage from 'assets/bring-coin-home/images/logo-small.png';
import { URL_HOMEPAGE } from 'bring-coin-home/constants/common';
import styles from './Logo.module.scss';

const cx = classNames.bind(styles);

const Logo: React.FC = () => {
  const isXs = useMediaQuery('(max-width: 576px)');
  const { isRemiApp } = useGlobalContext();

  const handleClickLogo = (e: any) => {
    if (isRemiApp) {
      e.preventDefault();
      RemitanoBrowser.closeBrowser();
    } else {
      window.open(`${URL_HOMEPAGE}`, '_blank');
    }
  };

  return (
    <Link to={'/'} onClick={handleClickLogo}>
      <div className={cx('logo')}>
        <img className={cx('logo-img')} src={isXs ? LogoSmallImage : LogoImage} alt="logo image" />
      </div>
    </Link>
  );
};

export default Logo;
