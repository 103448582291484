import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Match from '../Match';
import classNames from 'classnames/bind';
import styles from './Rounds.module.scss';
import { MatchVariant } from '../Match/Match';
import useVoteMutation from 'world-cup/hooks/useVoteMutation';
import { useMatchContext } from 'world-cup/contexts/MatchContext';

const cx = classNames.bind(styles);

type Props = {
  matchs?: Matchs;
  variant?: MatchVariant;
};

const Rounds: React.FC<Props> = ({ matchs = [], variant }) => {
  const { getMatchs, isLoadingMatchs } = useMatchContext();

  const onSuccess = (data: any) => {
    getMatchs?.();
  };

  const { mutate: onVoteMatch } = useVoteMutation({ onSuccess });

  const handleVote = useCallback((match_key: string, team_name?: string) => {
    onVoteMatch({ match_key, team_name });
  }, []);

  return (
    <Container>
      <Row>
        {matchs.map((match: Match, index: number) => (
          <Col key={index} xs={12} lg={matchs.length == 1 ? 12 : 6}>
            <div className={cx('space-vertical')}>
              <Match
                isLoading={isLoadingMatchs}
                onVote={handleVote}
                {...match}
                keyMatch={match?.key}
                variant={variant}
              />
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Rounds;
