export const CloseIcon = ({ size, width = '54', height = '54', className, onClick }: any) => (
  <svg
    onClick={onClick}
    className={className}
    width={size || width}
    height={size || height}
    viewBox="0 0 54 54"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="27" cy="27" r="25.5" fill="#7B04AB" stroke="white" strokeWidth="3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.8995 17.1005C36.1184 16.3194 34.8521 16.3194 34.0711 17.1005L27 24.1716L19.9289 17.1005C19.1479 16.3194 17.8816 16.3194 17.1005 17.1005C16.3195 17.8815 16.3195 19.1479 17.1005 19.9289L24.1716 27L17.1005 34.071C16.3195 34.8521 16.3195 36.1184 17.1005 36.8995C17.8816 37.6805 19.1479 37.6805 19.9289 36.8995L27 29.8284L34.0711 36.8995C34.8521 37.6805 36.1184 37.6805 36.8995 36.8995C37.6805 36.1184 37.6805 34.8521 36.8995 34.071L29.8284 27L36.8995 19.9289C37.6805 19.1479 37.6805 17.8815 36.8995 17.1005Z"
      fill="white"
    />
  </svg>
);
