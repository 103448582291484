import classNames from 'classnames/bind';
import headerImg from 'assets/birthday/images/header.png';
import styles from './Image.module.scss';

const cx = classNames.bind(styles);

const Image: React.FC = () => {
  return (
    <div className={cx('header')}>
      <img
        className={cx('header--img')}
        alt="REMITANO TURNS 9. LET'S JOIN OUR BIRTHDAY CELEBRATION!"
        src={headerImg}
      />
    </div>
  );
};

export default Image;
