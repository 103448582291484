import { isProdEnv, isDevEnv } from 'utils/ConfigEnv';
import { init, track } from '@amplitude/analytics-browser';

let isAmplitudeReady = false;

if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
  init(process.env.REACT_APP_AMPLITUDE_API_KEY);
  isAmplitudeReady = true;
}

export const sendEventLog = (eventName: string, params?: any) => {
  if (!eventName) return;

  if (!isProdEnv) {
    if (isDevEnv) console.log('sendEventLog', eventName, params);
    return;
  }

  try {
    if (isAmplitudeReady) {
      track(eventName, params);
    }
  } catch (e) {
    // I want application to not crash, but don't care about the message
  }
};

export default { sendEventLog };
