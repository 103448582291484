import React from 'react';
import { useGlobalContext } from 'contexts/GlobalContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import classNames from 'classnames/bind';

import style from './RequiedKyc.module.scss';
import Locale from 'locale';
import CommonHelper from 'utils/CommonHelper';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useAuthContext } from 'contexts/AuthContext';

const cx = classNames.bind(style);

const RequiedKyc: React.FC = () => {
  const { level } = useAuthContext();

  const { isRemiApp, isRequiredLoginPopup } = useGlobalContext();
  const { language } = useLanguageContext();

  const handleVerifyAccount = () => {
    CommonHelper.redirectUrl(isRemiApp, `/home/${language === 'vi' ? 'vn' : 'au'}/kyc`);
  };

  return (
    <Modal show={false} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <h4>{Locale.translate('terms_and_conditions_title')}</h4>
        <p>{Locale.translate('terms_and_conditions_intro')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className={cx('button')} onClick={handleVerifyAccount}>
          {Locale.translate('verify_account')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RequiedKyc;
