import React from 'react';
import classNames from 'classnames/bind';
import { Image } from 'react-bootstrap';
import WCButton from 'modules/world-cup/components/WCButton';
import { useLanguageContext } from 'contexts/LanguageContext';
import { useGlobalContext } from 'contexts/GlobalContext';
import Locale from 'locale';

import { Activities } from 'world-cup/components/Activities/data';
import styles from './ActivitiesItem.module.scss';

const cx = classNames.bind(styles);

type Props = Activities;

const ActivitiesItem: React.FC<Props> = ({ title, description, src, url }) => {
  const { language } = useLanguageContext();
  const { isRemiApp } = useGlobalContext();

  const handlePlayNow = (url: string) => {
    window.open(url);
  };

  return (
    <div className={cx('activities-item', language)}>
      <Image className={cx('activities-item-image')} src={src} fluid />
      <h2>{title}</h2>
      <span className={cx('activities-item-des')}>{Locale.translate(String(description))}</span>
      <WCButton
        onClick={() => handlePlayNow(String(url))}
        className={cx('activities-item-read-more')}
        outline
      >
        {Locale.translate('play_now')}
      </WCButton>
    </div>
  );
};

export default ActivitiesItem;
