import title1 from 'assets/look-back/images/title-1.png';
import title2 from 'assets/look-back/images/title-2.png';
import title3 from 'assets/look-back/images/title-3.png';
import title4 from 'assets/look-back/images/title-4.png';
import title5 from 'assets/look-back/images/title-5.png';
import title6 from 'assets/look-back/images/title-6.png';
import title7 from 'assets/look-back/images/title-7.png';
import title8 from 'assets/look-back/images/title-8.png';
import title9 from 'assets/look-back/images/title-9.png';

import background1 from 'assets/look-back/images/background-1.svg';
import background2 from 'assets/look-back/images/background-2.svg';
import background3 from 'assets/look-back/images/background-3.svg';
import background4 from 'assets/look-back/images/background-4.svg';

export const backgrounds = {
  1: background1,
  2: background2,
  3: background3,
  4: background4,
};

export const images = [title1, title2, title3, title4, title5, title6, title7, title8, title9];
