import Modal from 'react-bootstrap/Modal';
import Locale from 'locale';
import classNames from 'classnames/bind';
import { CloseIcon } from 'merry-christmas/components/Icons';
import styles from './TermAndCondition.module.scss';

const cx = classNames.bind(styles);

type Props = {
  show: boolean;
  onHide: () => void;
};

const ModalTermAndCondition: React.FC<Props> = (props) => {
  const { show, onHide } = props;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-game"
    >
      <CloseIcon className={cx('close-button')} onClick={onHide} />
      <div className={cx('content')}>
        <h3 className={cx('title')}>{Locale.translate('introduction')}</h3>
        <p>{Locale.translate('introduction_description')}</p>

        <h3 className={cx('title')}>{Locale.translate('rules')}</h3>
        <p dangerouslySetInnerHTML={{ __html: Locale.translate('rules_part_1') }}></p>
        <p dangerouslySetInnerHTML={{ __html: Locale.translate('rules_part_2') }}></p>
        <p dangerouslySetInnerHTML={{ __html: Locale.translate('rules_part_3') }}></p>
        <p>{Locale.translate('rules_part_4')}</p>

        <h3 className={cx('title')}>{Locale.translate('how_to_participate')}</h3>
        <b>{Locale.translate('how_to_participate_step_1')}</b>
        <ul>
          <li>{Locale.translate('how_to_participate_step_1_description')}</li>
        </ul>
        <b>{Locale.translate('how_to_participate_step_2')}</b>
        <ul>
          <li>{Locale.translate('how_to_participate_step_2_description_1')}</li>
          <li>{Locale.translate('how_to_participate_step_2_description_2')}</li>
          <li>{Locale.translate('how_to_participate_step_2_description_3')}</li>
        </ul>
        <b>{Locale.translate('how_to_participate_step_3')}</b>
        <ul>
          <li>{Locale.translate('how_to_participate_step_3_description_1')}</li>
          <li>{Locale.translate('how_to_participate_step_3_description_2')}</li>
        </ul>
        <h3 className={cx('title')}>{Locale.translate('terms_and_conditions_title')}</h3>
        <p>{Locale.translate('terms_and_conditions_intro')}</p>
        <h5>{Locale.translate('terms_and_conditions_part_1')}</h5>
        <p>{Locale.translate('terms_and_conditions_part_1_1')}</p>
        <p>{Locale.translate('terms_and_conditions_part_1_2')}</p>
        <p>{Locale.translate('terms_and_conditions_part_1_3')}</p>
        <h5>{Locale.translate('terms_and_conditions_part_2')}</h5>
        <p>{Locale.translate('terms_and_conditions_part_2_1')}</p>
        <p>{Locale.translate('terms_and_conditions_part_2_2')}</p>
        <p>{Locale.translate('terms_and_conditions_part_2_3')}</p>
        <p>{Locale.translate('terms_and_conditions_part_2_4')}</p>
        <p>{Locale.translate('terms_and_conditions_part_2_5')}</p>
        <h5>{Locale.translate('terms_and_conditions_part_3')}</h5>
        <p>{Locale.translate('terms_and_conditions_part_3_1')}</p>
        <p>{Locale.translate('terms_and_conditions_part_3_2')}</p>
        <p>{Locale.translate('terms_and_conditions_part_3_3')}</p>
        <p>{Locale.translate('terms_and_conditions_part_3_4')}</p>
        <h5>{Locale.translate('terms_and_conditions_part_4')}</h5>
        <p>{Locale.translate('terms_and_conditions_part_4_1')}</p>
        <p>{Locale.translate('terms_and_conditions_part_4_2')}</p>
        <h5>{Locale.translate('terms_and_conditions_part_5')}</h5>
        <p>{Locale.translate('terms_and_conditions_part_5_1')}</p>
        <p>{Locale.translate('terms_and_conditions_part_5_2')}</p>
        <p>{Locale.translate('terms_and_conditions_part_5_3')}</p>
      </div>
    </Modal>
  );
};

export default ModalTermAndCondition;
