const words = {
  required_login: 'Login / Register required',
  required_login_des: 'Login or Register to continue',
  login: 'Login / Register',
  back: 'Back',
  group_state: 'Group stage',
  round_of_16: 'Round of 16',
  quarter_finals: 'Quarter-finals',
  semi_final: 'Semi - finals',
  final_round: 'The Grand Finale',
  banner_title_one: 'Share “hot” rewards',
  banner_title_two: 'Share your vibes',
  banner_title_third: 'Tons of vouchers are waiting to be unboxed',
  voted: 'Voted',
  match_end: 'Match end',
  submit: 'Submit',
  gift_name: 'Win up to {amount} Shib Worth of Rewards',
  voteable_matches: 'Available for voting',
  voteable_not_matches: 'Unavailable for voting',
  match_select: 'Choose your favourite team',
  view_match_voting: 'Back to voting',
  view_match_not_voting: 'View matches not for voting',
  expired_match: 'Match expired',
  expired_vote: 'Vote expired',
  read_more: 'Read more',
  win: 'Win',
  lose: 'Loss',
  waiting: 'Waiting',
  draw: 'Draw',
  empty_voting: "You haven't choosen any team.",
  vote_now: 'Vote now!',
  my_vote: 'My Vote',
  match_not_vote: 'This match is not available for voting',
  date_time: 'DATE / TIME',
  comming_soon: 'Coming soon',
  activities_sub_title: 'Over $2000 and 1000 vouchers are waiting to be unboxed.',
  activities_description_01:
    'Over 1000 Crypto Coins and Vouchers winners every day until Christmas.',
  activities_description_02: `The best time for a new beginning is now! A Crypto knowledge mountain at Remitano named Coinlympia is open for any smart investor to conquer. Let’s challenge your crypto-knowledge!`,
  play_now: 'Play Now',
  button_back: 'Back to Home',
  button_back_small: 'Back',
  intro_1: 'HAPPY 2023',
  intro_2:
    'Wish you a joyous year filled with peace, happiness and great success. May you have a fabulous New Year!',
  outro_1: 'Each door stays open for 1 days.',
  week: 'Week',
  open_in: 'Open in',
  days: 'days',
  hours: 'hours',
  minutes: 'minutes',
  seconds: 'seconds',
  days_left_first: ' ',
  days_left: 'days left',
  expired: 'Expired',
  view_rewards: 'Click to view your reward',
  miss_round: 'Sorry, you missed this round',
  terms_and_conditions_title: 'Terms and Conditions',
  terms_and_conditions_intro:
    'Remitano Users need eKYC Level 2 to receive rewards from the program.',
  terms_and_conditions_part_1: 'A. General Rules',
  terms_and_conditions_part_1_1:
    "Based on the game's results, Remitano will send the reward directly to the player's Remitano wallet. In case of a complaint, Remitano will settle it based on fairness and the results of the game. Remitano has the right to make the final decision.",
  terms_and_conditions_part_1_2:
    "In case of detecting a player showing signs of abnormality or cheating, Remitano has the right to stop the player's participation in the program without prior notice. Remitano has the right to refuse to pay the prize or withdraw part or all of the award in case of detecting a customer's fraudulent behaviour. In all cases, Remitano has the right to make the final decision.",
  terms_and_conditions_part_1_3:
    "If there is any discrepancy between the information displayed on the player's app and Remitano's system, the final decision will be the basal situation; the program rules may be adjusted or terminated at the discretion of Remitano during the duration of the ped on information from Remitano's system.",
  terms_and_conditions_part_2: 'B. Conditions for Participants',
  terms_and_conditions_part_2_1:
    'Players must fully follow the instructions and comply with the regulations of the Organizer.',
  terms_and_conditions_part_2_2:
    'Players must keep photos and posts made for the Christmas Advent Calendar up for at least two weeks after the contest',
  terms_and_conditions_part_2_3:
    'Participants must keep pictures and posts made set public for easy verification.',
  terms_and_conditions_part_2_4:
    'The program applies to all Remitano users using the latest version of Remitano’s Mobile App or Remitano’s Website.',
  terms_and_conditions_part_2_5: 'The program does not apply to citizens under 18 years old.',
  terms_and_conditions_part_3: 'C. Rules for Receiving the Reward',
  terms_and_conditions_part_3_1:
    'Players can unlock rewards daily by completing a qualified mission.',
  terms_and_conditions_part_3_2:
    'Remitano users need eKYC Level 2 to receive rewards from the program.',
  terms_and_conditions_part_3_3:
    'Players must fill in the information provided by players entirely and accurately. The Organizer is not responsible for dealing with incorrect information provided.',
  terms_and_conditions_part_3_4: "Rewards must comply with Remitano's regulations.",
  terms_and_conditions_part_4:
    'D. Regulations on the Rights and Obligations of the Organizing Committee',
  terms_and_conditions_part_4_1:
    'The Organizer has the full right to use the players name and content (articles, images) for advertising, communication and other commercial purposes without paying any cost. These commercial purposes include promotion on social networks, online promotion (PR, Online Ads, Website), and media promotion (film, newspaper advertisement).',
  terms_and_conditions_part_4_2:
    'The organisers are obliged to keep the personal information provided by players confidential.',
  terms_and_conditions_part_5: 'E. Other Rules',
  terms_and_conditions_part_5_1:
    "The Organizer reserves the right to stop, adjust or change the program's content and terms before the end of the program and notify the updated terms on the Remitano fan page.",
  terms_and_conditions_part_5_2:
    'The Organizing Committee can shorten or extend the duration of the program based on their discretion.',
  terms_and_conditions_part_5_3:
    'The program may end earlier than expected when the promotional budget is exhausted.',
  introduction: 'Christmas advent calendar introduction',
  introduction_description:
    'Welcome to an exciting Christmas Countdown Adventure with Remitano. Every day is an opportunity to win great prizes! The $10,000 gift pool, which includes hot coins like BTC, DOGE, TRX, SHIB, ADA, and Gift Vouchers, is waiting for you to discover',
  rules: 'Advent calendar rules',
  rules_part_1:
    'Every day from <b>Dec 1 to Dec 24</b>, players visit the Christmas Advent Calendar Page through GameHub/Web Page/Direct Link and open a door for the day. Players could either get a Free Turn or perform a mission to qualify for an open door.',
  rules_part_2:
    'Remitano will give rewards on a <b>“First Come, First Served”</b> basis, so players who open the door and finish their missions early will most likely win more prizes. Each day, more than 1000 winners who open the door and complete the task will claim the designated prize of the day',
  rules_part_3:
    'For each day, players can only open one door. Players will open the first door on <b>Dec 1</b>, the 2nd door on <b>Dec 2</b>, the 3rd door on <b>Dec 3</b>, and so on. However, opened doors can stay open for five days, so users can still open a missed door before it expires.',
  rules_part_4:
    'Thousands of lucky gift boxes, including hot coins like USDT, DOGE, BTC, TRX, MATIC, XRP, and Trading & Swap Vouchers, are waiting for you to explore daily.',
  how_to_participate: 'How to participate',
  how_to_participate_step_1: 'Step One',
  how_to_participate_step_1_description:
    'Players should log in or register for a Remitano account.',
  how_to_participate_step_2: 'Step Two',
  how_to_participate_step_2_description_1:
    'Access the game on the Remitano Website or App via GameHub',
  how_to_participate_step_2_description_2:
    'Click the Christmas Advent Calendar banner to join the fun',
  how_to_participate_step_2_description_3:
    'After logging into the Game, click on the door for the day to reveal your free turn or mission.',
  how_to_participate_step_3: 'Step Three',
  how_to_participate_step_3_description_1:
    'Either claim your Bonus Entry or Complete the mission to claim your reward',
  how_to_participate_step_3_description_2:
    'Rewards will be credited to your Remitano Wallet after Remitano confirms qualified winners',
  no_rewards_title:
    'Oh No!😔 Today doesn’t seem like your lucky day but rest assured, tomorrow will be better!',
  no_rewards_description:
    'Come back early tomorrow, open a door, and perform the missions to claim your prize.',
  verify_account: 'Verify account',
  button_discover: 'Discover Now',
  welcome_text: 'Hi',
  friend: 'Friend',
  story1_description: "Let's look back at the outstanding memories of 2022. You have invested:",
  story2_desceiption:
    'Depositing and withdrawing fiat on Remitano is easy and safe, so you made transactions with peace of mind',
  deposit: 'Deposit',
  withdraw: 'Withdraw',
  call_yout: 'Call you',
  about_remitano_title1: 'In 2022',
  about_remitano_title2: 'Remitano has continued to grow and expand with',
  about_remitano_product: '___ new products and new features have been launched',
  about_remitano_coin: '___ million new users',
  about_remitano_action: '___ campaigns to connect with users',
  strive: 'Always strive for excellence',
  feedback_quote:
    'Every improvement contributing to your outstanding experience is our daily motivation!',
  story_end_title: '2022 was great',
  story_end_description1: 'because of You',
  story_end_description2: "Let's go through the promising 2023 together and make it greater!",
  register_now: 'Register now',
  share: 'Share',
  close: 'Close',
  next: 'Next',
  get_turn: 'Get 1 Lucky draw turn',
  lookback_share:
    "Let's look back at the outstanding crypto memories on Remitano #2022withRemitano #Remitano #2022lookback",
  look_back: 'Year in review',
  look_back_description: 'Remitano in 2022',
  share_with_friends: 'Share with friends',
  download: 'Download image',
  get_the_best_experience_witha_reliable_9_year_old_exchange:
    'Get the best experience with a reliable 9-year-old exchange',
  we_are_available_on: 'We are available on',
  or_visit: 'Or visit',
  explore_more_with_remitano_9_truths:
    'Explore more with #Remitano9Truths on Remitano Facebook and Twitter!',
  explore_more_with_remitano_9_truths_short: 'Explore more with #Remitano9Truths on',
  birthday_terms_description:
    'Missions will show up one after another. Players can complete the missed ones to win a loyalty bonus. Quantity of gift is limited and will be rewarded after players completed missions following first come first served.',
  terms_and_conditions: 'Terms and Conditions',
  thank_you_title: 'Thank you for accompanying Remitano!',
  thank_you_text:
    'Because of you, Remitano firmly celebrates our 9th year of growth with the following highlights:',
  highlight_1:
    'Born in 2014, Remitano is a pioneering cryptocurrency exchange that brings crypto to everyone',
  highlight_2:
    'Reached 1 million users milestone with a trading volume of 3 billion USD in the first 3 years',
  highlight_3: 'We always put the user at first and ensure 7 customer rights at Remitano',
  highlight_4:
    '14 different products are available on Remitano to meet the diverse needs of Crypto investors',
  highlight_5:
    '67% of trading volume on Remitano is from crypto-fiat pairs. Trade with trust, Trade with Remitano!',
  highlight_6:
    "Remitano's 5 local service centers across time zones, ready to take care of customers 24/7",
  highlight_7: "Remitano's 24/7 customer service team handles 1815 requests/day",
  highlight_8: 'Nearly 6M users trusting and transacting with Remitano over the years',
  highlight_9:
    'Rated high satisfaction 4.7/5.0 on Trustpilot by 11,935 people from around the world',
  complete: 'Completed',
  complete_and_earn: 'Complete mission and earn',
  open_on: 'Open on',
  special_content: '(Tweet a birthday wish) mention @remitano with hashtag #RemitanoTurns9',
  mission_content_1: 'Share the Birthday Countdown with Friends',
  mission_content_2: `Retweet a Tweet from Remitano's Twitter `,
  mission_content_3: `View a post on Remitano's Facebook `,
  mission_content_4: 'Remitano Trivia 1',
  mission_content_5: 'Join "How I met Remitano" Contest in Facebook community group',
  mission_content_6: `Retweet a Tweet from Remitano's Twitter `,
  mission_content_7: `Claim Loyalty Bonus`,
  mission_content_8: `Remitano Trivia 2`,
  share_now: 'Share now',
  thank_you: 'Thank you!',
  thank_des_1: 'Thank you for the birthday wishes.',
  thank_des_2: `The number of participants at the birthday party exceeded our expectations, and unfortunately, Remitano has run out of gifts. We hope you had memorable experiences with Remitano.`,
  oh_no: 'Oh No!',
  oh_no_des_1: 'Looks like the party rewards have partied their way out!',
  oh_no_des_2: `Don't worry, come back early tomorrow and complete the remaining missions to claim your prizes.`,
};

export default words;
