import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import Rounds from 'modules/world-cup/components/Rounds';

import styles from './GroupStage.module.scss';
import { useSearchParams } from 'react-router-dom';
import { useMatchContext } from 'world-cup/contexts/MatchContext';
import { MatchUtil } from 'world-cup/ultils/MatchUtils';
import WCButton from 'world-cup/components/WCButton';
import { RightArrowIcon } from 'base-components/Icons';
import Locale from 'locale';
import MyVote from 'world-cup/components/MyVote';
const cx = classNames.bind(styles);

type TabValue =
  | 'round-of-16-teams'
  | 'quater-finals'
  | 'semi-final'
  | 'final-round'
  | 'my-voted'
  | null;

type TitleProps = {
  title: string;
  description?: string;
  subTitle?: string;
};

const defaultMatchsLoading: Matchs = [
  {
    key: '',
    amount: '',
    currency: '',
    startGuessing: '',
    endGuessing: '',
    guessing: '',
    isCorrectGuessing: '',
    participants: 0,
    score: '',
    team: '',
    winningTeam: '',
  },
  {
    key: '',
    amount: '',
    currency: '',
    startGuessing: '',
    endGuessing: '',
    guessing: '',
    isCorrectGuessing: '',
    participants: 0,
    score: '',
    team: '',
    winningTeam: '',
  },
];

const Title: React.FC<TitleProps> = ({ title, description, subTitle }: TitleProps) => (
  <div className={cx('title')}>
    <h1 className={cx('title-bold')}>{title}</h1>
    {subTitle && <span className={cx('title-sub')}>{subTitle}</span>}
    {description && <span className={cx('title-des')}>{description}</span>}
  </div>
);

const GroupStage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [voteTab, setVoteTab] = useState(true); // true onVote false outVote
  const [isNotVote, setIsNoteVote] = useState(false);
  const voteTabRef: any = useRef();
  const isFirstLoad = useRef<boolean>(true);
  const { matches, isLoadingMatchs } = useMatchContext();

  voteTabRef.current = voteTab;

  const tab = searchParams.get('tab') as TabValue;
  const sectionStateRef: any = useRef(null);

  const executeScroll = () => sectionStateRef.current?.scrollIntoView({ behavior: 'smooth' });

  const renderTitle = useMemo(() => {
    switch (tab) {
      case 'round-of-16-teams':
        return (
          <Title
            title={Locale.translate('round_of_16')}
            subTitle="04/12 – 07/12"
            description={
              voteTab
                ? Locale.translate('voteable_matches')
                : Locale.translate('voteable_not_matches')
            }
          />
        );
      case 'quater-finals':
        return (
          <Title
            title={Locale.translate('quarter_finals')}
            subTitle="09-12 – 11/12"
            description={
              voteTab
                ? Locale.translate('voteable_matches')
                : Locale.translate('voteable_not_matches')
            }
          />
        );
      case 'semi-final':
        return (
          <Title
            title={Locale.translate('semi_final')}
            subTitle="14/12 to 15/12"
            description={
              voteTab
                ? Locale.translate('voteable_matches')
                : Locale.translate('voteable_not_matches')
            }
          />
        );
      case 'final-round':
        return (
          <Title
            title={Locale.translate('final_round')}
            subTitle="17/12 to 18/12"
            description={
              voteTab
                ? Locale.translate('voteable_matches')
                : Locale.translate('voteable_not_matches')
            }
          />
        );
      case 'my-voted':
        return <Title title={Locale.translate('my_vote')} />;
      default:
        return (
          <Title
            title={Locale.translate('group_state')}
            subTitle="20/11 to 3/12"
            description={
              voteTab
                ? Locale.translate('voteable_matches')
                : Locale.translate('voteable_not_matches')
            }
          />
        );
    }
  }, [tab, voteTab]);

  useEffect(() => {
    setVoteTab(true);
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
    } else if (!searchParams.get('init')) {
      executeScroll();
    }
  }, [tab]);

  const transformDataByRound = useMemo(() => {
    switch (tab) {
      case 'round-of-16-teams': {
        const matchsNotVote = MatchUtil.matchesNotVote(matches?.round16);
        const matchsCanVote = MatchUtil.matchesCanVote(matches?.round16);
        setIsNoteVote(Boolean(matchsNotVote?.length));
        return voteTab ? matchsCanVote : matchsNotVote;
      }
      case 'quater-finals': {
        const matchsNotVote = MatchUtil.matchesNotVote(matches?.quarterfinal);
        setIsNoteVote(Boolean(matchsNotVote?.length));
        return voteTab
          ? MatchUtil.matchesCanVote(matches?.quarterfinal)
          : MatchUtil.matchesNotVote(matches?.quarterfinal);
      }

      case 'semi-final': {
        const matchsNotVote = MatchUtil.matchesNotVote(matches?.semifinal);
        setIsNoteVote(Boolean(matchsNotVote?.length));
        return voteTab
          ? MatchUtil.matchesCanVote(matches?.semifinal)
          : MatchUtil.matchesNotVote(matches?.semifinal);
      }

      case 'final-round': {
        const matchsNotVote = MatchUtil.matchesNotVote(matches?.final);
        setIsNoteVote(Boolean(matchsNotVote?.length));
        const third = matches?.third || [];
        return voteTab
          ? [...((MatchUtil.matchesCanVote(matches?.final) as Matchs) || []), ...third]
          : MatchUtil.matchesNotVote(matches?.final);
      }

      default: {
        const matchsNotVote = MatchUtil.matchesNotVote(matches?.groupstage);
        const matchsCanVote = MatchUtil.matchesCanVote(matches?.groupstage);

        setIsNoteVote(Boolean(matchsNotVote?.length));
        return voteTab ? matchsCanVote : matchsNotVote;
      }
    }
  }, [tab, matches, voteTab]);

  const handleLoadVoteType = () => {
    setVoteTab((currentValue) => !currentValue);
    executeScroll();
  };

  const renderByTabName = useMemo(() => {
    if (isLoadingMatchs && !matches?.groupstage?.length && tab !== 'my-voted') {
      return <Rounds matchs={defaultMatchsLoading} variant="white" />;
    }
    switch (tab) {
      case 'my-voted':
        return <MyVote />;
      default:
        return <Rounds matchs={transformDataByRound} variant="white" />;
    }
  }, [tab, transformDataByRound, isLoadingMatchs]);

  return (
    <div className={cx('group-stage')} ref={sectionStateRef}>
      {renderTitle}
      {renderByTabName}
      {isNotVote && tab !== 'my-voted' && !isLoadingMatchs && (
        <div className={cx('load-no-vote')}>
          <WCButton
            onClick={handleLoadVoteType}
            rightIcon={voteTab && <RightArrowIcon size="1.6rem" fillColor="var(--primary)" />}
            leftIcon={
              !voteTab && (
                <RightArrowIcon
                  className={cx('right-transform')}
                  size="1.6rem"
                  fillColor="var(--primary)"
                />
              )
            }
            outline
          >
            {voteTab
              ? Locale.translate('view_match_not_voting')
              : Locale.translate('view_match_voting')}
          </WCButton>
        </div>
      )}
    </div>
  );
};

export default GroupStage;
